import React from 'react'

import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { trackOutlinkClick } from '../../../services/analytics-service'
import { ECContentBaseProps } from '../core/ECContentBase'
import { ECBackgroundProps } from '../utilities/ECColor'
import { ECSpacableProps, useMarginClass } from '../utilities/ECSpacable'
import { ECTextProps, useTextClasses } from '../utilities/ECText'

export interface ECLinkProps {
  id?: string
  /** path to link to or url, default = '#' */
  path?: string
  lang?: string
  href?: string
  rel?: string
  title?: string
  targetBlank?: boolean
  onClick?: () => void
}

export const ECLink: React.FC<ECLinkProps & ECTextProps & ECSpacableProps & ECBackgroundProps & ECContentBaseProps> = ({
  id,
  path = '#',
  href,
  targetBlank = false,
  lang,
  rel,
  onClick,
  color,
  styles,
  margin,
  padding,
  title,
  children,
  className,
  style,
}) => {
  const onClickLocal = React.useCallback(() => {
    if (targetBlank && href) {
      trackOutlinkClick(href)
    }
    onClick?.()
  }, [onClick, targetBlank, href])

  const textClasses = useTextClasses(color, styles)
  const marginClass = useMarginClass(margin)
  const paddingClass = useMarginClass(padding)

  return (
    <Link
      id={id}
      to={href ? { pathname: href } : path}
      lang={lang}
      hrefLang={lang}
      target={targetBlank ? '_blank' : undefined}
      rel={rel}
      className={classnames(textClasses, marginClass, paddingClass, className)}
      onClick={onClickLocal}
      style={{ cursor: 'pointer', ...style }}>
      {title ?? children}
    </Link>
  )
}
