import React from 'react'

import { countries, Country } from 'countries-list'

import { ECButton } from '../../../../components/EC/ECButton'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { ECColorPrimary } from '../../../../components/EC/utilities/ECColor'
import { ECColumn, ECColumnViewportProps, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable, ECSpacableProps } from '../../../../components/EC/utilities/ECSpacable'
import { ECStack } from '../../../../components/EC/utilities/ECStack'
import { useAnglersTranslate } from '../../../../i18n'
import { useAnglerSelector } from '../../../../store/reducers/user/reducer'
import './AnglerProfilePage.scss'

interface GenericRowProps {
  label: string
  value?: string
  widthLeft?: ECColumnViewportProps
  widthRight?: ECColumnViewportProps
  color?: ECColorPrimary
}

const rowMargins: ECSpacable = { vertical: 's' }

const leftColWidth: ECColumnViewportProps = { all: 4 }
const rightColWidth: ECColumnViewportProps = { all: 8 }

const labelColor = 'grey'

export const GenericRow: React.FC<GenericRowProps & ECSpacableProps> = ({
  label,
  value,
  widthLeft = leftColWidth,
  widthRight = rightColWidth,
  color = labelColor,
  margin = rowMargins,
}) => {
  return (
    <ECRow margin={margin}>
      <ECColumn col={widthLeft} color={color} className="align_right">
        {label}
      </ECColumn>
      <ECColumn col={widthRight}>{value}</ECColumn>
    </ECRow>
  )
}

export const AnglerProfilePage = () => {
  const t = useAnglersTranslate()

  const angler = useAnglerSelector()

  const countryName = React.useMemo(() => {
    if (angler?.countryOfResidence) {
      return (countries as { [code: string]: Country })[angler.countryOfResidence]?.name ?? angler?.countryOfResidence
    }
    return angler?.countryOfResidence
  }, [angler])

  const boatCountryName = React.useMemo(() => {
    if (angler?.boatCountry) {
      return (countries as { [code: string]: Country })[angler.boatCountry]?.name ?? angler?.boatCountry
    }
    return angler?.boatCountry
  }, [angler])

  return (
    <ECColumn col={{ all: 12 }}>
      <GenericRow label={t('profile.id')} value={angler?.id} />
      <GenericRow label={t('profile.email')} value={angler?.emailPartial} />
      <GenericRow label={t('profile.birthYear')} value={`${angler?.birthYear}`} />
      <GenericRow label={t('profile.countryOfResidence')} value={countryName} />
      <GenericRow label={t('profile.zipCode')} value={angler?.zipCode} />
      <GenericRow
        label={t('profile.anglersGroups')}
        value={(angler?.anglersGroups ?? []).map((elt) => elt.name).join(', ')}
      />
      <GenericRow
        label={t('profile.ownsBoat.title')}
        value={t(`profile.ownsBoat.${angler?.ownsBoat === true ? 'yes' : 'no'}`)}
      />
      {angler?.ownsBoat === true ? <GenericRow label={t('profile.boatCountry')} value={boatCountryName} /> : undefined}
      <ECStack horizontalAlignment="center" margin={{ top: '2xl' }}>
        <ECButton type="primary" text={t('profile.edit')} margin={{ bottom: 'm' }} href="/profile/edit" />
      </ECStack>
      <ECStack horizontalAlignment="center" margin={{ top: 'xl' }}>
        <ECLink title={t('profile.changePassword')} path="/profile/changePassword" />
      </ECStack>
    </ECColumn>
  )
}
