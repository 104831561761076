import React from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ECButton } from '../../../../components/EC/ECButton'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { ECColumn, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { trackOutlinkClick } from '../../../../services/analytics-service'
import { useEtransIsLoaded } from '../../../../utils/hooks/useEtrans'
import { ScrollSpyedComponent } from '../../components/ScrollSpyedComponent'

export const PrivacyStatementPage = React.memo(() => {
  const { t } = useTranslation('privacy-statement')
  const { t: tc } = useTranslation('common')

  const [visibleIds, setVisibleIds] = React.useState<{ [id: string]: { isVisible: boolean } }>({})

  const elements = React.useMemo<{ id: string; key: string; paragraphsKeys?: string[] }[]>(() => {
    return [
      { id: '1', key: 'introduction', paragraphsKeys: ['first', 'second', 'third'] },
      { id: '2', key: 'why-collect', paragraphsKeys: ['first', 'second', 'third', 'fourth'] },
      { id: '3', key: 'what-information', paragraphsKeys: ['first', 'second'] },
      { id: '4', key: 'how-collect', paragraphsKeys: ['first', 'second'] },
      { id: '5', key: 'who-has-access', paragraphsKeys: ['first', 'second'] },
      { id: '6', key: 'how-you-access', paragraphsKeys: ['first', 'second', 'third', 'fourth'] },
      { id: '7', key: 'how-long', paragraphsKeys: ['first'] },
      { id: '8', key: 'security-measures', paragraphsKeys: ['first', 'second'] },
      { id: '9', key: 'what-rights', paragraphsKeys: ['first', 'second'] },
      { id: '10', key: 'contact-information', paragraphsKeys: ['first', 'second', 'third', 'fourth'] },
      { id: '11', key: 'where-more-info', paragraphsKeys: ['first', 'second'] },
    ]
  }, [])

  const onViewportVisibilityChange = React.useCallback((opts) => {
    setVisibleIds((prev) => {
      return {
        ...prev,
        [opts.id]: { isVisible: opts.isInViewport },
      }
    })
  }, [])

  const visibleItem = React.useMemo(() => {
    const firstVisible = Object.entries(visibleIds)
      .sort((a, b) => Number.parseInt(a[0], 10) - Number.parseInt(b[0], 10))
      .find((el) => el[1].isVisible)
    if (firstVisible) {
      return firstVisible[0]
    }
  }, [visibleIds])

  const etransIsLoaded = useEtransIsLoaded()

  return (
    <div>
      <div className="ecl-container">
        <ECRow style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <ECTitle isPageTitle>
            {t('title')}
            {etransIsLoaded && (
              <ECButton
                type="primary"
                text={tc('header.languages.translate')}
                style={{ fontSize: 23, textDecoration: 'none', marginLeft: 22 }}
                onClick={() => window.$wt.etrans.modal.show()}
              />
            )}
          </ECTitle>
        </ECRow>
        <div className="ecl-row ecl-u-mt-l" data-ecl-inpage-navigation-container>
          <ECColumn col={{ md: 3 }}>
            <nav
              className="ecl-inpage-navigation"
              data-ecl-auto-init="InpageNavigation"
              data-ecl-inpage-navigation="true"
              aria-labelledby="ecl-inpage-navigation-default">
              <div className="ecl-inpage-navigation__title" id="ecl-inpage-navigation-default">
                {t('tableOfContents')}
              </div>
              <div className="ecl-inpage-navigation__body">
                <ul
                  className="ecl-inpage-navigation__list"
                  data-ecl-inpage-navigation-list="true"
                  id="ecl-inpage-navigation-default-list">
                  {elements.map((el) => (
                    <li
                      key={el.id}
                      className={classNames('ecl-inpage-navigation__item', {
                        'ecl-inpage-navigation__item--active': visibleItem === el.id,
                      })}>
                      <a
                        href={`#inline-nav-${el.id}`}
                        className="ecl-link ecl-inpage-navigation__link"
                        data-ecl-inpage-navigation-link>
                        {t(`${el.key}.title`)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </ECColumn>
          <ECColumn col={{ md: 9 }}>
            {elements.map((el) => (
              <ScrollSpyedComponent key={el.id} id={el.id} onViewportVisibilityChange={onViewportVisibilityChange}>
                <div style={{ paddingBottom: '2rem' }}>
                  <h2
                    className="ecl-u-type-heading-2"
                    id={`inline-nav-${el.id}`}
                    style={{
                      paddingBottom: '0.75rem',
                      borderBottom: '2px solid #000',
                    }}>
                    {t(`${el.key}.title`)}
                  </h2>
                  {el.paragraphsKeys ? (
                    el.paragraphsKeys.map((key) => (
                      <p key={key} className="ecl-u-type-paragraph-m">
                        <span dangerouslySetInnerHTML={{ __html: t(`${el.key}.content.${key}`) }} />
                      </p>
                    ))
                  ) : (
                    <p className="ecl-u-type-paragraph-m">
                      <span dangerouslySetInnerHTML={{ __html: t(`${el.key}.content`) }} />
                    </p>
                  )}
                </div>
              </ScrollSpyedComponent>
            ))}
            <hr
              style={{
                marginTop: 100,
                borderTop: '1px solid #eee',
              }}
            />
            <p className="ecl-u-type-paragraph-m">
              <a
                href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32018R1725"
                onClick={() => {
                  trackOutlinkClick('https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32018R1725')
                }}
                target="_blank"
                rel="noreferrer">
                Regulation (EC) 2018/1725
              </a>{' '}
              (OJ L 295, 21.11.2018, p. 39–98).
            </p>
          </ECColumn>
        </div>
      </div>
    </div>
  )
})
