import React from 'react'

import { ECDatePicker } from '../../../components/EC/forms/ECDatePicker'
import { ECFormErrorMessage } from '../../../components/EC/forms/ECForm'
import { ECFormGroup } from '../../../components/EC/forms/ECFormGroup'
import { ECRadioInput } from '../../../components/EC/forms/ECRadio'
import { ECColumn } from '../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../components/EC/utilities/ECSpacable'
import { useBackOfficeTranslate } from '../../../i18n'

export interface UserActiveStatusValue {
  activationStatus: 'active' | 'activeUntil' | 'inactive'
  isActiveUntil?: Date
}

export interface SelectUserActiveStatusProps {
  sectionsMargin: ECSpacable
  value: UserActiveStatusValue
  onChange: (value: UserActiveStatusValue) => void
  isEdition?: boolean
  isDisabled?: boolean
}

export const SelectUserActiveStatus: React.FC<SelectUserActiveStatusProps> = ({
  sectionsMargin,
  value,
  onChange,
  isDisabled = false,
  isEdition = false,
}) => {
  const t = useBackOfficeTranslate()

  const onSelectStatus = React.useCallback(
    (activationStatus: UserActiveStatusValue['activationStatus']) => {
      return () =>
        onChange({
          ...value,
          activationStatus,
        })
    },
    [onChange, value],
  )

  const onSelectUntilDate = React.useCallback(
    (newDate: Date) => {
      onChange({
        ...value,
        isActiveUntil: newDate,
      })
    },
    [onChange, value],
  )

  return (
    <ECFormGroup
      id="scope"
      legend={t('users.createForm.activeStatus.title')}
      help={t(isEdition ? 'users.editForm.activeStatus.description' : 'users.createForm.activeStatus.description')}
      margin={sectionsMargin}>
      <ECRadioInput
        id="user-mgmt-rights-inactive"
        value={'inactive'}
        label={t('users.createForm.activeStatus.inactive')}
        isChecked={value.activationStatus === 'inactive'}
        onSelect={onSelectStatus('inactive')}
        isDisabled={isDisabled}
      />
      <ECRadioInput
        id="user-mgmt-rights-active"
        value={'active'}
        label={t('users.createForm.activeStatus.active')}
        isChecked={value.activationStatus === 'active'}
        onSelect={onSelectStatus('active')}
        isDisabled={isDisabled}
      />
      <ECRadioInput
        id="user-mgmt-rights-active-until"
        value={'active-until'}
        label={t('users.createForm.activeStatus.activeUntil')}
        isChecked={value.activationStatus === 'activeUntil'}
        onSelect={onSelectStatus('activeUntil')}
        isDisabled={isDisabled}
      />
      {value.activationStatus === 'activeUntil' && (
        <ECColumn col={{ all: 12, lg: 6 }}>
          {!value.isActiveUntil && (
            <ECFormErrorMessage
              id="activeUntilEndDate"
              error={{ message: t('users.createForm.activeStatus.activeUntilError') }}
            />
          )}
          <ECDatePicker
            id="activation-end"
            name="activation-end"
            value={value.isActiveUntil}
            onSelect={onSelectUntilDate}
            margin={{ top: 'm' }}
            isDisabled={isDisabled}
          />
        </ECColumn>
      )}
    </ECFormGroup>
  )
}
