import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import moment from 'moment'
import queryString from 'query-string'

import { Filters } from '../containers/back-office/indicators/FilterSection'
import { PubSub } from '../utils/PubSub'

const API_HOST = window._env_.REACT_APP_API_HOST

class BackOfficeApi {
  axiosInstance!: AxiosInstance

  constructor() {
    this.axiosInstance = Axios.create({
      baseURL: API_HOST,
      withCredentials: true,
    })

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // user has been logged out.
          PubSub.publish('admin-logged-out')
        }
        return Promise.reject(error)
      },
    )
  }

  async getData<T>(path: string, config?: AxiosRequestConfig) {
    return (await this.axiosInstance.get<T>(path, config)).data
  }

  async postLogin(body: { username: string; password: string }) {
    await this.axiosInstance.post('/backOfficeUsers/login', body)
  }

  async requestResetPassword(username: string) {
    return this.axiosInstance.post('/backOfficeUsers/forgotPassword', {
      email: username,
    })
  }

  async saveResetPassword(newPassword: string, token: string) {
    return this.axiosInstance.post('/backOfficeUsers/saveResetPassword', {
      newPassword,
      token,
    })
  }

  async getUserMe() {
    return this.getData<BackOfficeUser>('/backOfficeUsers/me')
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    return this.axiosInstance.post('/backOfficeUsers/updatePassword', {
      oldPassword,
      newPassword,
    })
  }

  async createUser(body: CreateBackOfficeUserRequest) {
    return this.axiosInstance.post<BackOfficeUser>('/backofficeUsers', body)
  }

  async updateUser(id: string, body: CreateBackOfficeUserRequest) {
    return this.axiosInstance.put<BackOfficeUser>(`/backofficeUsers/${id}`, body)
  }

  async getUser(id: string) {
    return this.getData<BackOfficeUser>(`/backOfficeUsers/${id}`)
  }

  async getUsers() {
    return this.getData<BackOfficeUser[]>('/backOfficeUsers')
  }

  async getScopes() {
    return this.getData<Scopes>('/scopes')
  }

  async getSpecies() {
    return this.getData<FishSpecies[]>('/scoped/fishSpecies')
  }

  async getGeographicalAreas() {
    return this.getData<GeographicalArea[]>('/scoped/geographicalAreas')
  }

  async getAnglersGroups() {
    return this.getData<AnglersGroup[]>('/scoped/anglersGroups')
  }

  async logOut() {
    return this.axiosInstance.post('/backOfficeUsers/logOut')
  }

  async getAggregatedData({
    limit,
    offset,
    speciesIds,
    geographicalAreasIds,
    startDate,
    endDate,
  }: {
    limit: number
    offset: number
    speciesIds: string[]
    geographicalAreasIds: string[]
    startDate: Date
    endDate: Date
  }) {
    return (
      await this.axiosInstance.get<Exports.AggregatedDataResponse>('/aggregatedData', {
        params: {
          limit,
          offset,
          speciesIds,
          geographicalAreasIds,
          after: startDate.getTime(),
          before: endDate.getTime(),
        },
      })
    ).data
  }

  async getExportExample({
    speciesIds,
    geographicalAreasIds,
    startDate,
    endDate,
  }: {
    speciesIds: string[]
    geographicalAreasIds: string[]
    startDate: Date
    endDate: Date
  }) {
    return (
      await this.axiosInstance.get<Exports.AggregatedDataResponse>(`/export.json`, {
        params: {
          geographicalAreasIds,
          speciesIds,
          after: startDate.getTime(),
          before: endDate.getTime(),
        },
      })
    ).data
  }

  getExportFileUrl({
    speciesIds,
    geographicalAreasIds,
    startDate,
    endDate,
    format,
  }: {
    speciesIds: string[]
    geographicalAreasIds: string[]
    startDate: Date
    endDate: Date
    format: 'csv' | 'xlsx'
  }) {
    const queries = queryString.stringify({
      speciesIds,
      geographicalAreasIds,
      after: startDate.getTime(),
      before: endDate.getTime(),
    })
    return `${API_HOST}/export.${format}?${queries}`
  }

  async getOAuthClients() {
    return (await this.axiosInstance.get<OAuthClients.OAuthClient[]>('/oAuthClients')).data
  }

  async getOAuthClient(id: string) {
    return (await this.axiosInstance.get<OAuthClients.OAuthClient>(`/oAuthClients/${id}`)).data
  }

  async createOAuthClient(body: OAuthClients.CreateOAuthClientRequest) {
    return this.axiosInstance.post<OAuthClients.OAuthClient>('/oAuthClients', body)
  }

  async updateOAuthClient(id: string, body: OAuthClients.UpdateOAuthClientRequest) {
    return this.axiosInstance.put<OAuthClients.OAuthClient>(`/oAuthClients/${id}`, body)
  }
}

export const backOfficeApi = new BackOfficeApi()

const filtersApiFromFilters = (filters: Filters) => {
  return {
    after: new Date(filters.startPeriod ?? moment().startOf('month').valueOf()).getTime(),
    before: new Date(filters.endPeriod ?? moment().endOf('month').valueOf()).getTime(),
    speciesIds: filters.fishSpeciesScope?.isAll ? [] : filters.fishSpeciesScope?.selected ?? [],
    geographicalAreasIds: filters.geographicalAreasScope?.isAll ? [] : filters.geographicalAreasScope?.selected ?? [],
  }
}

export const useDashboardApiAggregatedData = ({ filters }: { filters: Filters }) => {
  return useQuery(
    ['dashboard', 'aggregatedData', filters],
    async () => {
      const res = await backOfficeApi.axiosInstance.get<Dashboard.AggregatedDataResponse>(
        `/dashboard/aggregData?${queryString.stringify(filtersApiFromFilters(filters))}`,
      )
      return res.data
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  )
}

export const useDashboardApiTimeSeries = ({ filters, keptOnly }: { filters: Filters; keptOnly?: boolean }) => {
  return useQuery(
    ['dashboard', 'timeSeries', filters, keptOnly],
    async () => {
      const res = await backOfficeApi.axiosInstance.get<Dashboard.TimeSeriesResponse>(
        `/dashboard/timeSeries?${queryString.stringify({ ...filtersApiFromFilters(filters), keptOnly })}`,
      )
      return res.data
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  )
}
export const useDashboardApiMapData = ({ filters }: { filters: Filters }) => {
  return useQuery(
    ['dashboard', 'mapData', filters],
    async () => {
      const res = await backOfficeApi.axiosInstance.get<Dashboard.MapDataResponse>(
        `/dashboard/mapData?${queryString.stringify(filtersApiFromFilters(filters))}`,
      )
      return res.data
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  )
}
export const useDashboardApiDistributionData = ({ filters }: { filters: Filters }) => {
  return useQuery(
    ['dashboard', 'distributionData', filters],
    async () => {
      const res = await backOfficeApi.axiosInstance.get<Dashboard.DistributionDataResponse>(
        `/dashboard/distributionData?${queryString.stringify(filtersApiFromFilters(filters))}`,
      )
      return res.data
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  )
}
