import { Dispatch } from 'redux'

import { anglersApi } from '../../api/anglersApi'
import { SetAnglerUserActionCreator } from '../../store/reducers/user/actions'

const login = (body: { username: string; password: string }) => {
  return anglersApi.login(body)
}

const logOut = async (dispatch: Dispatch) => {
  await anglersApi.logOut()
  dispatch(SetAnglerUserActionCreator())
}

const refreshAngler = async (dispatch: Dispatch) => {
  try {
    const me = await anglersApi.getUserMe()
    dispatch(SetAnglerUserActionCreator(me))
  } catch (err) {
    dispatch(SetAnglerUserActionCreator())
  }
}

export const AnglersLoginService = {
  login,
  logOut,
  refreshAngler,
}
