/**
 * https://ec.europa.eu/component-library/ec/components/forms/radio/code/
 */

import React, { useCallback } from 'react'

import classnames from 'classnames'

import { ECFormHelpMessage, ECFormIdPrefixes, ECFormProps } from './ECForm'

export interface ECRadioInputProps extends ECFormProps {
  value: string
  label?: string
  help?: string
  /** if set radio is controlled and onChange is expected  */
  isChecked?: boolean
  onSelect?: (value: string) => void
  isDefaultChecked?: boolean
  isInvalid?: boolean
  className?: string
}

export const ECRadioInput: React.FC<ECRadioInputProps> = ({
  id,
  value,
  label,
  help,
  isChecked: checked,
  onSelect,
  isDisabled: disable = false,
  error,
  className,
}) => {
  const onChange = useCallback(
    (event) => {
      onSelect?.(event.target.value)
    },
    [onSelect],
  )

  const groupClass = classnames('ecl-radio', className)
  const labelClass = classnames('ecl-radio__label', {
    'ecl-radio__label--disabled': disable,
    'ecl-radio__box--invalid': error !== undefined,
  })
  const radioIconClass = classnames('ecl-radio__box', {
    'ecl-radio__box--disabled': disable,
    'ecl-radio__box--invalid': error !== undefined,
  })

  return (
    <div className={groupClass}>
      <input
        type="radio"
        id={`${ECFormIdPrefixes.radioInput}${id}`}
        name={`radio-group-${id}`}
        className="ecl-radio__input"
        value={value}
        aria-describedby={`${ECFormIdPrefixes.radioInput}${id}`}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={`${ECFormIdPrefixes.radioInput}${id}`} className={labelClass}>
        <span className={radioIconClass}></span>
        {label ?? value}
      </label>
      {help && (
        <ECFormHelpMessage id={id} isDisabled={disable} error={error}>
          {help}
        </ECFormHelpMessage>
      )}
    </div>
  )
}

export interface ECRadioBinaryInputProps {
  choice?: 'yes' | 'no'
  labelYes: string
  labelNo: string
  onSelect?: (value: string) => void
}

export const ECRadioBinaryInput: React.FC<ECRadioBinaryInputProps & ECFormProps> = ({
  choice = 'yes',
  id,
  isDisabled: disable,
  labelNo,
  labelYes,
  error,
  onSelect,
}) => {
  return (
    <div>
      <ECRadioInput
        className="ecl-radio--binary"
        value="yes"
        id={`${id}-yes`}
        label={labelYes}
        isDisabled={disable}
        isInvalid={error !== undefined}
        isChecked={choice === 'yes'}
        onSelect={onSelect}
      />
      <ECRadioInput
        className="ecl-radio--binary"
        value="no"
        id={`${id}-no`}
        label={labelNo}
        isDisabled={disable}
        isInvalid={error !== undefined}
        isChecked={choice === 'no'}
        onSelect={onSelect}
      />
    </div>
  )
}
