import Axios from 'axios'

/** cached during the execution time of the app */
let cachedGeolocIp: GeoIP.Response | undefined
export const getCurrentIpGeoloc = async () => {
  if (cachedGeolocIp) {
    return cachedGeolocIp
  }
  try {
    cachedGeolocIp = (await Axios.get('https://freegeoip.app/json/')).data as GeoIP.Response
  } catch (_err) {}
  return cachedGeolocIp
}
