import React from 'react'

import { ECHeader } from '../../../components/EC/core/ECHeader'
import { useBackOfficeUserSelector } from '../../../store/reducers/user/reducer'

export const Header: React.FC = () => {
  const currentUser = useBackOfficeUserSelector()
  const loggedIn = !!currentUser

  return <ECHeader isLoggedIn={loggedIn} profileRoute="/backOfficeUsers/me" />
}
