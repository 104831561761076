import React from 'react'

export const useEtransIsLoaded = () => {
  const [eTransIsLoaded, setETransIsLoaded] = React.useState(false)

  React.useEffect(() => {
    if (eTransIsLoaded) {
      return
    }
    const i = setInterval(() => {
      try {
        const _ = window.$wt.etrans.modal.show
        setETransIsLoaded(true)
      } catch (err) {
        setETransIsLoaded(false)
      }
    }, 100)
    return () => clearInterval(i)
  }, [eTransIsLoaded])

  return eTransIsLoaded
}
