import React from 'react'

import { useTranslation } from 'react-i18next'

import { ECContainer } from '../../../../components/EC/ECContainer'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useCommonTranslate } from '../../../../i18n'
import { trackOutlinkClick } from '../../../../services/analytics-service'

const LINKS = [
  {
    linkTitle: 'Common Fisheries Policy presentation',
    link: 'https://ec.europa.eu/fisheries/cfp_en',
  },
  {
    linkTitle: 'Common Fisheries Policy',
    title: 'Recital 3',
    link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32013R1380&from=EN',
  },
  {
    linkTitle: "EU's fisheries control system presentation",
    link: 'https://ec.europa.eu/fisheries/cfp/control_en',
  },
  {
    linkTitle: 'Control Regulation - latest consolidated version',
    title: 'Article 55',
    link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:02009R1224-20170101&from=EN',
  },
  {
    linkTitle: '2020 Fishing Opportunities – initial legal act',
    title: 'Recitals 10, 11, 12, 38, Article 2, Article 3, Article 10 (sea bass), Article 11 (European eel)',
    link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32020R0123&from=EN',
  },
  {
    linkTitle: '2020 Fishing Opportunities – consolidated version',
    title: 'Article 2, Article 3, Article 10 (sea bass), Article 11 (European eel)',
    link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:02020R0123-20200130&from=EN',
  },
]

export const LegislationPage: React.FC = () => {
  const tc = useCommonTranslate()
  const { t } = useTranslation('static-legislation')

  return (
    <ECContainer>
      <ECTitle isPageTitle>{tc('footer.more.recreational-fishing-legislation')}</ECTitle>
      <ECText>{t('introduction')}</ECText>
      <ECText>{t('linkListIntroduction')}</ECText>
      <ul>
        {LINKS.map((elt) => (
          <li key={elt.link}>
            <a
              href={elt.link}
              onClick={() => {
                trackOutlinkClick(elt.link)
              }}
              target="_blank"
              rel="noopener noreferrer">
              <b>{elt.linkTitle}</b>
            </a>
            {elt.title ? (
              <>
                <b>{' • '}</b>
                {elt.title}
              </>
            ) : undefined}
          </li>
        ))}
      </ul>
    </ECContainer>
  )
}
