import React from 'react'

import moment from 'moment'

import { ECButton } from '../../../components/EC/ECButton'
import { ECTag } from '../../../components/EC/ECTag'
import { ECSelectOption } from '../../../components/EC/forms/ECSelect'
import { ECLink } from '../../../components/EC/navigation/ECLink'
import { ECColorPrimary } from '../../../components/EC/utilities/ECColor'
import { ECColumn, ECColumnViewportProps, ECRow } from '../../../components/EC/utilities/ECGrid'
import { ECSpacable, ECSpacableProps } from '../../../components/EC/utilities/ECSpacable'
import { ECStack } from '../../../components/EC/utilities/ECStack'
import { useBackOfficeTranslate } from '../../../i18n'
import { useBackOfficeUserSelector } from '../../../store/reducers/user/reducer'
import { useScopesData } from '../../../utils/hooks/useScopesData'

interface GenericRowProps {
  labelKey?: string
  value?: string
  isLeftBold?: boolean
  widthLeft?: ECColumnViewportProps
  widthRight?: ECColumnViewportProps
  color?: ECColorPrimary
}

const rowMargins: ECSpacable = { vertical: 's' }

const leftColWidth: ECColumnViewportProps = { all: 4 }
const rightColWidth: ECColumnViewportProps = { all: 8 }

const labelColor = 'grey'

const GenericRow: React.FC<GenericRowProps & ECSpacableProps> = ({
  labelKey,
  value,
  isLeftBold = false,
  widthLeft = leftColWidth,
  widthRight = rightColWidth,
  color = labelColor,
  margin = rowMargins,
}) => {
  const t = useBackOfficeTranslate()
  return (
    <ECRow margin={margin}>
      <ECColumn col={widthLeft} color={color} className="align_right">
        {labelKey ? isLeftBold ? <b>{t(`users.specs.${labelKey}`)}</b> : t(`users.specs.${labelKey}`) : undefined}
      </ECColumn>
      <ECColumn col={widthRight}>{value}</ECColumn>
    </ECRow>
  )
}

export interface UserProfileProps {
  user: BackOfficeUser
  noShow?: Array<'scopes' | 'role' | 'activationStatus' | 'editButton'>
}

export const UserProfile: React.FC<UserProfileProps> = ({ user, noShow = [] }) => {
  const t = useBackOfficeTranslate()
  const currentUser = useBackOfficeUserSelector()
  const { fishSpecies, geographicalAreas, anglersGroups } = useScopesData()

  const isCurrentUser = currentUser?.id === user.id

  const canEdit = !noShow.includes('editButton') && user.isEditableByCurrentUser === true && !isCurrentUser

  const fishesScopes: ECSelectOption[] = React.useMemo(() => {
    return (user.scopes?.fishSpecies.selected ?? [])
      .map((id) => fishSpecies.find((elt) => elt.value === id))
      .filter((elt) => !!elt) as ECSelectOption[]
  }, [fishSpecies, user.scopes])
  const geographicalAreasScopes: ECSelectOption[] = React.useMemo(() => {
    return (user.scopes?.geographicalAreas.selected ?? [])
      .map((id) => geographicalAreas.find((elt) => elt.value === id))
      .filter((elt) => !!elt) as ECSelectOption[]
  }, [geographicalAreas, user.scopes])
  const anglersGroupsScopes: ECSelectOption[] = React.useMemo(() => {
    return (user.scopes?.anglersGroups.selected ?? [])
      .map((id) => anglersGroups.find((elt) => elt.value === id))
      .filter((elt) => !!elt) as ECSelectOption[]
  }, [anglersGroups, user.scopes])

  return (
    <>
      <GenericRow labelKey="firstName" value={user.firstName} />
      <GenericRow labelKey="lastName" value={user.lastName} />
      <GenericRow labelKey="institution" value={user.institution} />
      <GenericRow labelKey="email" value={user.email} />
      {isCurrentUser && (
        <ECRow margin={rowMargins}>
          <ECColumn col={leftColWidth} />
          <ECColumn col={rightColWidth}>
            <ECLink path="/backOfficeUsers/me/changePassword">{t('users.updatePasswordLink')}</ECLink>
          </ECColumn>
        </ECRow>
      )}
      <ECRow margin={rowMargins}>
        <ECColumn col={leftColWidth} color={labelColor} className="align_right">
          {t(`users.specs.parentUser`)}
        </ECColumn>
        <ECColumn col={rightColWidth}>
          {user.creator ? (
            <ECLink path={`/backOfficeUsers/${user.creator.id}`}>
              {user.creator.firstName} {user.creator.lastName}
            </ECLink>
          ) : (
            ''
          )}
        </ECColumn>
      </ECRow>
      {!noShow.includes('activationStatus') && (
        <GenericRow
          isLeftBold
          labelKey="activeStatus.status"
          value={
            user.isActive
              ? user.isActiveUntil
                ? t('users.specs.activeStatus.activeUntil', { endDate: moment(user.isActiveUntil).format('lll') })
                : t('users.specs.activeStatus.active')
              : t('users.specs.activeStatus.inactive')
          }
        />
      )}
      {!noShow.includes('role') && user.role && user.usableRole && (
        <>
          <GenericRow
            margin={{ top: 'l' }}
            labelKey="role.title"
            isLeftBold
            value={t(`users.role.${user.role.type}.name`)}
          />
          {user.usableRole.canCreateUsersWithRoles.length > 0 && (
            <GenericRow
              labelKey=""
              value={t(
                `users.specs.roleCanCreateOtherUser.${user.usableRole.canCreateUsersWithRoles ? 'yes' : 'false'}`,
              )}
            />
          )}
          <GenericRow
            labelKey="role.dataAggregation.period.title"
            value={t(`users.specs.role.dataAggregation.period.${user.usableRole.aggregationPeriod}`)}
          />
          <GenericRow
            labelKey="role.dataAggregation.geography.title"
            value={t(`users.specs.role.dataAggregation.geography.${user.usableRole.aggregationGeography}`)}
          />
        </>
      )}
      {!noShow.includes('scopes') && (
        <>
          <GenericRow margin={{ top: 'l' }} labelKey="scope" isLeftBold />
          <ECRow margin={rowMargins}>
            <ECColumn col={leftColWidth} color={labelColor} className="align_right">
              {t(`users.scope.species`)}
            </ECColumn>
            <ECColumn col={rightColWidth}>
              {user.scopes?.fishSpecies.isAll === true
                ? t('users.createForm.scopes.fishSpecies.all.true')
                : fishesScopes.length === 0
                ? t('users.scope.none')
                : fishesScopes.map((elt) => <ECTag key={elt.value}>{elt.title}</ECTag>)}
            </ECColumn>
          </ECRow>
          <ECRow margin={rowMargins}>
            <ECColumn col={leftColWidth} color={labelColor} className="align_right">
              {t(`users.scope.zones`)}
            </ECColumn>
            <ECColumn col={rightColWidth}>
              {user.scopes?.geographicalAreas.isAll === true
                ? t('users.createForm.scopes.geographicalAreas.all.true')
                : geographicalAreasScopes.length === 0
                ? t('users.scope.none')
                : geographicalAreasScopes.map((elt) => <ECTag key={elt.value}>{elt.title}</ECTag>)}
            </ECColumn>
          </ECRow>
          <ECRow margin={rowMargins}>
            <ECColumn col={leftColWidth} color={labelColor} className="align_right">
              {t(`users.scope.anglersGroups`)}
            </ECColumn>
            <ECColumn col={rightColWidth}>
              {user.scopes?.anglersGroups.isAll === true
                ? t('users.createForm.scopes.anglersGroups.all.true')
                : anglersGroupsScopes.length === 0
                ? t('users.scope.none')
                : anglersGroupsScopes.map((elt) => <ECTag key={elt.value}>{elt.title}</ECTag>)}
            </ECColumn>
          </ECRow>
        </>
      )}
      {canEdit && (
        <ECRow margin={rowMargins}>
          <ECColumn col={leftColWidth} color={labelColor} className="align_right"></ECColumn>
          <ECColumn col={rightColWidth}>
            <ECStack horizontalAlignment="start">
              <ECButton type="primary" text={t('users.edit')} href={`/backOfficeUsers/${user.id}/edit`}></ECButton>
            </ECStack>
          </ECColumn>
        </ECRow>
      )}
    </>
  )
}
