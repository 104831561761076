/**
 * See https://ec.europa.eu/component-library/ec/utilities/dimension/usage/
 */

import classnames from 'classnames'

export interface ECDimensionProps {
  /** in % */
  width?: number
  hasWidthAuto?: boolean
  /** in % */
  height?: number
  hasHeightAuto?: boolean
}

export interface ECDimensionableProps {
  dimensions?: ECDimensionProps
}

export const useDimensionClass = (dimensions?: ECDimensionProps) => {
  if (dimensions) {
    return classnames({
      'ecl-u-width-auto': dimensions.hasWidthAuto,
      [`ecl-u-width-${dimensions.width}`]: !dimensions.hasWidthAuto && (dimensions.width ?? 0) > 0,
      'ecl-u-height-auto': dimensions.hasHeightAuto,
      [`ecl-u-height-${dimensions.height}`]: !dimensions.hasHeightAuto && (dimensions.height ?? 0) > 0,
    })
  }
}
