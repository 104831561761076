import React from 'react'

import { countries, Country } from 'countries-list'
import RandExp from 'randexp'
import { useForm } from 'react-hook-form'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { anglersApi } from '../../../../api/anglersApi'
import { getCurrentIpGeoloc } from '../../../../api/geoipApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECCheckBox } from '../../../../components/EC/forms/ECCheckBox'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECSelect, ECSelectOption } from '../../../../components/EC/forms/ECSelect'
import { ECSelectMulti } from '../../../../components/EC/forms/ECSelectMulti'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { ECDimensionProps } from '../../../../components/EC/utilities/ECDimensionable'
import { ECColumn, ECGrid, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../../components/EC/utilities/ECSpacable'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useAnglersTranslate, useCommonTranslate } from '../../../../i18n'
import { AnglersLoginService } from '../../../../services/anglers/anglers-login-service'
import { useOAuthAuthorizeRedirectUrl } from '../../../../store/reducers/oauthReducer'
import { Logger } from '../../../../utils/Logger'
import './RegisterPage.scss'

const zipCodesByCountries = require('../../../../utils/data/zipCodesByCountries.json')

const countriesOptions = Object.entries(countries).map(([key, elt]) => ({
  title: `${elt.native} (${elt.name})`,
  key: key,
  value: key,
}))

const regexpExamplesCache: { [regexp: string]: string } = {}
const getRegexpExample = (regexp: string) => {
  if (regexpExamplesCache[regexp]) {
    return regexpExamplesCache[regexp]
  }
  regexpExamplesCache[regexp] = [
    `"${new RandExp(regexp).gen()}"`,
    `"${new RandExp(regexp).gen()}"`,
    `"${new RandExp(regexp).gen()}"`,
  ].join(', ')
  return regexpExamplesCache[regexp]
}

export const RegisterPage: React.FC = () => {
  const t = useAnglersTranslate()
  const tc = useCommonTranslate()

  const dispatch = useDispatch()
  const oAuthAuthroizedRedirectUrl = useOAuthAuthorizeRedirectUrl()
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [errorCreatingUser, setErrorCreatingUser] = React.useState<string>()

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      email: yup.string().email(t('register.email.error')).required(t('register.email.error')),
      password: yup.string().min(6).required(),
      gender: yup.string().oneOf(['M', 'F']),
      birthYear: yup.number().min(1900).max(new Date().getFullYear()).typeError(t('register.birthYear.error')),
      countryOfResidence: yup.string(),
      zipCode: yup.string().when('countryOfResidence', (country: string | undefined) => {
        const zipCodeRegexp: string = country && country !== 'placeholder' ? zipCodesByCountries[country] ?? '.*' : '.*'
        let countryName = ''
        if (country && country !== 'placeholder') {
          countryName = (countries as { [code: string]: Country })[country]?.name
        }
        return yup.string().matches(new RegExp(`(^${zipCodeRegexp}$)`), {
          message: t('register.zipCode.error', {
            countryName,
            example: getRegexpExample(`^${zipCodeRegexp}$`),
          }),
        })
      }),
      ownsBoat: yup.string().oneOf(['true', 'false'], t('register.ownsBoat.mandatory')).required(),
      boatCountry: yup.string().when('ownsBoat', {
        is: 'true',
        then: yup.string().notOneOf(['placeholder'], t('register.boatCountry.mandatory')).required(),
      }),
      acceptTOUs: yup.boolean().required().oneOf([true], t('register.acceptTOUs.error')),
    })
  }, [t])

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: 'onBlur',
    validationSchema,
    defaultValues: {
      email: '',
      password: '',
      birthYear: '',
      zipCode: '',
      countryOfResidence: 'placeholder',
      gender: 'placeholder',
      acceptTOUs: false,
      ownsBoat: 'placeholder' as 'true' | 'false' | 'placeholder',
      boatCountry: 'placeholder',
      anglersGroups: [] as string[],
    },
  })

  React.useEffect(() => {
    register('anglersGroups')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const password = watch('password')
  const ownsBoat = watch('ownsBoat')
  const acceptTOUs = watch('acceptTOUs')

  React.useEffect(() => {
    getCurrentIpGeoloc().then((approxGeoloc) => {
      if (approxGeoloc?.country_code) {
        Logger.debug(approxGeoloc?.country_code)
        setValue('countryOfResidence', approxGeoloc?.country_code)
      }
    })
  }, [setValue])

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true)
    setErrorCreatingUser(undefined)
    let birthYear: number | undefined
    try {
      birthYear = Number.parseInt(values.birthYear, 10)
    } catch (err) {}
    const username = values.email.trim()
    try {
      await anglersApi.postAngler({
        email: username,
        password: values.password,
        birthYear,
        zipCode: values.zipCode.trim(),
        countryOfResidence: values.countryOfResidence !== 'placeholder' ? values.countryOfResidence : undefined,
        gender: values.gender !== 'N/A' && values.gender !== 'placeholder' ? values.gender : undefined,
        ownsBoat: values.ownsBoat === 'true',
        boatCountry:
          values.ownsBoat === 'true'
            ? values.boatCountry !== 'placeholder'
              ? values.boatCountry
              : undefined
            : undefined,
        anglersGroups: values.anglersGroups,
      })
      setErrorCreatingUser(undefined)
      await AnglersLoginService.login({ username, password: values.password })
      await AnglersLoginService.refreshAngler(dispatch)
      if (oAuthAuthroizedRedirectUrl) {
        //redirect to mainServer/authorize
        window.location.href = oAuthAuthroizedRedirectUrl
      } else {
        history.push('/profile')
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        setErrorCreatingUser(t('register.error.badRequest'))
      } else {
        setErrorCreatingUser(t('register.error.unknown'))
      }
    }
    setLoading(false)
    Logger.debug('values=', values)
  })

  const genderOptions = React.useMemo(() => {
    return [
      {
        key: 'M',
        value: 'M',
        title: t('register.gender.options.M'),
      },
      {
        key: 'F',
        value: 'F',
        title: t('register.gender.options.F'),
      },
    ]
  }, [t])

  const ownsBoatOptions = React.useMemo(() => {
    return [
      {
        key: 'true',
        value: 'true',
        title: t('register.ownsBoat.options.true'),
      },
      {
        key: 'false',
        value: 'false',
        title: t('register.ownsBoat.options.false'),
      },
    ]
  }, [t])

  const [allAnglersGroups, setAllAnglersGroups] = React.useState<AnglersGroup[]>([])
  React.useEffect(() => {
    anglersApi
      .listAnglersGroups()
      .then((groups) => {
        setAllAnglersGroups(groups)
      })
      .catch((err) => {})
  }, [])
  const anglersGroupsOptions: ECSelectOption[] = React.useMemo(() => {
    return allAnglersGroups.map((elt) => ({
      value: elt.id,
      title: elt.name,
    }))
  }, [allAnglersGroups])

  const titleMargin: ECSpacable = { bottom: 'l' }
  const formElementMargin: ECSpacable = { vertical: 's' }
  const formGroupMargin: ECSpacable = { ...formElementMargin, top: '4xl' }
  const textInputDimension: ECDimensionProps = { width: 100 }

  return (
    <ECForm onSubmit={onSubmit} className="ecl-u-mh-auto">
      <ECGrid>
        <ECColumn col={{ lg: 6 }} offset={{ lg: 3 }}>
          <ECTitle level={1} margin={titleMargin}>
            {t('register.title')}
          </ECTitle>
          <ECTextInput
            id="email"
            name="email"
            inputType="email"
            label={t('register.email.label')}
            placeholder="fisher@email.com"
            ref={register}
            error={errors.email}
            margin={formGroupMargin}
            width={textInputDimension}
            isDisabled={loading}
            isRequired
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          <ECTextInput
            id="password"
            inputType="password"
            name="password"
            label={t('register.password.label')}
            help={t('register.password.help')}
            placeholder="***"
            ref={register}
            isRequired
            error={errors.password}
            margin={formElementMargin}
            width={textInputDimension}
            isDisabled={loading}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          <PasswordStrengthBar
            password={password}
            minLength={6}
            shortScoreWord={t('updatePassword.strength.tooShort')}
            scoreWordStyle={{ color: 'black' }}
            scoreWords={[
              t('updatePassword.strength.weak'),
              t('updatePassword.strength.okay'),
              t('updatePassword.strength.good'),
              t('updatePassword.strength.strong'),
            ]}
          />
          <ECSelect
            id="gender"
            name="gender"
            ref={register}
            label={t('register.gender.label')}
            placeholder={t('register.gender.placeholder')}
            isRequired
            options={genderOptions}
            margin={formGroupMargin}
            isDisabled={loading}
          />
          <ECTextInput
            id="birthYear"
            inputType="number"
            name="birthYear"
            label={t('register.birthYear.label')}
            placeholder="1990"
            ref={register}
            error={errors.birthYear}
            isRequired
            margin={formElementMargin}
            width={textInputDimension}
            isDisabled={loading}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          <ECSelect
            id="countryOfResidence"
            name="countryOfResidence"
            ref={register}
            label={t('register.countryOfResidence.label')}
            placeholder={t('register.countryOfResidence.placeholder')}
            isRequired
            options={countriesOptions}
            margin={formElementMargin}
            isDisabled={loading}
          />
          <ECTextInput
            id="zipCode"
            name="zipCode"
            label={t('register.zipCode.label')}
            placeholder={t('register.zipCode.label')}
            ref={register}
            isRequired
            error={errors.zipCode}
            margin={formElementMargin}
            width={textInputDimension}
            isDisabled={loading}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          <ECSelectMulti
            id="anglersGroups"
            name="anglersGroups"
            label={t('register.anglersGroups.label')}
            placeholder={t('register.anglersGroups.placeholder')}
            options={anglersGroupsOptions}
            forcePlaceholder
            onChange={(values) => setValue('anglersGroups', values)}
            width="l"
          />
          <ECSelect
            id="ownsBoat"
            error={errors.ownsBoat}
            name="ownsBoat"
            ref={register}
            label={t('register.ownsBoat.label')}
            placeholder={t('register.ownsBoat.placeholder')}
            isRequired
            options={ownsBoatOptions}
            margin={formElementMargin}
            isDisabled={loading}
          />
          {ownsBoat === 'true' ? (
            <ECSelect
              id="boatCountry"
              name="boatCountry"
              error={errors.boatCountry}
              ref={register}
              label={t('register.boatCountry.label')}
              placeholder={t('register.boatCountry.placeholder')}
              isRequired={ownsBoat === 'true'}
              options={countriesOptions}
              margin={formElementMargin}
              isDisabled={loading}
            />
          ) : undefined}
          <ECRow margin={{ top: '2xl' }} />
          <ECCheckBox
            id="acceptTOUs-checkbox"
            name="acceptTOUs"
            className="information-checkbox"
            isInvalid={!!errors.acceptTOUs}
            ref={register}
            isChecked={acceptTOUs}
            onClick={() => setValue('acceptTOUs', !acceptTOUs)}>
            <ECText>{t('register.acceptTOUs.text')}</ECText>
          </ECCheckBox>
          <ECFormErrorMessage id="acceptTOUs-checkbox" error={errors.acceptTOUs} />
          <ECText margin={{ top: 'none' }} className="align_right">
            <ECLink
              targetBlank
              title={tc('footer.more.terms-of-use')}
              href="https://ec.europa.eu/info/legal-notice_en"
            />
            <br />
            <ECLink targetBlank title={tc('footer.more.detail-data')} path="/specific-privacy-statement" />
          </ECText>
          <ECRow margin={{ top: '2xl' }}>
            {errorCreatingUser && (
              <ECFormErrorMessage id="error-creating-user" error={{ message: errorCreatingUser }} />
            )}
            <div className="ecl-u-mh-auto">
              {loading ? (
                <Spinner />
              ) : (
                <ECButton isSubmit type="primary" text={t('register.submit')} dimensions={textInputDimension} />
              )}
            </div>
          </ECRow>
        </ECColumn>
      </ECGrid>
    </ECForm>
  )
}
