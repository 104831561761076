import React from 'react'

import { ECHeader } from '../../../components/EC/core/ECHeader'
import { useAnglerSelector } from '../../../store/reducers/user/reducer'

export const Header: React.FC = () => {
  const currentUser = useAnglerSelector()
  const loggedIn = !!currentUser

  return <ECHeader isLoggedIn={loggedIn} showLoginStatus={true} profileRoute="/profile" />
}
