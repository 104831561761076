/**
 * See https://ec.europa.eu/component-library/ec/core-template/header/code/
 *
 * todo: get languages list from i18next
 * todo: fix logo/login links
 */

import React, { useCallback, useState } from 'react'

import { ReactComponent as LanguageIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/general/language.svg'
import { ReactComponent as LogInIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/general/log-in.svg'
import { ReactComponent as LoggedInIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/general/logged-in.svg'
import { ReactComponent as CheckIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/check.svg'
import { ReactComponent as CloseIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/close.svg'
import classnames from 'classnames'
import i18next from 'i18next'

import i18n, { useCommonTranslate } from '../../../i18n'
import { useEtransIsLoaded } from '../../../utils/hooks/useEtrans'
import { ECLink } from '../navigation/ECLink'

export interface CloseButtonProps {
  onClick: () => void
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  const tc = useCommonTranslate()
  const onClickLocal = useCallback(() => onClick(), [onClick])
  return (
    <button
      type="submit"
      className="ecl-language-list__close-button ecl-button ecl-button--ghost"
      onClick={onClickLocal}>
      <span className="ecl-button__container" style={{ cursor: 'pointer' }}>
        <span className="ecl-button__label" data-ecl-label="true">
          {tc('header.languages.close')}
        </span>
        <CloseIcon
          focusable="false"
          aria-hidden="true"
          data-ecl-icon="true"
          className="ecl-button__icon ecl-button__icon--after ecl-icon ecl-icon--s"
        />
      </span>
    </button>
  )
}

export interface ECLanguageProps {
  lang?: string
  onClick?: () => void
  title: string
}

export interface ECLanguageListItemProps extends ECLanguageProps {
  isActive?: boolean
}

export const ECLanguageListItem: React.FC<ECLanguageListItemProps> = ({ lang, onClick: _onClick, title, isActive }) => {
  const onClick = useCallback(async () => {
    if (_onClick) {
      _onClick()
      return
    }
    await i18n.changeLanguage(lang)
    window.location.reload()
  }, [lang, _onClick])
  return (
    <li className={classnames('ecl-language-list__item', { 'ecl-language-list__item--is-active': isActive })}>
      <ECLink
        lang={lang}
        rel="alternate"
        className={classnames('ecl-language-list__link ecl-link ecl-link--standalone', {
          'ecl-link--icon ecl-link--icon-after': isActive,
        })}
        onClick={onClick}>
        {title}
        {isActive && (
          <CheckIcon focusable="false" aria-hidden="true" className="ecl-link__icon ecl-icon ecl-icon--xs" />
        )}
      </ECLink>
    </li>
  )
}

export interface ECLanguageListOverlayProps {
  isShown: boolean
  onClose: () => void
  options: Array<ECLanguageProps>
}

export const ECLanguageListOverlay: React.FC<ECLanguageListOverlayProps> = ({ isShown, onClose, options }) => {
  const tc = useCommonTranslate()
  const splitIndex = Math.ceil(options.length / 2)
  const leftColumn = options.slice(0, splitIndex)
  const rightColumn = options.slice(splitIndex)
  const etransIsLoaded = useEtransIsLoaded()

  return (
    <div
      id="language-list-overlay"
      hidden={!isShown}
      className="ecl-language-list ecl-language-list--overlay"
      aria-labelledby="ecl-language-list__title"
      role="dialog"
      data-ecl-language-list-overlay="true">
      <div className="ecl-language-list__container ecl-container">
        <div className="ecl-row">
          <div className="ecl-language-list__close ecl-col-12 ecl-col-lg-8 ecl-offset-lg-2">
            <CloseButton onClick={onClose} />
          </div>
          <div
            className="ecl-language-list__title ecl-col-12 ecl-col-lg-8 ecl-offset-lg-2"
            id="ecl-language-list__title">
            {tc('header.languages.title')}
          </div>
        </div>
        <div className="ecl-row">
          <div className="ecl-language-list__column ecl-col-12 ecl-col-lg-4 ecl-offset-lg-2">
            <ul className="ecl-language-list__list">
              {leftColumn?.map(({ lang, title }, index) => (
                <ECLanguageListItem
                  key={lang}
                  lang={lang}
                  title={title}
                  isActive={i18next.language === lang || (!!lang && i18next.language.startsWith(lang))}
                />
              ))}
            </ul>
          </div>
          <div className="ecl-language-list__column ecl-col-12 ecl-col-lg-4">
            <ul className="ecl-language-list__list">
              {rightColumn?.map(({ lang, title }, index) => (
                <ECLanguageListItem
                  key={lang}
                  lang={lang}
                  title={title}
                  isActive={i18next.language === lang || (!!lang && i18next.language.startsWith(lang))}
                />
              ))}
              {/*etransIsLoaded && (
                <ECLanguageListItem
                  key="translate"
                  lang="translate"
                  title={tc('header.languages.translate')}
                  onClick={() => {
                    onClose()
                    window.$wt.etrans.modal.show()
                  }}
                />
                )*/}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const languageOptions = [
  { lang: 'bg', title: 'Български' },
  { lang: 'cs', title: 'Čeština' },
  { lang: 'da', title: 'Dansk' },
  { lang: 'de', title: 'Deutsch' },
  { lang: 'el', title: 'Ελληνικά' },
  { lang: 'en', title: 'English' },
  { lang: 'es', title: 'Español' },
  { lang: 'et', title: 'Eesti' },
  { lang: 'fi', title: 'Suomi' },
  { lang: 'fr', title: 'Français' },
  { lang: 'ga', title: 'Gaeilge' },
  { lang: 'hr', title: 'Hrvatski' },
  { lang: 'hu', title: 'Magyar' },
  { lang: 'it', title: 'Italiano' },
  { lang: 'lt', title: 'Lietuvių' },
  { lang: 'lv', title: 'Latviešu' },
  { lang: 'mt', title: 'Malti' },
  { lang: 'nl', title: 'Nederlands' },
  { lang: 'pl', title: 'Polski' },
  { lang: 'pt', title: 'Português' },
  { lang: 'ro', title: 'Română' },
  { lang: 'sk', title: 'Slovenčina' },
  { lang: 'sl', title: 'Slovenščina' },
  { lang: 'sv', title: 'Svenska' },
]

export interface ECHeaderProps {
  /** true if logged in */
  isLoggedIn?: boolean
  showLoginStatus?: boolean
  profileRoute?: string
}

export const ECHeader: React.FC<ECHeaderProps> = ({ isLoggedIn, showLoginStatus = true, profileRoute }) => {
  const tc = useCommonTranslate()

  const [overlayShow, setOverlayShow] = useState(false)

  const onLanguageClick = () => setOverlayShow(true)
  const onCloseClick = () => setOverlayShow(false)

  const logoImages: Record<string, string> = { en: '/images/logo--en.30b933cc.svg' }
  const loginStatus = isLoggedIn ? tc('header.authStatus.loggedIn') : tc('header.authStatus.notLoggedIn')

  return (
    <header className="ecl-site-header-core">
      <div className="ecl-site-header-core__container ecl-container">
        <div className="ecl-site-header-core__top">
          <ECLink path="/" className="ecl-link ecl-link--standalone ecl-site-header-core__logo-link">
            <img
              src={logoImages[i18next.language] || logoImages.en}
              alt={tc('header.logoAltKey')}
              className={'ecl-site-header-core__logo-image'}
            />
          </ECLink>
          <div className="ecl-site-header-core__action">
            {showLoginStatus && (
              <div className="ecl-site-header-core__login-container">
                <ECLink
                  className="ecl-link ecl-link--standalone ecl-site-header-core__login-toggle"
                  path={isLoggedIn ? profileRoute : '/login'}>
                  <span className="ecl-site-header-core__icon ecl-icon ecl-icon--s">
                    {isLoggedIn ? (
                      <LoggedInIcon
                        focusable="false"
                        aria-hidden="true"
                        className="ecl-site-header-core__icon ecl-icon ecl-icon--s"
                      />
                    ) : (
                      <LogInIcon
                        focusable="false"
                        aria-hidden="true"
                        className="ecl-site-header-core__icon ecl-icon ecl-icon--s"
                      />
                    )}
                  </span>
                  {loginStatus}
                </ECLink>
              </div>
            )}
            <ECLink
              className="ecl-link ecl-link--standalone ecl-site-header-core__language-selector"
              onClick={onLanguageClick}>
              <span className="ecl-site-header-core__language-icon">
                <LanguageIcon
                  focusable="false"
                  aria-hidden="true"
                  className="ecl-site-header-core__icon ecl-icon ecl-icon--s"
                />
                <span className="ecl-site-header-core__language-code">{i18next.language.split('-')[0]}</span>
              </span>
              {tc('header.languages.title')}
            </ECLink>
            {window._env_.REACT_APP_SHOW_ETRANS === 'true' && (
              <div style={{}}>
                <script type="application/json">
                  {JSON.stringify({
                    service: 'etrans',
                    languages: {
                      exclude: ['en'],
                    },
                    dynamic: true,
                    renderAs: {
                      icon: false,
                      button: false,
                      link: false,
                    },
                  })}
                </script>
              </div>
            )}
          </div>
        </div>
      </div>
      <ECLanguageListOverlay isShown={overlayShow} onClose={onCloseClick} options={languageOptions} />
      <div className="ecl-site-header-standardised__banner">
        <div className="ecl-container">Recreational Fisheries (RecFishing)</div>
      </div>
      <div style={{ height: 22 }} />
    </header>
  )
}
