import produce from 'immer'
import { useSelector } from 'react-redux'
import { Action } from 'redux'

import { Filters } from '../../containers/back-office/indicators/FilterSection'
import { AppReduxState } from './reducers'

export interface FiltersReduxState {
  filters?: Filters
}

export const SetFiltersActionType = '@@actionTypes/SetFilters'
export interface SetFiltersAction extends Action<typeof SetFiltersActionType> {
  payload: {
    filters?: Filters
  }
}
export const SetFiltersActionCreator = (filters?: Filters): SetFiltersAction => ({
  type: SetFiltersActionType,
  payload: { filters },
})

export const filtersReducerInitialState: FiltersReduxState = {}

// REDUCERS
export const FiltersReducer = (state = filtersReducerInitialState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SetFiltersActionType:
        draft.filters = (action as SetFiltersAction).payload.filters
        break
    }
  })

export const useLatestSavedFilters = () =>
  useSelector((appState: AppReduxState) => {
    return appState.filters.filters
  })
