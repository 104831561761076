import React from 'react'

import { backOfficeApi } from '../../api/backOfficeApi'
import { ECSelectOption } from '../../components/EC/forms/ECSelect'

export const useScopesData = () => {
  const [scopes, setScopes] = React.useState<Scopes>()

  const [fishSpecies, setFishSpecies] = React.useState<ECSelectOption[]>([])
  const [geographicalAreas, setGeographicalAreas] = React.useState<ECSelectOption[]>([])
  const [anglersGroups, setAnglersGroups] = React.useState<ECSelectOption[]>([])

  const refresh = React.useCallback(async () => {
    backOfficeApi.getScopes().then((newScopes) => {
      setScopes(newScopes)
    })
    backOfficeApi.getSpecies().then((newSpecies) => {
      setFishSpecies(
        newSpecies
          .map((elt) => ({ title: `<i>${elt.scientificName}</i> (${elt.faoCode})`, value: elt.id }))
          .sort((a, b) => a.title.localeCompare(b.title)),
      )
    })
    backOfficeApi.getGeographicalAreas().then((newGeographicalAreas) => {
      setGeographicalAreas(newGeographicalAreas.map((elt) => ({ title: elt.name_locale ?? elt.name, value: elt.id })))
    })
    backOfficeApi.getAnglersGroups().then((newAnglersGroups) => {
      setAnglersGroups(newAnglersGroups.map((elt) => ({ title: elt.name, value: elt.id })))
    })
  }, [])

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    scopes,
    fishSpecies,
    geographicalAreas,
    anglersGroups,
    refresh,
  }
}
