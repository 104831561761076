import React from 'react'

import { useForm } from 'react-hook-form'
import PasswordStrengthBar from 'react-password-strength-bar'
import * as yup from 'yup'

import { anglersApi } from '../../../api/anglersApi'
import { backOfficeApi } from '../../../api/backOfficeApi'
import { ECPageBanner } from '../../../components/EC/core/ECPageBanner'
import { ECButton } from '../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../components/EC/forms/ECForm'
import { ECTextInput } from '../../../components/EC/forms/ECTextInput'
import { ECDimensionProps } from '../../../components/EC/utilities/ECDimensionable'
import { ECColumn, ECGrid, ECRow } from '../../../components/EC/utilities/ECGrid'
import { ECText } from '../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../components/Spinner/Spinner'
import { useAnglersTranslate } from '../../../i18n'

export interface ResetPasswordFillProps {
  token: string
}

export const ResetPasswordFill: React.FC<ResetPasswordFillProps> = ({ token }) => {
  const t = useAnglersTranslate()

  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoginError, setShowLoginError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const validationSchema = yup.object({
    password: yup.string().min(6).required(),
  })

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      password: '',
    },
    validationSchema,
  })
  const password = watch('password')

  const isAdmin = window._env_.REACT_APP_RCSB_TYPE === 'admin'

  const onSubmit = handleSubmit(async (values) => {
    if (loading) {
      return
    }
    try {
      setLoading(true)
      setShowLoginError(false)
      setShowSuccess(false)
      if (isAdmin) {
        await backOfficeApi.saveResetPassword(values.password, token)
      } else {
        await anglersApi.saveResetPassword(values.password, token)
      }
      setShowSuccess(true)
    } catch (err) {
      setShowLoginError(true)
    }
    setLoading(false)
  })

  const textInputDimension: ECDimensionProps = { width: 100 }

  if (showSuccess) {
    return (
      <ECPageBanner
        background="blue"
        title={t('resetPasswordFill.success.title')}
        baseline={t('resetPasswordFill.success.baseline')}
        buttons={[{ title: t('resetPasswordFill.logIn'), path: '/login' }]}
      />
    )
  }

  return (
    <ECForm onSubmit={onSubmit}>
      <ECGrid>
        <ECColumn col={{ lg: 6 }} offset={{ lg: 3 }}>
          <ECTitle level={1} margin={{ bottom: 'l', horizontal: 'auto' }}>
            {t('resetPasswordFill.title')}
          </ECTitle>
          <ECText>{t('resetPasswordFill.baseline')}</ECText>
          <ECTextInput
            inputType="password"
            id="password"
            name="password"
            label={t('resetPasswordFill.password.label')}
            placeholder="***"
            isRequired
            ref={register}
            error={errors.password}
            width={textInputDimension}
            margin={{ vertical: 's', horizontal: 'auto' }}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
            isDisabled={loading}
          />
          <PasswordStrengthBar
            password={password}
            minLength={6}
            shortScoreWord={t('updatePassword.strength.tooShort')}
            scoreWordStyle={{ color: 'black' }}
            scoreWords={[
              t('updatePassword.strength.weak'),
              t('updatePassword.strength.okay'),
              t('updatePassword.strength.good'),
              t('updatePassword.strength.strong'),
            ]}
          />
          {showLoginError ? (
            <div className="ecl-u-mh-auto">
              <ECFormErrorMessage id="login-error" error={{ message: t('resetPasswordFill.error') }} />
            </div>
          ) : undefined}
          <ECRow>
            <div className="ecl-u-mh-auto">
              {loading ? (
                <Spinner />
              ) : (
                <ECButton
                  type="primary"
                  isSubmit
                  text={t('resetPasswordFill.submit')}
                  margin={{ top: 's', bottom: 's', horizontal: 'auto' }}
                  padding={{ horizontal: '4xl' }}
                />
              )}
            </div>
          </ECRow>
        </ECColumn>
      </ECGrid>
    </ECForm>
  )
}
