type EventName = 'public-logged-out' | 'admin-logged-out'

type Handler = (opts: { event: EventName; args?: any }) => void

const subscribers: { [event: string]: { id: number; handler: Handler }[] } = {}

let lastId = 0

const subscribe = (event: EventName, handler: Handler) => {
  if (!subscribers[event]) {
    subscribers[event] = []
  }
  lastId += 1
  subscribers[event].push({ id: lastId, handler })
  return lastId
}

const publish = (event: EventName, args?: any) => {
  const eventSubscribers = subscribers[event]

  if (!eventSubscribers) {
    return
  }
  eventSubscribers.forEach((subscriber) => {
    if (subscriber && typeof subscriber.handler === 'function') {
      try {
        subscriber.handler({ event, args })
      } catch (e) { }
    }
  })
}

const unsubscribe = (event: EventName, id: number) => {
  subscribers[event] = (subscribers[event] || []).filter((subscriber) => subscriber.id !== id)
}

export const PubSub = {
  subscribe,
  publish,
  unsubscribe,
}
