import React from 'react'

import { useForm } from 'react-hook-form'
import PasswordStrengthBar from 'react-password-strength-bar'
import * as yup from 'yup'

import { anglersApi } from '../../../../api/anglersApi'
import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECPageBanner } from '../../../../components/EC/core/ECPageBanner'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECDimensionProps } from '../../../../components/EC/utilities/ECDimensionable'
import { ECColumn, ECGrid, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useAnglersTranslate } from '../../../../i18n'

export const ChangePasswordPage = () => {
  const t = useAnglersTranslate()

  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoginError, setShowLoginError] = React.useState(false)

  const validationSchema = yup.object({
    oldPassword: yup.string().min(6).required(),
    password: yup.string().min(6).required(),
  })

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      oldPassword: '',
      password: '',
    },
    validationSchema,
  })
  const password = watch('password')

  const onSubmit = handleSubmit(async (values) => {
    try {
      setShowLoginError(false)
      setShowSuccess(false)
      if (window._env_.REACT_APP_RCSB_TYPE === 'admin') {
        await backOfficeApi.updatePassword(values.oldPassword, values.password)
      } else {
        await anglersApi.updatePassword(values.oldPassword, values.password)
      }
      setShowSuccess(true)
    } catch (err) {
      setShowLoginError(true)
    }
  })

  const textInputDimension: ECDimensionProps = { width: 100 }

  if (showSuccess) {
    return (
      <ECPageBanner
        background="blue"
        title={t('updatePassword.success.title')}
        baseline={t('updatePassword.success.baseline')}
      />
    )
  }

  return (
    <ECForm onSubmit={onSubmit}>
      <ECGrid>
        <ECColumn col={{ lg: 6 }} offset={{ lg: 3 }}>
          <ECTitle level={1} margin={{ bottom: 'l', horizontal: 'auto' }}>
            {t('updatePassword.title')}
          </ECTitle>
          <ECText>{t('updatePassword.baseline')}</ECText>
          <ECTextInput
            inputType="password"
            id="oldPassword"
            name="oldPassword"
            label={t('updatePassword.oldPassword.label')}
            placeholder="***"
            isRequired
            ref={register}
            error={errors.oldPassword}
            width={textInputDimension}
            margin={{ vertical: 's', horizontal: 'auto' }}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          <ECTextInput
            inputType="password"
            id="password"
            name="password"
            label={t('updatePassword.password.label')}
            placeholder="***"
            isRequired
            ref={register}
            error={errors.password}
            width={textInputDimension}
            margin={{ vertical: 's', horizontal: 'auto' }}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          <PasswordStrengthBar
            password={password}
            minLength={6}
            shortScoreWord={t('updatePassword.strength.tooShort')}
            scoreWordStyle={{ color: 'black' }}
            scoreWords={[
              t('updatePassword.strength.weak'),
              t('updatePassword.strength.okay'),
              t('updatePassword.strength.good'),
              t('updatePassword.strength.strong'),
            ]}
          />
          {showLoginError ? (
            <div className="ecl-u-mh-auto">
              <ECFormErrorMessage id="login-error" error={{ message: t('updatePassword.error') }} />
            </div>
          ) : undefined}
          <ECRow>
            <ECButton
              type="primary"
              isSubmit
              text={t('updatePassword.submit')}
              margin={{ top: 's', bottom: 's', horizontal: 'auto' }}
              padding={{ horizontal: '4xl' }}
            />
          </ECRow>
        </ECColumn>
      </ECGrid>
    </ECForm>
  )
}
