import React from 'react'

import moment from 'moment'

import { ECFormGroup } from '../../../components/EC/forms/ECFormGroup'
import { ECSelect, ECSelectOption } from '../../../components/EC/forms/ECSelect'
import { ECColumn, ECRow } from '../../../components/EC/utilities/ECGrid'
import i18n from '../../../i18n'

export interface MonthSelectorProps {
  name: string
  legend?: string
  help?: string
  isDisabled?: boolean
  month: number
  year: number
  onChange: (month: number, year: number) => void
}

export const MonthSelector: React.FC<MonthSelectorProps> = ({
  name,
  legend,
  help,
  isDisabled,
  month,
  year,
  onChange,
}) => {
  const monthsOptions = React.useMemo(() => {
    moment.locale(i18n.language)
    return moment.months().map(
      (elt, index) =>
        ({
          value: `${index}`,
          title: elt,
        } as ECSelectOption),
    )
  }, [])

  const yearsOptions = React.useMemo(() => {
    const currentYear = new Date().getFullYear()
    let startAt = 2020
    let results: number[] = []
    while (startAt < currentYear) {
      results.push(startAt)
      startAt++
    }
    results.push(currentYear)
    return results.map((elt) => ({
      value: `${elt}`,
      title: `${elt}`,
    }))
  }, [])

  const onSelectMonth = React.useCallback(
    (value: string) => {
      onChange(Number.parseInt(value, 10), year)
    },
    [onChange, year],
  )

  const onSelectYear = React.useCallback(
    (value: string) => {
      onChange(month, Number.parseInt(value, 10))
    },
    [month, onChange],
  )

  return (
    <ECFormGroup id={`${name}-attribution`} legend={legend} help={help}>
      <ECRow>
        <ECColumn col={{ sm: 6, md: 12, lg: 6 }}>
          <ECSelect
            id={`perido-${name}-months`}
            name={`period-${name}-months`}
            options={monthsOptions}
            selectedValue={`${month}`}
            onSelect={onSelectMonth}
            isDisabled={isDisabled}
          />
        </ECColumn>
        <ECColumn col={{ sm: 6, md: 12, lg: 6 }}>
          <ECSelect
            id={`period-${name}-year`}
            name={`period-${name}-year`}
            options={yearsOptions}
            selectedValue={`${year}`}
            onSelect={onSelectYear}
            isDisabled={isDisabled}
          />
        </ECColumn>
      </ECRow>
    </ECFormGroup>
  )
}
