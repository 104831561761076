import React from 'react'

import { ECFormErrorMessage } from '../../../components/EC/forms/ECForm'
import { ECFormGroup } from '../../../components/EC/forms/ECFormGroup'
import { ECRadioInput } from '../../../components/EC/forms/ECRadio'
import { ECSelect } from '../../../components/EC/forms/ECSelect'
import { ECSpacable } from '../../../components/EC/utilities/ECSpacable'
import { useBackOfficeTranslate } from '../../../i18n'
import { useBackOfficeUserSelector } from '../../../store/reducers/user/reducer'

export interface RoleEditFormProps {
  values?: EditRoleValues
  onChange: (newValue: EditRoleValues) => void
  hasError?: boolean
}

export const RoleEditForm: React.FC<RoleEditFormProps> = ({ values, onChange, hasError }) => {
  const t = useBackOfficeTranslate()

  const currentUser = useBackOfficeUserSelector()

  const internalOnChange = React.useCallback(
    (newValues?: EditRoleValues) => {
      onChange(newValues ?? {})
    },
    [onChange],
  )

  const onSelectRole = React.useCallback(
    (role: string) => {
      internalOnChange({
        ...(values ?? {}),
        role: role as EditRoleValues['role'],
      })
    },
    [internalOnChange, values],
  )

  const onSelectCanCreateUser = React.useCallback(
    (newValue: string) => {
      internalOnChange({
        ...(values ?? {}),
        canManageUsers: newValue === 'true',
      })
    },
    [internalOnChange, values],
  )

  const onSelectAggregPeriod = React.useCallback(
    (newValue: string) => {
      internalOnChange({
        ...(values ?? {}),
        aggregPeriod: newValue as EditRoleValues['aggregPeriod'],
      })
    },
    [internalOnChange, values],
  )

  const onSelectAggregGeography = React.useCallback(
    (newValue: string) => {
      internalOnChange({
        ...(values ?? {}),
        aggregGeography: newValue as EditRoleValues['aggregGeography'],
      })
    },
    [internalOnChange, values],
  )

  const sectionsMargin: ECSpacable = { top: 'l' }
  const subSectionsMargin: ECSpacable = { top: 'l', left: 'l' }

  if (!currentUser?.usableRole?.canCreateUsersWithRoles) {
    return <></>
  }

  return (
    <>
      <ECFormGroup id="roles-description" legend={t('users.role.title')} margin={sectionsMargin}>
        {hasError && (
          <ECFormErrorMessage id="role-error" error={{ message: t('users.role.mandatory') }}></ECFormErrorMessage>
        )}
        {currentUser.usableRole.canCreateUsersWithRoles.map((elt) => (
          <React.Fragment key={elt}>
            <ECRadioInput
              id={`role-${elt}`}
              value={elt}
              label={t(`users.role.${elt}.name`)}
              isChecked={values?.role === elt}
              onSelect={onSelectRole}
            />
            {elt === values?.role && elt === 'SCI' ? (
              <>
                <ECFormGroup
                  id="roles-aggreg-period"
                  legend={t('users.role.dataAggregation.period.title')}
                  margin={subSectionsMargin}>
                  <ECSelect
                    id={`role-${elt}-aggreg-period`}
                    name={`role-${elt}-aggreg-period`}
                    options={[
                      { value: 'month', title: t(`users.role.dataAggregation.period.month`) },
                      { value: 'day', title: t(`users.role.dataAggregation.period.day`) },
                    ]}
                    selectedValue={values?.aggregPeriod ?? 'month'}
                    onSelect={onSelectAggregPeriod}
                    width="m"
                  />
                </ECFormGroup>
                <ECFormGroup
                  id="roles-aggreg-geography"
                  legend={t('users.role.dataAggregation.geography.title')}
                  margin={subSectionsMargin}>
                  <ECSelect
                    id={`role-elt-aggreg-geography`}
                    name={`role-elt-aggreg-geography`}
                    options={[
                      { value: 'country', title: t(`users.role.dataAggregation.geography.country`) },
                      { value: 'ices', title: t(`users.role.dataAggregation.geography.ices`) },
                    ]}
                    selectedValue={values?.aggregGeography ?? 'country'}
                    onSelect={onSelectAggregGeography}
                    width="m"
                  />
                </ECFormGroup>
              </>
            ) : undefined}
          </React.Fragment>
        ))}
      </ECFormGroup>
      {!!values?.role ? (
        <ECFormGroup
          id="roles-admin"
          legend={t('users.role.canCreateUser.title')}
          help={t('users.role.canCreateUser.subtitle')}
          margin={subSectionsMargin}>
          <ECSelect
            id={`role-${values.role}-isAdmin`}
            name={`role-${values.role}-isAdmin`}
            options={[
              { value: 'true', title: t(`users.role.canCreateUser.yes`) },
              { value: 'false', title: t(`users.role.canCreateUser.no`) },
            ]}
            selectedValue={values.role === 'ADMIN' || values.canManageUsers ? 'true' : 'false'}
            isDisabled={values.role === 'ADMIN'}
            onSelect={onSelectCanCreateUser}
            width="l"
          />
        </ECFormGroup>
      ) : undefined}
    </>
  )
}
