/**
 * See https://ec.europa.eu/component-library/ec/components/banners/page-banner/usage/
 *
 * todo: support modes image, image-shade, primary, default, align-left
 */

import React from 'react'

import { ReactComponent as CornerArrow } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/corner-arrow.svg'
import classnames from 'classnames'

import { ECContainer } from '../ECContainer'
import { ECLink } from '../navigation/ECLink'
import { ECBackgroundProps, useBackgroundClass } from '../utilities/ECColor'
import { ECSpacableProps, useMarginClass, usePaddingClass } from '../utilities/ECSpacable'
import { ECStack } from '../utilities/ECStack'
import { ECText } from '../utilities/ECText'
import { ECTitle } from '../utilities/ECTitle'

export interface ECPageBannerProps {
  title: string
  baseline?: string
  buttons?: Array<{ title: string; path?: string; onClick?: () => void }>
}

export const ECPageBanner: React.FC<ECPageBannerProps & ECBackgroundProps & ECSpacableProps> = ({
  background = 'grey',
  title,
  baseline,
  buttons,
  margin,
  padding,
}) => {
  const bgClass = classnames('ecl-page-banner__image', useBackgroundClass(background))

  const baselineTag = baseline ? <ECText className="ecl-page-banner__baseline">{baseline}</ECText> : undefined

  const marginClass = useMarginClass(margin)
  const paddingClass = usePaddingClass(padding)

  const buttonMargin = useMarginClass({ horizontal: 'm' })

  return (
    <section
      className={classnames(
        'ecl-page-banner ecl-page-banner--image ecl-page-banner--centered',
        marginClass,
        paddingClass,
      )}>
      <div className={bgClass}></div>
      <div className="ecl-container ecl-page-banner__container">
        <div className="ecl-page-banner__content">
          <ECTitle level={1} className="ecl-page-banner__title">
            {title}
          </ECTitle>
          {baselineTag}
          {buttons && (
            <ECStack horizontalAlignment="center">
              <ECContainer>
                {buttons.map((button) => (
                  <ECLink
                    key={button.title}
                    path={button.path}
                    onClick={button.onClick}
                    className={classnames(
                      'ecl-page-banner__link-cta ecl-link ecl-link--cta ecl-link--icon ecl-link--icon-after',
                      buttonMargin,
                    )}>
                    <span className="ecl-link__label">{button.title}</span>&nbsp;
                    <CornerArrow
                      focusable="false"
                      aria-hidden="true"
                      className="ecl-link__icon ecl-icon ecl-icon--xs ecl-icon--rotate-90"
                    />
                  </ECLink>
                ))}
              </ECContainer>
            </ECStack>
          )}
        </div>
      </div>
    </section>
  )
}
