import React from 'react'

import classnames from 'classnames'
import { Message } from 'react-hook-form'

import { ECContentBaseProps } from '../core/ECContentBase'

export interface ECFormProps {
  id: string
  error?: { message?: Message }
  isDisabled?: boolean
  /** add optional text to label if true, default = false */
  optionalText?: string
  /** add required text to label if true, default = false */
  isRequired?: boolean
}

export const ECFormIdPrefixes = {
  optional: 'form-legend-optional-',
  required: 'form-legend-required',
  help: 'form-help-',
  error: 'form-error-',
  datepicker: 'form-datepicker-',
  textInput: 'form-text-',
  radioInput: 'form-radio-',
  radioHelp: 'form-radio-help-',
  select: 'form-select-',
  checkBox: 'form-checkbox-',
  checkBoxHelp: 'form-checkbox-help-',
}

export interface ECFormSubmitProps {
  onSubmit?: React.FormEventHandler<Record<string, string>>
}

export const ECForm: React.FC<ECFormSubmitProps & ECContentBaseProps> = ({
  className,

  children,
  onSubmit,
}) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export const ECFormHelpMessage: React.FC<ECFormProps & ECContentBaseProps> = ({
  id,
  isDisabled: disable,
  children,
}) => {
  const helpClass = classnames('ecl-help-block', { 'ecl-help-block--disabled': disable })
  return (
    <div id={id && `${ECFormIdPrefixes.help}${id}`} className={helpClass}>
      {children}
    </div>
  )
}

export const ECFormErrorMessage: React.FC<ECFormProps> = ({ id, error }) => {
  if (error) {
    return (
      <div id={id && `${ECFormIdPrefixes.error}${id}`} className="ecl-feedback-message">
        {error.message}
      </div>
    )
  } else return null
}
