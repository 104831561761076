/**
 * See https://ec.europa.eu/component-library/ec/core-template/footer/code/
 */

import React from 'react'

import i18next from 'i18next'
import { Link } from 'react-router-dom'

import { useCommonTranslate } from '../../../i18n'
import { trackOutlinkClick } from '../../../services/analytics-service'
import { ECLinkProps } from '../navigation/ECLink'
import { ECSpacableProps } from '../utilities/ECSpacable'

export interface ECFooterProps {
  /** path for site name link */
  siteNamePath?: string
  /** links for "more" section */
  moreLinks?: Array<ECLinkProps>
  /** links for "service" section */
  serviceLinks?: Array<ECLinkProps>
  /** links for "legal" section */
  legalLinks?: Array<ECLinkProps>
}

export const ECFooter: React.FC<ECFooterProps & ECSpacableProps> = ({
  siteNamePath = '/',
  moreLinks = [],
  serviceLinks = [],
  legalLinks = [],
  margin,
  padding,
}) => {
  const tc = useCommonTranslate()

  const logoImages: Record<string, string> = { en: '/images/logo-ec--en.10f5072b.svg' }

  return (
    <>
      <footer className="ecl-footer-harmonised ecl-footer-harmonised--group1" style={{ marginTop: 100 }}>
        <div className="ecl-container ecl-footer-harmonised__container">
          <section className="ecl-footer-harmonised__section ecl-footer-harmonised__section1">
            <Link
              to="/"
              className="ecl-footer-harmonised__title ecl-link ecl-link--standalone"
              aria-label={tc('footer.website.name')}>
              Recreational Fisheries (RecFishing)
            </Link>
            <div className="ecl-footer-harmonised__description">{tc('footer.website.administratedBy')}</div>
          </section>
          <div className="ecl-footer-harmonised__section2">
            <section className="ecl-footer-harmonised__section">
              <div className="ecl-footer-harmonised__title ecl-footer-harmonised__title--separator">
                {tc('footer.website.contactUs')}
              </div>
              <ul className="ecl-footer-harmonised__list">
                <li className="ecl-footer-harmonised__list-item">
                  <a
                    href="https://commission.europa.eu/about-european-commission/departments-and-executive-agencies/maritime-affairs-and-fisheries_en"
                    className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                    aria-label={tc('footer.website.contactDGMaritime')}
                    target="_blank"
                    rel="noreferrer">
                    {tc('footer.website.contactDGMaritime')}
                  </a>
                </li>
                <li className="ecl-footer-harmonised__list-item">
                  <a
                    href="mailto:support@mail.recreational-fishing.ec.europa.eu"
                    target="_blank"
                    className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                    aria-label={tc('footer.more.support')}
                    rel="noreferrer">
                    {tc('footer.more.support')}
                  </a>
                </li>
              </ul>
            </section>
          </div>
          <div className="ecl-footer-harmonised__section3">
            <section className="ecl-footer-harmonised__section">
              <div className="ecl-footer-harmonised__title ecl-footer-harmonised__title--separator">
                {tc('footer.website.aboutUs')}
              </div>
              <ul className="ecl-footer-harmonised__list">
                {moreLinks.map((props) => (
                  <li key={`footer-nav-more-${props.title}`} className="ecl-footer-harmonised__list-item">
                    <Link
                      to={props.href ?? props.path ?? '/'}
                      target={props.targetBlank ? '_blank' : '_self'}
                      className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                      aria-label="Link to Related link 1"
                      rel="noreferrer">
                      {props.title}
                    </Link>
                  </li>
                ))}
                {serviceLinks.map((props) => (
                  <li key={`footer-nav-more-${props.title}`} className="ecl-footer-harmonised__list-item">
                    <Link
                      to={props.href ?? props.path ?? '/'}
                      target={props.targetBlank ? '_blank' : '_self'}
                      className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                      aria-label="Link to Related link 1"
                      rel="noreferrer">
                      {props.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
            {/*moreLinks.length + legalLinks.length > 0 && (
              <section className="ecl-footer-harmonised__section">
                <div className="ecl-footer-harmonised__title ecl-footer-harmonised__title--separator">
                  {tc('footer.website.relatedLinks')}
                </div>
                <ul className="ecl-footer-harmonised__list">
                  
                </ul>
              </section>
            )*/}
          </div>
          <section className="ecl-footer-harmonised__section ecl-footer-harmonised__section6"></section>
          <section className="ecl-footer-harmonised__section ecl-footer-harmonised__section7">
            <img
              src={logoImages[i18next.language] || logoImages.en}
              alt={tc('footer.commission.title')}
              className={'ecl-site-header-core__logo-image'}
            />
            <a
              href="https://ec.europa.eu/info/index_en"
              onClick={() => {
                trackOutlinkClick('https://ec.europa.eu/info/index_en')
              }}
              target="_blank"
              className="ecl-footer-harmonised__title ecl-link ecl-link--standalone"
              rel="noreferrer">
              {tc('footer.commission.title')}
            </a>
          </section>
          <section className="ecl-footer-harmonised__section ecl-footer-harmonised__section8">
            <ul className="ecl-footer-harmonised__list">
              <li className="ecl-footer-harmonised__list-item">
                <a
                  target="_blank"
                  href="https://ec.europa.eu/info/about-european-commission/contact_en"
                  onClick={() => {
                    trackOutlinkClick('https://ec.europa.eu/info/about-european-commission/contact_en')
                  }}
                  className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                  aria-label="Link to Contact the European Commission"
                  rel="noreferrer">
                  {tc('footer.commission.contact')}
                </a>
              </li>
              <li className="ecl-footer-harmonised__list-item">
                <a
                  href="https://europa.eu/european-union/contact/social-networks_en"
                  onClick={() => {
                    trackOutlinkClick('https://europa.eu/european-union/contact/social-networks_en')
                  }}
                  target="_blank"
                  className="ecl-footer-harmonised__link ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-after"
                  aria-label={tc('footer.commission.follow')}
                  rel="noreferrer">
                  <span className="ecl-link__label">{tc('footer.commission.follow')}</span>&nbsp;
                </a>
              </li>
              <li className="ecl-footer-harmonised__list-item">
                <a
                  href="https://ec.europa.eu/info/resources-partners_en"
                  onClick={() => {
                    trackOutlinkClick('https://ec.europa.eu/info/resources-partners_en')
                  }}
                  className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                  aria-label="Link to Resources for partners">
                  {tc('footer.commission.partnersResources')}
                </a>
              </li>
            </ul>
          </section>
          <section className="ecl-footer-harmonised__section ecl-footer-harmonised__section9">
            <ul className="ecl-footer-harmonised__list">
              <li className="ecl-footer-harmonised__list-item">
                <a
                  href="https://ec.europa.eu/info/language-policy_en"
                  onClick={() => {
                    trackOutlinkClick('https://ec.europa.eu/info/language-policy_en')
                  }}
                  target="_blank"
                  className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                  aria-label="Link to Language policy"
                  rel="noreferrer">
                  {tc('footer.commission.languagePolicy')}
                </a>
              </li>
              <li className="ecl-footer-harmonised__list-item">
                <a
                  href="https://ec.europa.eu/info/cookies_en"
                  onClick={() => {
                    trackOutlinkClick('https://ec.europa.eu/info/cookies_en')
                  }}
                  target="_blank"
                  className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                  aria-label="Link to Cookies"
                  rel="noreferrer">
                  {tc('footer.commission.cookies')}
                </a>
              </li>
              <li className="ecl-footer-harmonised__list-item">
                <a
                  href="https://ec.europa.eu/info/privacy-policy_en"
                  onClick={() => {
                    trackOutlinkClick('https://ec.europa.eu/info/privacy-policy_en')
                  }}
                  target="_blank"
                  className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                  aria-label="Link to Privacy policy"
                  rel="noreferrer">
                  {tc('footer.commission.privacyPolicy')}
                </a>
              </li>
              <li className="ecl-footer-harmonised__list-item">
                <a
                  href="https://ec.europa.eu/info/legal-notice_en"
                  onClick={() => {
                    trackOutlinkClick('https://ec.europa.eu/info/legal-notice_en')
                  }}
                  target="_blank"
                  className="ecl-footer-harmonised__link ecl-link ecl-link--standalone"
                  aria-label="Link to Legal notice"
                  rel="noreferrer">
                  {tc('footer.commission.legalNotice')}
                </a>
              </li>
            </ul>
          </section>
        </div>
      </footer>
    </>
  )
}
