import React, { ReactNode } from 'react'

import { ReactComponent as CheckIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/check.svg'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ECContentBaseProps } from '../core/ECContentBase'
import { ECSpacableProps, useMarginClass, usePaddingClass } from '../utilities/ECSpacable'
import { ECFormProps, ECFormIdPrefixes } from './ECForm'

export interface ECCheckBoxProps extends ECFormProps {
  name: string
  contentKey?: string
  helpKey?: string
  value?: string
  isDefaultChecked?: boolean
  isChecked?: boolean
  isInvalid?: boolean
  isDiasbled?: boolean
  onClick: () => void
  children?: ReactNode
}

export const ECCheckBox = React.forwardRef<HTMLInputElement, ECCheckBoxProps & ECContentBaseProps & ECSpacableProps>(
  (
    {
      id,
      name,
      contentKey,
      helpKey,
      value,
      isDefaultChecked,
      isChecked,
      isInvalid = false,
      isDiasbled,
      onClick,
      margin,
      padding,
      className,
      children,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const localOnClick = React.useCallback(() => onClick(), [onClick])

    const checkboxClass = classnames(
      'ecl-checkbox',
      { 'ecl-checkbox--invalid': isInvalid, 'ecl-checkbox--disabled': isDiasbled },
      useMarginClass(margin),
      usePaddingClass(padding),
      className,
    )
    const checkboxBoxClass = classnames('ecl-checkbox__box', {
      'ecl-checkbox__box--invalid': isInvalid,
      'ecl-checkbox__box--disabled': isDiasbled,
    })

    return (
      <div className={checkboxClass}>
        <input
          type="checkbox"
          className="ecl-checkbox__input"
          id={id}
          name={name}
          value={value}
          defaultChecked={isDefaultChecked}
          checked={isChecked}
          ref={ref}
        />
        <label className="ecl-checkbox__label" htmlFor={id} onClick={localOnClick}>
          <span className={checkboxBoxClass} onClick={localOnClick}>
            <CheckIcon focusable="false" className="ecl-checkbox__icon ecl-icon ecl-icon--s" />
          </span>
          {(contentKey && t(contentKey)) || children}
        </label>
        {helpKey && (
          <div id={id} className="ecl-checkbox__help">
            {t(helpKey)}
          </div>
        )}
      </div>
    )
  },
)
