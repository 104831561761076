import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next, useTranslation } from 'react-i18next'

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    supportedLngs: [
      'bg',
      'cs',
      'da',
      'de',
      'el',
      'en',
      'es',
      'et',
      'fi',
      'fr',
      'ga',
      'hr',
      'hu',
      'it',
      'lt',
      'lv',
      'mt',
      'nl',
      'pl',
      'pt',
      'ro',
      'sk',
      'sl',
      'sv',
    ],
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n

export const useCommonTranslate = () => {
  const { t } = useTranslation('common')
  return t
}
export const useBackOfficeTranslate = () => {
  const { t } = useTranslation('back-office')
  return t
}
export const useFishCatchesTranslate = () => {
  const { t } = useTranslation('fish-catches')
  return t
}
export const useAnglersTranslate = () => {
  const { t } = useTranslation('anglers')
  return t
}
