import React from 'react'

import classnames from 'classnames'

import { ECButton } from '../../../components/EC/ECButton'
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHeader,
  ECTableHeaderCell,
  ECTableRow,
} from '../../../components/EC/ECTable'
import { useMarginClass } from '../../../components/EC/utilities/ECSpacable'
import { useBackOfficeTranslate } from '../../../i18n'

export interface IndicatorsTableProps {
  data: Exports.AggregatedDataResponse
  headerKeys: string[]
  mode: 'aggregatedData' | 'export'
  onHideColumn?: (columnKey: string) => void
}

export const IndicatorsTable: React.FC<IndicatorsTableProps> = ({ data, mode, headerKeys, onHideColumn }) => {
  const t = useBackOfficeTranslate()

  const marginClass = useMarginClass({ top: 'l' })

  return (
    <div style={{ overflowX: 'scroll' }}>
      <ECTable className={classnames(marginClass)}>
        <ECTableHeader>
          <ECTableRow>
            {headerKeys.map((key: string) => (
              <ECTableHeaderCell key={key}>
                {mode !== 'export' && onHideColumn ? (
                  <>
                    <div className="hideColumnButton">
                      <ECButton
                        type="text"
                        onClick={() => onHideColumn(key)}
                        text={t('aggregatedData.header.hideColumn')}
                      />
                    </div>
                    <br />
                  </>
                ) : undefined}
                {mode === 'export' ? key : t(`aggregatedData.header.${key}`)}
              </ECTableHeaderCell>
            ))}
          </ECTableRow>
        </ECTableHeader>
        <ECTableBody>
          {data.results.map((indicator) => {
            return (
              <ECTableRow key={`${indicator.period}-${indicator.speciesFaoCode}-${indicator.zone}`}>
                {headerKeys.map((elt, index) => (
                  <ECTableCell key={elt} headerTitle={mode === 'export' ? headerKeys[index] : t(headerKeys[index])}>
                    {(indicator as any)[elt]}
                  </ECTableCell>
                ))}
              </ECTableRow>
            )
          })}
        </ECTableBody>
      </ECTable>
    </div>
  )
}
