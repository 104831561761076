/* eslint-disable no-console */
export type TypeLogLevel = 'debug' | 'info' | 'warn' | 'error'

let logLevel: TypeLogLevel = 'debug'

const iflevel = (level: TypeLogLevel) => {
  if (logLevel === 'debug' || (level === 'warn' && logLevel !== 'error')) {
    return true
  }
  if (logLevel === level) {
    return true
  }
  return false
}

const log = (method: (p: any[]) => void, args: any[]) => {
  const newArgs = [new Date().toLocaleTimeString(), ' : ', ...args]
  try {
    if (!method) {
      method = console.log
    }
    method.apply(console, newArgs as any)
  } catch (err) {
    console.info('Could NOT log', err)
  }
}

export const Logger = {
  debug: (...args: any[]) => {
    if (iflevel('debug')) {
      log(console.log, args)
    }
  },
  info: (...args: any[]) => {
    if (iflevel('info')) {
      log(console.info, args)
    }
  },
  warn: (...args: any[]) => {
    if (iflevel('warn')) {
      log(console.warn, args)
    }
  },
  error: (...args: any[]) => {
    log(console.error, args)
  },
  setLogLevel: (level: TypeLogLevel) => (logLevel = level),
}
