import React from 'react'

import { useHistory } from 'react-router-dom'

import { useBackOfficeTranslate } from '../../../i18n'
import { ECButton } from '../ECButton'
import { ECNavItemProps } from '../navigation/ECInpageNav'

interface ECHarmonizedPageBannerProps {
  navItems?: ECNavItemProps[]
}

export const ECHarmonizedPageBanner = ({ navItems }: ECHarmonizedPageBannerProps) => {
  const t = useBackOfficeTranslate()

  const activeItem = React.useMemo(() => {
    return navItems?.find((item) => item.isActive)
  }, [navItems])

  const title = React.useMemo(() => {
    return activeItem?.title
  }, [activeItem])

  if (!title) {
    return null
  }

  return (
    <div>
      <div className="ecl-page-header-harmonised">
        <div className={'ecl-container'}>
          {activeItem?.hideNavWhenActive ? (
            <ECButton type="text" text={`< ${t('back')}`} style={{ paddingLeft: 0 }} href="/" />
          ) : undefined}
          <h1 className="ecl-page-header-harmonised__title" style={{ paddingTop: 16 }}>
            {title}
          </h1>
        </div>
      </div>
    </div>
  )
}
