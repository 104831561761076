/**
 * See https://ec.europa.eu/component-library/ec/utilities/stacks/code/
 */

import React from 'react'

import classnames from 'classnames'

import { ECContentBaseProps } from '../core/ECContentBase'
import { ECDimensionableProps, useDimensionClass } from './ECDimensionable'
import { ECSpacableProps, useMarginClass, usePaddingClass } from './ECSpacable'

export interface ECStackProps {
  /** if true ignore [inline]-flex class */
  isChildOnly?: boolean
  /** if true use inline-flex class */
  isInline?: boolean
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  wrap?: 'no-wrap' | 'wrap' | 'wrap-reverse'
  verticalAlignment?: 'start' | 'end' | 'center' | 'baseline' | 'fresh'
  horizontalAlignment?: 'start' | 'end' | 'center' | 'between' | 'around'
  packing?: 'start' | 'end' | 'center' | 'between' | 'around' | 'stretch'
  alignSelf?: 'auto' | 'start' | 'end' | 'center' | 'baseline' | 'stretch'
  /** positive number */
  grow?: number
  /** positive number */
  shrink?: number
  order?: 'first' | 'last' | 0
}

export const ECStack: React.FC<ECStackProps & ECSpacableProps & ECContentBaseProps & ECDimensionableProps> = ({
  isChildOnly = false,
  isInline = false,
  direction = 'row',
  wrap = 'no-wrap',
  verticalAlignment,
  horizontalAlignment,
  packing,
  alignSelf,
  grow,
  shrink,
  order,
  margin,
  padding,
  dimensions,
  className,
  children,
  style,
}) => {
  const stackClass = classnames(
    { 'ecl-u-d-flex': !isChildOnly && !isInline, 'ecl-u-d-inline-flex': !isChildOnly && isInline },
    `ecl-u-flex-${direction} ecl-u-flex-${wrap} `,
    {
      [`ecl-u-align-items-${verticalAlignment}`]: verticalAlignment,
      [`ecl-u-justify-content-${horizontalAlignment}`]: horizontalAlignment,
      [`ecl-u-align-content-${packing}`]: packing,
      [`ecl-u-align-self-${alignSelf}`]: alignSelf,
      [`ecl-u-flex-grow-${grow}`]: grow,
      [`ecl-u-flex-shrink-${shrink}`]: shrink,
      [`ecl-u-order-${order}`]: order,
    },
    useMarginClass(margin),
    usePaddingClass(padding),
    useDimensionClass(dimensions),
    className,
  )

  return (
    <div className={stackClass} style={style}>
      {children}
    </div>
  )
}
