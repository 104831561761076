import React from 'react'

import moment from 'moment'

import { ECFormGroup } from '../../../components/EC/forms/ECFormGroup'
import { ECColumn, ECRow } from '../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../components/EC/utilities/ECSpacable'
import { ECStack } from '../../../components/EC/utilities/ECStack'
import { Spinner } from '../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../i18n'
import { useScopesData } from '../../../utils/hooks/useScopesData'
import { ScopeGroup } from '../common/ScopeGroup'
import { MonthSelector } from '../components/MonthSelector'
import { Filters } from './FilterSection'

export interface FilterSectionV2Props {
  value: Filters
  onChange: (newValues: Filters) => void
}

export const FilterSectionV2: React.FC<FilterSectionV2Props> = ({ value, onChange }) => {
  const { scopes, fishSpecies, geographicalAreas } = useScopesData()

  const t = useBackOfficeTranslate()

  const rowMargin: ECSpacable = {}
  const sectionsMargin: ECSpacable = {}

  const firstLoaded = React.useRef(false)
  React.useEffect(() => {
    if (!firstLoaded.current) {
      if (!!scopes) {
        onChange({
          fishSpeciesScope: scopes.fishSpecies,
          geographicalAreasScope: scopes.geographicalAreas,
          ...value,
        })
        firstLoaded.current = true
      }
    }
  }, [onChange, scopes, value])

  const onChangeSpeciesScope = React.useCallback(
    (newScope: Scope) => {
      onChange({ ...value, fishSpeciesScope: newScope })
    },
    [onChange, value],
  )

  const onChangeGeographicalAreasScope = React.useCallback(
    (newScope: Scope) => {
      onChange({ ...value, geographicalAreasScope: newScope })
    },
    [onChange, value],
  )

  const onPeriodStartChanged = React.useCallback(
    (month: number, year: number) => {
      onChange({
        ...value,
        startPeriod: moment().day(15).month(month).year(year).startOf('month').valueOf(),
      })
    },
    [onChange, value],
  )

  const onPeriodEndChanged = React.useCallback(
    (month: number, year: number) => {
      onChange({
        ...value,
        endPeriod: moment().day(15).month(month).year(year).endOf('month').valueOf(),
      })
    },
    [onChange, value],
  )

  const borderStyle = {
    padding: 8,
    marginTop: 8,
    border: 'solid thin lightgrey',
  }

  if (!scopes) {
    return <Spinner />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      <ECRow>
        <ECColumn col={{ sm: 12, md: 6, lg: 4 }} padding={{ horizontal: 'xs' }}>
          <div style={{ ...borderStyle }}>
            <ECFormGroup id="periodStart" legend={t('aggregatedData.filters.periodStart.title')} margin={rowMargin}>
              <ECStack margin={{ bottom: 'm' }}>
                <MonthSelector
                  name="start"
                  onChange={onPeriodStartChanged}
                  month={(value.startPeriod ? new Date(value.startPeriod).getMonth() : undefined) ?? moment().month()}
                  year={(value.startPeriod ? new Date(value.startPeriod).getFullYear() : undefined) ?? moment().year()}
                />
              </ECStack>
            </ECFormGroup>
            <ECFormGroup id="periodEnd" legend={t('aggregatedData.filters.periodEnd.title')} margin={rowMargin}>
              <ECStack>
                <MonthSelector
                  name="end"
                  onChange={onPeriodEndChanged}
                  month={(value.endPeriod ? new Date(value.endPeriod).getMonth() : undefined) ?? moment().month()}
                  year={(value.endPeriod ? new Date(value.endPeriod).getFullYear() : undefined) ?? moment().year()}
                />
              </ECStack>
            </ECFormGroup>
          </div>
        </ECColumn>
        {fishSpecies.length > 0 ? (
          <ECColumn col={{ sm: 12, md: 6, lg: 4 }} padding={{ horizontal: 'xs' }}>
            <div style={{ ...borderStyle, flex: 1, flexDirection: 'column' }}>
              <ScopeGroup
                name="fishSpecies"
                margin={sectionsMargin}
                value={value.fishSpeciesScope ?? { isAll: false, selected: [] }}
                options={fishSpecies}
                onChange={onChangeSpeciesScope}
                isAllAvailable={scopes.fishSpecies.isAll}
                legend={t('aggregatedData.filters.fishSpecies.title')}
                labelSelectAll={t('users.createForm.scopes.fishSpecies.all.true')}
                labelSelectNotAll={t('users.createForm.scopes.fishSpecies.all.false')}
                placeholder={t('users.createForm.scopes.fishSpecies.select')}
              />
            </div>
          </ECColumn>
        ) : undefined}
        {geographicalAreas.length > 0 ? (
          <ECColumn col={{ sm: 12, md: 6, lg: 4 }} padding={{ horizontal: 'xs' }}>
            <div style={{ ...borderStyle }}>
              <ScopeGroup
                name="geographicalAreas"
                margin={sectionsMargin}
                value={value.geographicalAreasScope ?? { isAll: false, selected: [] }}
                options={geographicalAreas}
                onChange={onChangeGeographicalAreasScope}
                isAllAvailable={scopes.geographicalAreas.isAll}
                legend={t('aggregatedData.filters.geographicalAreas.title')}
                labelSelectAll={t('users.createForm.scopes.geographicalAreas.all.true')}
                labelSelectNotAll={t('users.createForm.scopes.geographicalAreas.all.false')}
                placeholder={t('users.createForm.scopes.geographicalAreas.select')}
              />
            </div>
          </ECColumn>
        ) : undefined}
      </ECRow>
    </ECColumn>
  )
}
