/**
 * See https://ec.europa.eu/component-library/ec/components/table/code/
 */

import React from 'react'

import classnames from 'classnames'

import { ECContentBaseProps } from './core/ECContentBase'
import { useMarginClass, usePaddingClass, ECSpacableProps } from './utilities/ECSpacable'

export interface ECTableProps {
  hasZebra?: boolean
}

export const ECTable: React.FC<ECTableProps & ECSpacableProps & ECContentBaseProps> = ({
  hasZebra = false,
  margin,
  padding,
  className,
  children,
}) => {
  const tableClass = classnames(
    'ecl-table',
    { 'ecl-table--zebra': hasZebra },
    useMarginClass(margin),
    usePaddingClass(padding),
    className,
  )

  return <table className={tableClass}>{children}</table>
}

export const ECTableRow: React.FC<ECContentBaseProps> = ({ className, children }) => {
  return <tr className={classnames('ecl-table__row', className)}>{children}</tr>
}

export const ECTableHeader: React.FC<ECContentBaseProps> = ({ className, children }) => {
  return <thead className={classnames('ecl-table__head', className)}>{children}</thead>
}

export const ECTableHeaderCell: React.FC<ECContentBaseProps> = ({ className, children }) => {
  return <th className={classnames('ecl-table__header', className)}>{children}</th>
}

export const ECTableBody: React.FC<ECContentBaseProps> = ({ className, children }) => {
  return <tbody className={classnames('ecl-table__body', className)}>{children}</tbody>
}

export const ECTableCell: React.FC<{ headerTitle?: string } & ECContentBaseProps> = ({
  headerTitle,
  className,
  children,
}) => {
  return (
    <td data-ecl-table-header={headerTitle} className={classnames('ecl-table__cell', className)}>
      {children}
    </td>
  )
}
