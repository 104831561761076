import React from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { ECButton } from '../../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { ECDimensionProps } from '../../../../components/EC/utilities/ECDimensionable'
import { ECColumn, ECGrid, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useAnglersTranslate } from '../../../../i18n'
import { AnglersLoginService } from '../../../../services/anglers/anglers-login-service'
import './LoginPage.scss'
import { useOAuthAuthorizeRedirectUrl } from '../../../../store/reducers/oauthReducer'

export const LoginPage: React.FC = () => {
  const t = useAnglersTranslate()

  const history = useHistory()
  const dispatch = useDispatch()
  const oAuthAuthroizedRedirectUrl = useOAuthAuthorizeRedirectUrl()
  const [showLoginError, setShowLoginError] = React.useState(false)

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().min(6).required(),
  })

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    validationSchema,
  })

  const onSubmit = handleSubmit(async (values) => {
    setShowLoginError(false)
    try {
      await AnglersLoginService.login(values)
      await AnglersLoginService.refreshAngler(dispatch)
      if (oAuthAuthroizedRedirectUrl) {
        //redirect to mainServer/authorize
        window.location.href = oAuthAuthroizedRedirectUrl
      } else {
        history.push('/profile')
      }
    } catch (err) {
      setShowLoginError(true)
    }
  })

  const textInputDimension: ECDimensionProps = { width: 100 }

  return (
    <ECForm onSubmit={onSubmit}>
      <ECGrid>
        <ECColumn col={{ lg: 6 }} offset={{ lg: 3 }}>
          <ECTitle level={1} margin={{ bottom: 'l', horizontal: 'auto' }}>
            {oAuthAuthroizedRedirectUrl ? t('login.oauthTitle') : t('login.title')}
          </ECTitle>
          <ECTextInput
            id="username"
            name="username"
            inputType="text"
            label={t('login.username.label')}
            isRequired
            ref={register}
            error={errors.username}
            width={textInputDimension}
            margin={{ vertical: 's', horizontal: 'auto' }}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          <ECTextInput
            inputType="password"
            id="password"
            name="password"
            label={t('register.password.label')}
            placeholder="***"
            isRequired
            ref={register}
            error={errors.password}
            width={textInputDimension}
            margin={{ vertical: 's', horizontal: 'auto' }}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
          />
          {showLoginError ? (
            <div className="ecl-u-mh-auto">
              <ECFormErrorMessage id="login-error" error={{ message: t('login.error') }} />
            </div>
          ) : undefined}
          <ECRow>
            <ECButton
              type="primary"
              isSubmit
              text={t('login.submit')}
              margin={{ top: 's', bottom: 's', horizontal: 'auto' }}
              padding={{ horizontal: '4xl' }}
            />
          </ECRow>
          <ECRow>
            <ECLink path="/forgotPassword" margin={{ bottom: 'xs', horizontal: 'auto' }} className="forgotLink">
              {t('login.links.forgot')}
            </ECLink>
          </ECRow>
          <ECRow>
            <ECLink path="/register" margin={{ horizontal: 'auto' }} className="registerLink">
              {t('login.links.register')}
            </ECLink>
          </ECRow>
        </ECColumn>
      </ECGrid>
    </ECForm>
  )
}
