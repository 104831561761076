/**
 * See https://ec.europa.eu/component-library/ec/components/navigation/pagination/code/
 */

import React, { useCallback } from 'react'

import { ReactComponent as CornerArrow } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/corner-arrow.svg'
import classnames from 'classnames'

import { useCommonTranslate } from '../../../i18n'
import { ECDimensionableProps, useDimensionClass } from '../utilities/ECDimensionable'
import { ECSpacableProps, useMarginClass, usePaddingClass } from '../utilities/ECSpacable'
import { ECLink } from './ECLink'

export interface PaginationLinkProps {
  page: number
  isCurrent: boolean
  onClick: (page: number) => void
}

export const PaginationLink: React.FC<PaginationLinkProps> = ({ page, isCurrent, onClick }) => {
  const onClickLocal = useCallback(() => onClick(page), [onClick, page])

  const liClass = classnames('ecl-pagination__item', { 'ecl-pagination__item--current': isCurrent })

  return (
    <li className={liClass}>
      <ECLink className="ecl-pagination__link ecl-link ecl-link--standalone" onClick={onClickLocal}>
        {(isCurrent && <span className="ecl-pagination__text ecl-pagination__text--summary">{`${page + 1}`}</span>) ||
          page + 1}
      </ECLink>
    </li>
  )
}

export interface ECPaginationProps {
  /**
   * zero-base
   */
  currentPage: number
  /**
   * zero-base
   */
  maxPage: number
  onClick: (value: number) => void
}

export const ECPagination: React.FC<ECPaginationProps & ECSpacableProps & ECDimensionableProps> = ({
  currentPage,
  maxPage,
  onClick,
  dimensions,
  margin,
  padding,
}) => {
  const t = useCommonTranslate()

  const pages = React.useMemo(() => {
    return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2].filter(
      (v) => v >= 0 && v <= maxPage,
    )
  }, [currentPage, maxPage])

  const onClickPrev = useCallback(() => {
    if (currentPage > 0) {
      onClick(currentPage - 1)
    }
  }, [currentPage, onClick])
  const onClickNext = useCallback(() => {
    if (currentPage < maxPage) {
      onClick(currentPage + 1)
    }
  }, [currentPage, maxPage, onClick])

  const navClass = classnames(
    'ecl-pagination',
    useDimensionClass(dimensions),
    useMarginClass(margin),
    usePaddingClass(padding),
  )

  return (
    <nav className={navClass} aria-label="Pagination">
      <ul className="ecl-pagination__list">
        {currentPage !== 0 && maxPage > 0 && (
          <li className="ecl-pagination__item ecl-pagination__item--previous">
            <ECLink
              onClick={onClickPrev}
              aria-label={t('pagination.previous')}
              className="ecl-pagination__link ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before">
              <CornerArrow
                focusable="false"
                aria-hidden="true"
                className="ecl-link__icon ecl-icon ecl-icon--xs ecl-icon--rotate-270"
              />
              <span className="ecl-link__label">{t('pagination.previous')}</span>
            </ECLink>
          </li>
        )}
        {pages.map((p: number) => {
          return <PaginationLink key={`${p}`} page={p} isCurrent={p === currentPage} onClick={onClick} />
        })}
        {currentPage !== maxPage && maxPage > 0 && (
          <li className="ecl-pagination__item ecl-pagination__item--next">
            <ECLink
              onClick={onClickNext}
              aria-label={t('pagination.next')}
              className="ecl-pagination__link ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-after">
              <span className="ecl-link__label">{t('pagination.next')}</span>
              <CornerArrow
                focusable="false"
                aria-hidden="true"
                className="ecl-link__icon ecl-icon ecl-icon--xs ecl-icon--rotate-90"
              />
            </ECLink>
          </li>
        )}
      </ul>
    </nav>
  )
}
