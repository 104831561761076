/**
 * See https://ec.europa.eu/component-library/ec/components/forms/datepicker/code/
 */

import React, { createRef, useEffect } from 'react'

import { ReactComponent as CalendarIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/general/calendar.svg'
import classnames from 'classnames'
import Pikaday from 'pikaday'

import { ECSpacableProps, useMarginClass, usePaddingClass } from '../utilities/ECSpacable'
import { ECFormIdPrefixes } from './ECForm'
import { ECFormGroupProps } from './ECFormGroup'

export interface ECDatePickerProps extends ECFormGroupProps {
  name: string
  width?: 's' | 'm' | 'l'
  format?: string
  value?: Date
  placeholder?: string
  onSelect: (value: Date) => void
}

export const ECDatePicker: React.FC<ECDatePickerProps & ECSpacableProps> = ({
  id,
  name,
  legend,
  help,
  width,
  value,
  format = 'LLL',
  placeholder = 'DD/MM/YYYY',
  onSelect,
  margin,
  padding,
}) => {
  const ref = createRef<HTMLInputElement>()

  useEffect(() => {
    const picker = new Pikaday({
      field: ref.current,
      format: format,
      defaultDate: value,
      onSelect: onSelect,
    })
    if (value && value.getTime() !== picker.getDate()?.getTime()) {
      picker.setDate(value)
    }
    return () => picker.destroy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const containerClass = classnames('ecl-form-group', useMarginClass(margin), usePaddingClass(padding))

  return (
    <div className={containerClass}>
      {legend && (
        <label className="ecl-form-label" htmlFor={`${ECFormIdPrefixes.datepicker}${id}`}>
          {legend}
        </label>
      )}
      {help && <div className="ecl-help-block">{help}</div>}
      <div className={`ecl-datepicker ecl-datepicker-theme ecl-select__container--${width}`}>
        <input
          type="text"
          ref={ref}
          id={`${ECFormIdPrefixes.datepicker}${id}`}
          name={`${ECFormIdPrefixes.datepicker}${name}`}
          placeholder={placeholder}
          className="ecl-datepicker__field ecl-text-input ecl-text-input--s"
          data-ecl-datepicker-toggle="true"
        />
        <CalendarIcon focusable="false" aria-hidden="true" className="ecl-datepicker__icon ecl-icon ecl-icon--s" />
      </div>
    </div>
  )
}
