/**
 * See https://ec.europa.eu/component-library/ec/utilities/spacing/usage/
 *
 * todo: support Responsive spacing
 */

import classnames from 'classnames'

export type ECSpacableSize = '4xl' | '3xl' | '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs' | '2xs' | 'none' | 'auto'
type ECSpacableMode = 'm' | 'p'

export interface ECSpacable {
  allAround?: ECSpacableSize
  vertical?: ECSpacableSize
  horizontal?: ECSpacableSize
  top?: ECSpacableSize
  bottom?: ECSpacableSize
  right?: ECSpacableSize
  left?: ECSpacableSize
}

export interface ECSpacableProps {
  margin?: ECSpacable
  padding?: ECSpacable
}

const useSpacableClass = (mode: ECSpacableMode, spacable?: ECSpacable) => {
  if (spacable) {
    return classnames({
      [`ecl-u-${mode}a-${spacable.allAround}`]: spacable.allAround,
      [`ecl-u-${mode}v-${spacable.vertical}`]: spacable.vertical,
      [`ecl-u-${mode}h-${spacable.horizontal}`]: spacable.horizontal,
      [`ecl-u-${mode}t-${spacable.top}`]: spacable.top,
      [`ecl-u-${mode}b-${spacable.bottom}`]: spacable.bottom,
      [`ecl-u-${mode}l-${spacable.left}`]: spacable.left,
      [`ecl-u-${mode}r-${spacable.right}`]: spacable.right,
    })
  }
}

export const useMarginClass = (margin?: ECSpacable) => useSpacableClass('m', margin)

export const usePaddingClass = (padding?: ECSpacable) => useSpacableClass('p', padding)
