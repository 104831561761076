const isActive = () => {
  return window._env_.REACT_APP_ACTIVATE_ANALYTICS === 'true'
}

const isTrackable = () => {
  try {
    return window.$wt?.analytics?.isTrackable() === true
  } catch (e) {
    console.error(e)
  }
  return false
}

const getWT = () => {
  try {
    if (!isActive()) {
      return undefined
    }
    if (!isTrackable()) {
      return undefined
    }
    return window.$wt
  } catch (e) {
    console.error(e)
  }
  return undefined
}

window.addEventListener('wtAnalyticsReady', function () {
  if (!isTrackable()) {
    return
  }
  if (!isActive()) {
    console.log('wtAnalyticsReady')
    return
  }
  try {
    getWT()?.trackPageView()
  } catch (e) {
    console.error(e)
  }
})

export const trackPageView = () => {
  if (!isTrackable()) {
    return
  }
  if (!isActive()) {
    console.log('trackPageView')
    return
  }
  try {
    getWT()?.trackPageView()
  } catch (e) {
    console.error(e)
  }
}

export const trackDownloadClick = (fileName: string) => {
  if (!isTrackable()) {
    return
  }
  if (!isActive()) {
    console.log('trackDownloadClick', fileName)
    return
  }
  try {
    window._paq?.push(['trackLink', fileName, 'download'])
  } catch (e) {
    console.error(e)
  }
}

export const trackOutlinkClick = (url: string) => {
  if (!isTrackable()) {
    return
  }
  if (!isActive()) {
    console.log('trackOutlinkClick', url)
    return
  }
  try {
    window._paq?.push(['trackLink', url, 'link'])
  } catch (e) {
    console.error(e)
  }
}

/**
 *
 * @param category feedbackForm
 * @param action Click
 * @param name feedback
 * @param value Yes
 * @returns
 */
export const trackCustomEvent = (category: string, action: string, name: string, value: string) => {
  if (!isTrackable()) {
    return
  }
  if (!isActive()) {
    console.log('trackEvent', category, action, name, value)
    return
  }
  try {
    window._paq?.push(['trackEvent', category, action, name, value])
  } catch (e) {
    console.error(e)
  }
}
