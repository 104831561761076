import React from 'react'

import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECFormGroup } from '../../../../components/EC/forms/ECFormGroup'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECColumn, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../../components/EC/utilities/ECSpacable'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../../i18n'

export const OAuthClientCreatePage = () => {
  const t = useBackOfficeTranslate()
  const history = useHistory()

  const [loading, setLoading] = React.useState(false)
  const [errorCreatingPartner, setErrorCreatingPartner] = React.useState(false)

  const validationSchema = React.useMemo(() => {
    return yup.object({
      name: yup.string().min(1).required(),
      logoUrl: yup
        .string()
        .url()
        .min(1)
        .matches(/^https/, t('oAuthClients.form.urlMustBeHttps'))
        .matches(/(.png|.jpg)$/, t('oAuthClients.form.logoUrlMustBePngOrJpg'))
        .required(),
      clientUrl: yup
        .string()
        .url()
        .min(1)
        .matches(/^https/, t('oAuthClients.form.urlMustBeHttps'))
        .required(),
    })
  }, [t])

  const { register, handleSubmit, errors } = useForm<OAuthClients.CreateOAuthClientRequest>({
    defaultValues: {
      name: '',
      logoUrl: '',
      clientUrl: '',
    },
    validationSchema,
    mode: 'onBlur',
  })

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true)
    setErrorCreatingPartner(false)
    try {
      const newPartner = (await backOfficeApi.createOAuthClient(values)).data
      history.push(`/oAuthClients/${newPartner.id}`)
    } catch (err) {
      setErrorCreatingPartner(true)
      setLoading(false)
    }
  })

  const sectionsMargin: ECSpacable = { top: 'l' }

  return (
    <ECColumn col={{ all: 12 }}>
      <ECTitle level={1} isPageTitle>
        {t('oAuthClients.create')}
      </ECTitle>
      <ECForm onSubmit={onSubmit}>
        <ECFormGroup id="general" margin={sectionsMargin}>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="name"
                name="name"
                label={t('oAuthClients.name')}
                ref={register}
                error={errors.name}
                inputWidth="l"
                isRequired
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="clientUrl"
                name="clientUrl"
                label={t('oAuthClients.clientUrl')}
                help={t('oAuthClients.form.clientUrl')}
                ref={register}
                error={errors.clientUrl}
                inputWidth="l"
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="logoUrl"
                name="logoUrl"
                label={t('oAuthClients.logo')}
                help={t('oAuthClients.form.logoUrl')}
                ref={register}
                error={errors.logoUrl}
                inputWidth="l"
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
        </ECFormGroup>

        {errorCreatingPartner ? (
          <div className="ecl-u-mh-auto">
            <ECFormErrorMessage id="login-error" error={{ message: t('oAuthClients.form.errorSaving') }} />
          </div>
        ) : undefined}
        <ECRow margin={sectionsMargin} className="align_center">
          <ECColumn col={{ all: 12, lg: 4 }}>
            {loading ? (
              <Spinner />
            ) : (
              <ECButton
                isSubmit
                type="primary"
                text={t('oAuthClients.form.submitButton')}
                dimensions={{ width: 100 }}
              />
            )}
          </ECColumn>
        </ECRow>
      </ECForm>
    </ECColumn>
  )
}
