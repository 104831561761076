import produce from 'immer'
import { useSelector } from 'react-redux'
import { Action } from 'redux'

import { AppReduxState } from '../reducers'
import {
  SetAnglerUserAction,
  SetAnglerUserActionType,
  SetBackOfficeUserAction,
  SetBackOfficeUserActionType,
} from './actions'

export interface UserReduxState {
  angler?: Angler
  backOfficeUser?: BackOfficeUser
}

export const userInitialState: UserReduxState = {}

// REDUCERS
export const UserReducer = (state = userInitialState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SetAnglerUserActionType:
        draft.angler = (action as SetAnglerUserAction).payload.angler
        break

      case SetBackOfficeUserActionType:
        draft.backOfficeUser = (action as SetBackOfficeUserAction).payload.backOfficeUser
        break
    }
  })

export const useAnglerSelector = () => useSelector((appState: AppReduxState) => appState.user.angler)
export const useBackOfficeUserSelector = () => useSelector((appState: AppReduxState) => appState.user.backOfficeUser)
export const useBackOfficeUserIsActiveSelector = () =>
  useSelector((appState: AppReduxState) => {
    if (!appState.user.backOfficeUser) {
      return false
    }
    if (appState.user.backOfficeUser.isActive) {
      return true
    }
    if (
      appState.user.backOfficeUser.isActiveUntil &&
      appState.user.backOfficeUser.isActiveUntil.getTime() > Date.now()
    ) {
      return true
    }
    return false
  })
