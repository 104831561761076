import { Action } from 'redux'

export const SetAnglerUserActionType = '@@actionTypes/SetAnglerUser'
export interface SetAnglerUserAction extends Action<typeof SetAnglerUserActionType> {
  payload: { angler?: Angler }
}
export const SetAnglerUserActionCreator = (angler?: Angler): SetAnglerUserAction => ({
  type: SetAnglerUserActionType,
  payload: { angler },
})

export const SetBackOfficeUserActionType = '@@actionTypes/SetBackOfficeUser'
export interface SetBackOfficeUserAction extends Action<typeof SetBackOfficeUserActionType> {
  payload: { backOfficeUser?: BackOfficeUser }
}
export const SetBackOfficeUserActionCreator = (backOfficeUser?: BackOfficeUser): SetBackOfficeUserAction => ({
  type: SetBackOfficeUserActionType,
  payload: { backOfficeUser },
})
