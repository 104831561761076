import React from 'react'

import classnames from 'classnames'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHeader,
  ECTableHeaderCell,
  ECTableRow,
} from '../../../../components/EC/ECTable'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { ECColumn } from '../../../../components/EC/utilities/ECGrid'
import { useMarginClass } from '../../../../components/EC/utilities/ECSpacable'
import { ECStack } from '../../../../components/EC/utilities/ECStack'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { useBackOfficeTranslate } from '../../../../i18n'

const HEADERS = ['oAuthClients.logo', 'oAuthClients.identification', 'oAuthClients.publicVisibility']

export const OAuthClientListPage: React.FC = () => {
  const t = useBackOfficeTranslate()

  const [rows, setRows] = React.useState<OAuthClients.OAuthClient[]>([])

  React.useEffect(() => {
    backOfficeApi
      .getOAuthClients()
      .then(setRows)
      .catch((err) => {})
  }, [])

  const tableClass = classnames(useMarginClass({ top: 'l' }))

  return (
    <ECColumn col={{ all: 12 }}>
      <ECStack horizontalAlignment="end">
        <ECButton type="primary" text={t('oAuthClients.create')} href="/oAuthClients/new"></ECButton>
      </ECStack>
      <ECTable className={tableClass}>
        <ECTableHeader>
          <ECTableRow>
            {HEADERS.map((value: string) => {
              return <ECTableHeaderCell key={value}>{t(value)}</ECTableHeaderCell>
            })}
          </ECTableRow>
        </ECTableHeader>
        <ECTableBody>
          {rows.map((item) => {
            return (
              <ECTableRow key={item.id}>
                <ECTableCell>
                  <img src={item.logoUrl} width={50} alt={item.name} />
                </ECTableCell>
                <ECTableCell>
                  <ECLink path={`/oAuthClients/${item.id}`}>{item.id}</ECLink>
                  <ECText>
                    <b>{item.name}</b>
                  </ECText>
                </ECTableCell>
                <ECTableCell>
                  {item.displayInAppsPage
                    ? t('oAuthClients.isDisplayedInAppsPage.true')
                    : t('oAuthClients.isDisplayedInAppsPage.false')}
                </ECTableCell>
              </ECTableRow>
            )
          })}
        </ECTableBody>
      </ECTable>
    </ECColumn>
  )
}
