/**
 * See see https://ec.europa.eu/component-library/ec/guidelines/colours/
 */

export type ECColorBlue =
  | 'blue'
  | 'blue-130'
  | 'blue-120'
  | 'blue-110'
  | 'blue-100'
  | 'blue-75'
  | 'blue-50'
  | 'blue-25'
  | 'blue-5'

export type ECColorYellow =
  | 'yellow'
  | 'yellow-120'
  | 'yellow-110'
  | 'yellow-100'
  | 'yellow-75'
  | 'yellow-50'
  | 'yellow-25'

export type ECColorGrey =
  | 'grey'
  | 'grey-100'
  | 'grey-75'
  | 'grey-50'
  | 'grey-25'
  | 'grey-20'
  | 'grey-15'
  | 'grey-10'
  | 'grey-5'

export type ECColorPrimary = 'white' | 'black' | ECColorBlue | ECColorYellow | ECColorGrey

export type ECColorNotif = 'blue-n' | 'orange' | 'green' | 'red'

export type ECColor = ECColorPrimary | ECColorNotif

export type ECBackgroundProps = {
  background?: ECColor
}

export const useBackgroundClass = (background?: ECColor) => {
  if (background) return `ecl-u-bg-${background}`
}
