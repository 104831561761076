import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import i18n from '../i18n'
import { PubSub } from '../utils/PubSub'

const API_HOST = window._env_.REACT_APP_API_HOST

class AnglersApi {
  private axiosInstance!: AxiosInstance

  constructor() {
    this.axiosInstance = Axios.create({
      baseURL: API_HOST,
      withCredentials: true,
    })

    this.axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // add language header
        config = {
          ...config,
          headers: {
            ...config.headers,
            'Accept-Language': i18n.language,
          },
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // user has been logged out.
          PubSub.publish('public-logged-out')
        }
        return Promise.reject(error)
      },
    )
  }

  async getData<T>(path: string, config?: AxiosRequestConfig) {
    return (await this.axiosInstance.get<T>(path, config)).data
  }

  login(body: { username: string; password: string }) {
    return this.axiosInstance.post<Angler>('/anglers/login', body)
  }

  async logOut() {
    return await this.axiosInstance.post('/anglers/logOut')
  }

  async getUserMe() {
    return await this.getData<Angler>('/anglers/me')
  }

  async putAngler(body: {
    countryOfResidence?: string
    zipCode?: string
    ownsBoat: boolean
    boatCountry?: string
    anglersGroups: string[]
  }) {
    return await this.axiosInstance.put<Angler>('/anglers/me', body)
  }

  async postAngler(body: {
    email: string
    password: string
    gender?: string
    birthYear?: number
    countryOfResidence?: string
    zipCode?: string
    ownsBoat: boolean
    boatCountry?: string
    anglersGroups: string[]
  }) {
    return await this.axiosInstance.post<Angler>('/anglers', body)
  }

  async getAnglersFishCatchesHistory(query: { offset?: number }) {
    const result = await this.axiosInstance.get<FishCatchHistoryItem[]>('/anglers/me/fishCatches', {
      params: { offset: query.offset },
    })
    return {
      count: Number.parseInt(result.headers['x-total-count']),
      rows: result.data,
    }
  }

  async requestResetPassword(username: string) {
    return this.axiosInstance.post('/anglers/forgotPassword', {
      email: username,
    })
  }

  async saveResetPassword(newPassword: string, token: string) {
    return this.axiosInstance.post('/anglers/saveResetPassword', {
      newPassword,
      token,
    })
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    return this.axiosInstance.post('/anglers/updatePassword', {
      oldPassword,
      newPassword,
    })
  }

  async listAnglersGroups() {
    return this.getData<AnglersGroup[]>('/anglersGroups')
  }

  async listCertifiedApps() {
    return this.getData<CertifiedApp[]>('/certifiedApps')
  }

  async listFishSpecies() {
    return this.getData<FishSpecies[]>('/fishSpecies')
  }
}

export const anglersApi = new AnglersApi()
