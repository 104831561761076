import React from 'react'

import { ECTagList } from '../ECTag'
import { ECStack } from '../utilities/ECStack'
import { ECSelect, ECSelectProps } from './ECSelect'

interface ECSelectMultiProps extends ECSelectProps {
  initialValues?: string[]
  onChange: (values: string[]) => void
}

export const ECSelectMulti: React.FC<ECSelectMultiProps> = ({
  initialValues = [],
  options = [],
  onChange,
  ...props
}) => {
  const [selected, setSelected] = React.useState<string[]>(initialValues)

  const { selectableOptions, selectedOptions } = React.useMemo(() => {
    return {
      selectableOptions: options.filter((elt) => !selected.includes(elt.value)),
      selectedOptions: options.filter((elt) => selected.includes(elt.value)),
    }
  }, [options, selected])

  const onSelect = React.useCallback(
    (newValue: string) => {
      let newValues = [...selected]
      if (!newValues.includes(newValue)) {
        newValues = [...newValues, newValue]
      }
      setSelected(newValues)
      onChange(newValues)
    },
    [onChange, selected],
  )

  const onRemove = React.useCallback(
    (valueToRemove: string) => {
      let newValues = [...selected]
      if (newValues.includes(valueToRemove)) {
        newValues.splice(newValues.indexOf(valueToRemove), 1)
      }
      setSelected(newValues)
      onChange(newValues)
    },
    [onChange, selected],
  )

  return (
    <>
      <ECSelect {...props} onSelect={onSelect} width="l" options={selectableOptions} />
      <ECStack margin={{ top: 's' }}>
        <ECTagList id={'anglerGroups'} listSelection={selectedOptions} onRemoveBadge={onRemove} />
      </ECStack>
    </>
  )
}
