/**
 * See https://ec.europa.eu/component-library/ec/components/button/code/
 */

import React from 'react'

import { ReactComponent as CornerArrow } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/corner-arrow.svg'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'

import { ECDimensionableProps, useDimensionClass } from './utilities/ECDimensionable'
import { ECSpacableProps, useMarginClass, usePaddingClass } from './utilities/ECSpacable'

export interface ECButtonProps {
  type: 'primary' | 'secondary' | 'cta' | 'text' | 'search'
  text: string
  isSubmit?: boolean
  onClick?: () => void
  href?: string
  style?: React.CSSProperties
}

export const ECButton: React.FC<ECButtonProps & ECSpacableProps & ECDimensionableProps> = ({
  type,
  text,
  margin,
  padding,
  dimensions,
  isSubmit = false,
  href,
  onClick,
  style,
}) => {
  const history = useHistory()

  let typeClass: string
  switch (type) {
    case 'primary':
    case 'secondary':
    case 'search':
      typeClass = `ecl-button--${type}`
      break
    case 'cta':
      typeClass = `ecl-button--call`
      break
    case 'text':
      typeClass = `ecl-button--ghost`
      break
  }

  const internalOnClick = React.useMemo(() => {
    if (href) {
      return () => history.push(href)
    }
    return onClick
  }, [history, href, onClick])

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={internalOnClick}
      style={{ cursor: 'pointer', ...style }}
      className={classnames(
        'ecl-button',
        typeClass,
        useMarginClass(margin),
        usePaddingClass(padding),
        useDimensionClass(dimensions),
      )}>
      {(type === 'cta' && (
        <span className="ecl-button__container">
          <span className="ecl-button__label" data-ecl-label="true">
            {text}
          </span>
          <CornerArrow
            focusable="false"
            aria-hidden="true"
            data-ecl-icon="true"
            className="ecl-button__icon ecl-button__icon--after ecl-icon ecl-icon--xs ecl-icon--rotate-90"
          />
        </span>
      )) ||
        text}
    </button>
  )
}
