import React, { useMemo } from 'react'

import PQueue from 'p-queue'
import { useRecoilValue } from 'recoil'
import slug from 'slug'

import { ECWebtoolLoadedAtom } from './ec-webtool-atom'

// create a one by one queue to avoid rendering issues
const queue = new PQueue({ concurrency: 1, intervalCap: 1, interval: 10 })

const defaultStyle: React.CSSProperties = { width: '100%', height: 400 }

interface ECWebtoolElementProps {
  style?: React.CSSProperties
  loadData: () => Promise<any>
}

const ECWebtoolElementToMemo = ({ style = defaultStyle, loadData }: ECWebtoolElementProps) => {
  const myId = React.useMemo(() => {
    return slug(`ec-webtool-element-${Math.random().toString(36)}`)
  }, [])

  const isECWebtoolLoaded = useRecoilValue(ECWebtoolLoadedAtom)
  const [divIsDisplayed, setDivIsDisplayed] = React.useState(false)
  const divRef = React.useRef<HTMLDivElement | undefined>(undefined)
  const isLoading = React.useRef(false)

  React.useEffect(() => {
    if (isLoading.current) {
      return
    }
    const onWtReady = async () => {
      const data = await loadData()
      data.name = myId
      if (data.map) {
        data.map = { ...data.map, ...style }
      }
      queue.add(() => {
        window.$wt.render(myId, data)
        //isLoading.current = false
      })
    }
    if (isECWebtoolLoaded && divIsDisplayed) {
      isLoading.current = true
      onWtReady()
    }
  }, [isECWebtoolLoaded, divIsDisplayed, loadData, myId, style])

  const setRef = React.useCallback<React.RefCallback<HTMLDivElement>>((ref) => {
    divRef.current = ref ?? undefined
    setDivIsDisplayed(true)
  }, [])

  return useMemo(() => <div key={myId} id={myId} style={style} ref={setRef}></div>, [myId, style, setRef])
}

export const ECWebtoolElement = React.memo(ECWebtoolElementToMemo)
