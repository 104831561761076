/**
 * See https://ec.europa.eu/component-library/ec/utilities/typography/code/
 */
import React from 'react'

import classnames from 'classnames'

import { ECContentBaseProps } from '../core/ECContentBase'
import { ECDimensionableProps, useDimensionClass } from './ECDimensionable'
import { ECSpacableProps, useMarginClass, usePaddingClass } from './ECSpacable'
import { ECTextProps, useTextClasses } from './ECText'

export interface ECTitleProps {
  level?: 1 | 2 | 3 | 4 | 5
  isPageTitle?: boolean
}

export const ECTitle: React.FC<
  ECTitleProps & ECTextProps & ECDimensionableProps & ECSpacableProps & ECContentBaseProps
> = ({
  level = 1,
  isPageTitle: pageTitle = false,
  color,
  styles,
  dimensions,
  margin,
  padding,
  className,
  children,
}) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements

  const titleClass = classnames(
    `ecl-u-type-heading-${level}`,
    {
      [`${useMarginClass({ top: 'none' })}`]: pageTitle,
    },
    useTextClasses(color, styles),
    useDimensionClass(dimensions),
    useMarginClass(margin),
    usePaddingClass(padding),
    className,
  )

  return <Tag className={titleClass}>{children}</Tag>
}
