import moment from 'moment'
import { createStore, CombinedState } from 'redux'
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer, { AppReduxState } from './reducers/reducers'

const replacer = (key: string, value: any) => (value instanceof Date ? value.toISOString() : value)

const reviver = (key: string, value: any) =>
  typeof value === 'string' && moment(value, moment.ISO_8601, true).isValid()
    ? moment(value, moment.ISO_8601, true).toDate()
    : value

export const encode = (toDeshydrate: any) => JSON.stringify(toDeshydrate, replacer)

export const decode = (toRehydrate: any) => JSON.parse(toRehydrate, reviver)

const persistConfig: PersistConfig<CombinedState<AppReduxState>, any, any, any> = {
  key: `root_${window._env_.REACT_APP_RCSB_TYPE}_2`,
  storage,
  blacklist: ['oAuth'],
  //  transforms: [createTransform(encode, decode)],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  let store = createStore(persistedReducer)
  let persistor = persistStore(store)

  if (module.hot) {
    module.hot.accept('./reducers/reducers', () => {
      // This fetch the new state of the above reducers.
      const nextRootReducer = require('./reducers/reducers').default
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer))
    })
  }
  return { store, persistor }
}
