import React from 'react'

import { useLocation } from 'react-router-dom'

import { trackPageView } from '../services/analytics-service'

export const RouterPageChangeTracker: React.FC = () => {
  const location = useLocation()

  React.useEffect(() => {
    trackPageView()
  }, [location.pathname])

  return null
}
