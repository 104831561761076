import React from 'react'

import moment from 'moment'
import { useDispatch } from 'react-redux'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { DownloadLink } from '../../../../components/DownloadLink'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECFormGroup } from '../../../../components/EC/forms/ECFormGroup'
import { ECColumn } from '../../../../components/EC/utilities/ECGrid'
import { ECStack } from '../../../../components/EC/utilities/ECStack'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../../i18n'
import { SetFiltersActionCreator, useLatestSavedFilters } from '../../../../store/reducers/filtersReducer'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'
import { Filters, FilterSection } from '../../indicators/FilterSection'
import { IndicatorsTable } from '../../indicators/IndicatorsTable'

export const DataExports = () => {
  const t = useBackOfficeTranslate()
  const currentUser = useBackOfficeUserSelector()
  const dispatch = useDispatch()

  const latestUsedFilters = useLatestSavedFilters()
  const [filters, setFilters] = React.useState<Filters>(latestUsedFilters || {})
  const [example, setExample] = React.useState<Exports.AggregatedDataResponse>()

  const refreshExample = React.useCallback(async () => {
    const results = await backOfficeApi.getExportExample({
      startDate: new Date(filters.startPeriod ?? moment().startOf('month').valueOf()),
      endDate: new Date(filters.endPeriod ?? moment().endOf('month').valueOf()),
      speciesIds: filters.fishSpeciesScope?.isAll ? [] : filters.fishSpeciesScope?.selected ?? [],
      geographicalAreasIds: filters.geographicalAreasScope?.isAll ? [] : filters.geographicalAreasScope?.selected ?? [],
    })
    setExample(results)
  }, [filters.endPeriod, filters.fishSpeciesScope, filters.geographicalAreasScope, filters.startPeriod])

  const getExportFileUrl = React.useCallback(
    (format: 'csv' | 'xlsx') => {
      return backOfficeApi.getExportFileUrl({
        startDate: new Date(filters.startPeriod ?? moment().startOf('month').valueOf()),
        endDate: new Date(filters.endPeriod ?? moment().endOf('month').valueOf()),
        speciesIds: filters.fishSpeciesScope?.isAll ? [] : filters.fishSpeciesScope?.selected ?? [],
        geographicalAreasIds: filters.geographicalAreasScope?.isAll
          ? []
          : filters.geographicalAreasScope?.selected ?? [],
        format,
      })
    },
    [filters.endPeriod, filters.fishSpeciesScope, filters.geographicalAreasScope, filters.startPeriod],
  )

  const downloadFilePathCSV = React.useMemo(() => {
    return getExportFileUrl('csv')
  }, [getExportFileUrl])
  const downloadFilePathXLSX = React.useMemo(() => {
    return getExportFileUrl('xlsx')
  }, [getExportFileUrl])

  React.useEffect(() => {
    refreshExample()
  }, [refreshExample])

  const onChangeFilters = React.useCallback(
    (newFilters: Filters) => {
      dispatch(SetFiltersActionCreator(newFilters))
      setFilters(newFilters)
    },
    [dispatch],
  )

  const headerKeys = React.useMemo(() => {
    let keys = [
      'period',
      'zone',
      'speciesFaoCode',
      'numberOfCatchesKept',
      'numberOfCatchesReleased',
      'averageSizeKept',
      'averageWeightKept',
      'numberOfAnglers',
    ]
    if (currentUser?.role?.type !== 'ADMIN') {
      keys = keys.concat(['minSizeKept', 'maxSizeKept', 'minWeightKept', 'maxWeightKept'])
    }
    let keys_tools: string[] = []
    let keys_modes: string[] = []
    let keys_methods: string[] = []
    if (example?.results && example.results.length > 0) {
      Object.keys(example.results[0]).forEach((aKey) => {
        if (aKey.startsWith('percentByClient_')) {
          keys_tools.push(aKey)
        }
        if (aKey.startsWith('percentByFishingMode_')) {
          keys_modes.push(aKey)
        }
        if (aKey.startsWith('percentByFishingType_H')) {
          keys_methods.push(aKey)
        }
      })
    }
    keys = keys.concat(keys_modes, keys_methods, keys_tools)
    return keys
  }, [currentUser, example])

  return (
    <ECColumn col={{ all: 12 }}>
      <FilterSection value={filters} onChange={onChangeFilters} />
      {(example?.totalCount ?? 0) > 0 ? (
        <>
          <ECStack horizontalAlignment="center" margin={{ top: 'xl' }}>
            <DownloadLink filePath={downloadFilePathCSV}>
              <ECButton type="primary" text={t('aggregatedData.export.button.csv')} />
            </DownloadLink>
          </ECStack>
          <ECStack horizontalAlignment="center" margin={{ top: 'xl' }}>
            <DownloadLink filePath={downloadFilePathXLSX}>
              <ECButton type="primary" text={t('aggregatedData.export.button.xlsx')} />
            </DownloadLink>
          </ECStack>
          <ECFormGroup
            id="format"
            legend={t('aggregatedData.export.example', { count: example?.totalCount })}
            margin={{ top: 'xl' }}
          />
          {example ? <IndicatorsTable data={example} headerKeys={headerKeys} mode="export" /> : <Spinner />}
        </>
      ) : (
        <ECText type="lead">{t('aggregatedData.export.noResult')}</ECText>
      )}
    </ECColumn>
  )
}
