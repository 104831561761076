import React from 'react'

import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECFormGroup } from '../../../../components/EC/forms/ECFormGroup'
import { ECRadioInput } from '../../../../components/EC/forms/ECRadio'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECColumn, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../../components/EC/utilities/ECSpacable'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../../i18n'

export const OAuthClientEditPage = () => {
  const t = useBackOfficeTranslate()
  const history = useHistory()

  const [loading, setLoading] = React.useState(false)
  const [errorUpdatingOAuthClient, setErrorUpdatingOAuthClient] = React.useState(false)
  const [oAuthClient, setOAuthClient] = React.useState<OAuthClients.OAuthClient>()

  const params = useParams<{ id: string }>()
  const { id } = params

  const validationSchema = yup.object({
    name: yup.string().min(1).required(),
    logoUrl: yup
      .string()
      .url()
      .min(1)
      .matches(/^https/, t('oAuthClients.form.urlMustBeHttps'))
      .matches(/(.png|.jpg)$/, t('oAuthClients.form.logoUrlMustBePngOrJpg'))
      .required(),
    clientUrl: yup
      .string()
      .url()
      .min(1)
      .matches(/^https/, t('oAuthClients.form.urlMustBeHttps'))
      .required(),
    redirectUrisString: yup.string(),
    displayInAppsPage: yup.boolean().required(),
  })
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm<
    OAuthClients.UpdateOAuthClientRequest & { redirectUrisString: string; clientSecret?: string }
  >({
    defaultValues: {
      name: '',
      logoUrl: '',
      clientUrl: '',
      redirectUris: [],
      redirectUrisString: '',
      clientSecret: '',
      displayInAppsPage: false,
    },
    validationSchema,
    mode: 'onBlur',
  })

  const displayInAppsPageValue = watch('displayInAppsPage')
  React.useEffect(() => {
    register({ name: 'displayInAppsPage' })
  }, [register])

  React.useEffect(() => {
    if (id) {
      backOfficeApi
        .getOAuthClient(id)
        .then((client) => {
          setOAuthClient(client)
          reset({ ...client, redirectUrisString: client.redirectUris.join(', ') })
        })
        .catch(() => {})
    }
  }, [id, reset])

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true)
    setErrorUpdatingOAuthClient(false)
    try {
      if (oAuthClient?.id) {
        const updated = (
          await backOfficeApi.updateOAuthClient(oAuthClient.id, {
            ...values,
            redirectUris: (values.redirectUrisString || '').split(',').map((elt) => elt.trim()),
          })
        ).data
        history.push(`/oAuthClients/${updated.id}`)
      }
    } catch (err) {
      setErrorUpdatingOAuthClient(true)
      setLoading(false)
    }
  })

  const sectionsMargin: ECSpacable = { top: 'l' }

  if (loading || !oAuthClient) {
    return <Spinner />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      <ECTitle level={1} isPageTitle>
        {t('oAuthClients.edit')}
      </ECTitle>

      <ECForm onSubmit={onSubmit}>
        <ECFormGroup id="general" margin={sectionsMargin}>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="name"
                name="name"
                label={t('oAuthClients.name')}
                ref={register}
                error={errors.name}
                inputWidth="l"
                isRequired
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="clientUrl"
                name="clientUrl"
                label={t('oAuthClients.clientUrl')}
                help={t('oAuthClients.form.clientUrl')}
                ref={register}
                error={errors.clientUrl}
                inputWidth="l"
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="logoUrl"
                name="logoUrl"
                label={t('oAuthClients.logo')}
                help={t('oAuthClients.form.logoUrl')}
                ref={register}
                error={errors.logoUrl}
                inputWidth="l"
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
        </ECFormGroup>
        <ECFormGroup
          id="public-visibility"
          legend={t('oAuthClients.publicVisibility')}
          help={t('oAuthClients.publicVisibilityHelp')}
          margin={sectionsMargin}>
          <ECRadioInput
            id="partner-not-public"
            value={'inactive'}
            label={t('oAuthClients.isDisplayedInAppsPage.false')}
            isChecked={!displayInAppsPageValue}
            onSelect={() => setValue('displayInAppsPage', false)}
            isDisabled={loading}
          />
          <ECRadioInput
            id="partner-public"
            value={'active'}
            label={t('oAuthClients.isDisplayedInAppsPage.true')}
            isChecked={!!displayInAppsPageValue}
            onSelect={() => setValue('displayInAppsPage', true)}
            isDisabled={loading}
          />
        </ECFormGroup>
        <ECFormGroup id="general" margin={sectionsMargin}>
          <br />
          <ECTitle level={2}>{t('oAuthClients.form.technical')}</ECTitle>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="redirectUrisString"
                name="redirectUrisString"
                label={t('oAuthClients.redirectUris')}
                help={t('oAuthClients.form.redirectUris')}
                ref={register}
                inputWidth="l"
                isDisabled={loading}
                inputProps={{ multiple: true }}
              />
            </ECColumn>
          </ECRow>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="clientId"
                name="id"
                label={t('oAuthClients.clientId')}
                ref={register}
                inputWidth="l"
                isDisabled
              />
            </ECColumn>
          </ECRow>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="clientSecret"
                name="clientSecret"
                label={t('oAuthClients.clientSecret')}
                help={t('oAuthClients.form.clientSecretReadOnly')}
                ref={register}
                inputWidth="l"
                isDisabled
              />
            </ECColumn>
          </ECRow>
        </ECFormGroup>
        {errorUpdatingOAuthClient ? (
          <div className="ecl-u-mh-auto">
            <ECFormErrorMessage id="login-error" error={{ message: t('oAuthClients.form.errorSaving') }} />
          </div>
        ) : undefined}
        <ECRow margin={sectionsMargin} className="align_center">
          <br />
          <br />
          <ECColumn col={{ all: 12, lg: 4 }}>
            <ECButton isSubmit type="primary" text={t('oAuthClients.form.submitButton')} dimensions={{ width: 100 }} />
          </ECColumn>
        </ECRow>
      </ECForm>
    </ECColumn>
  )
}
