import React from 'react'

import { createBrowserHistory } from 'history'
import { useDispatch } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'

import { Footer } from '../../../components/Footer'
import { RouterPageChangeTracker } from '../../../components/RouterPageChangeTracker'
import ScrollToTop from '../../../components/ScrollToTop'
import { logOutFromBackOffice, refreshBackOfficeUser } from '../../../services/back-office/back-office-login-service'
import { PubSub } from '../../../utils/PubSub'
import { ForgotPasswordPage } from '../../anglers/pages/my-profile/ForgotPasswordPage'
import { Header } from '../components/Header'
import { Landing } from './landing/Landing'
import { Login } from './login/LoginPage'
import { PrivateRoute } from './PrivateRoute'
import { RouterLoggedIn } from './RouterLoggedIn'

const history = createBrowserHistory()

export const AppRouter: React.FC = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    refreshBackOfficeUser(dispatch)
  }, [dispatch])

  React.useEffect(() => {
    refreshBackOfficeUser(dispatch)
    const listenerId = PubSub.subscribe('admin-logged-out', () => {
      logOutFromBackOffice(dispatch)
    })
    return () => PubSub.unsubscribe('admin-logged-out', listenerId)
  }, [dispatch])

  return (
    <Router history={history}>
      <ScrollToTop />
      <Header />
      <RouterPageChangeTracker />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/login" component={Login} />
        <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
        <PrivateRoute component={RouterLoggedIn} />
      </Switch>
      <Footer />
    </Router>
  )
}
