import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'

import './App.scss'
import { AppRouter as PublicRouter } from './containers/anglers/pages/Router'
import { ECWebtoolLoadedAtom } from './containers/back-office/components/ec-webtool-atom'
import { AppRouter as AdminRouter } from './containers/back-office/pages/Router'

const globanConfig = {
  utility: 'globan',
  zindex: 10,
}

const queryClient = new QueryClient()

function App() {
  const isAdmin = window._env_.REACT_APP_RCSB_TYPE === 'admin'
  const showGloban = window._env_.REACT_APP_SHOW_GLOBAN === 'true'
  const { i18n } = useTranslation()

  const setECWebtoolLoadedAtom = useSetRecoilState(ECWebtoolLoadedAtom)

  React.useEffect(() => {
    const onWtReady = () => {
      setECWebtoolLoadedAtom(true)
    }
    window.addEventListener('wtReady', onWtReady)
    return () => {
      window.removeEventListener('wtReady', onWtReady)
    }
  }, [setECWebtoolLoadedAtom])

  const title = isAdmin ? 'Admin - Recreational Fisheries (RecFishing)' : 'Recreational Fisheries (RecFishing)'

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Helmet htmlAttributes={{ lang: i18n.language.split('-')[0] }}>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={title} />
          {/* Should be loaded here so the script has the language from the html tag */}
          <script key="europa-load-js" defer src="https://europa.eu/webtools/load.js" type="text/javascript"></script>
        </Helmet>
        {showGloban && <script type="application/json">{JSON.stringify(globanConfig)}</script>}
        {isAdmin ? <AdminRouter /> : <PublicRouter />}
      </div>
    </QueryClientProvider>
  )
}

export default App
