import React from 'react'

import { ECTagList } from '../../../components/EC/ECTag'
import { ECFormGroup } from '../../../components/EC/forms/ECFormGroup'
import { ECRadioInput } from '../../../components/EC/forms/ECRadio'
import { ECSelect, ECSelectOption } from '../../../components/EC/forms/ECSelect'
import { ECColumn, ECRow } from '../../../components/EC/utilities/ECGrid'
import { ECSpacableProps } from '../../../components/EC/utilities/ECSpacable'

export interface ScopeGroupProps {
  name?: string
  legend?: string
  placeholder?: string
  help?: string
  labelSelectAll?: string
  labelSelectNotAll?: string
  value: Scope
  options: Array<ECSelectOption>
  onChange: (value: Scope) => void
  isAllAvailable?: boolean
  isDisabled?: boolean
}
export const ScopeGroup: React.FC<ScopeGroupProps & ECSpacableProps> = ({
  name,
  isDisabled = false,
  legend,
  help,
  labelSelectAll,
  labelSelectNotAll,
  placeholder,
  value,
  options,
  margin,
  onChange,
  isAllAvailable,
}) => {
  const onSelectAll = React.useCallback(
    (newValue: boolean) => {
      return () =>
        onChange({
          selected: [...value.selected],
          isAll: newValue,
        })
    },
    [onChange, value],
  )

  const onAddOption = React.useCallback(
    (newlySelectedValue: string) => {
      let newValue = {
        selected: [...value.selected],
        isAll: false,
      }
      if (!newValue.selected.includes(newlySelectedValue)) {
        newValue.selected.push(newlySelectedValue)
      }
      onChange(newValue)
    },
    [onChange, value.selected],
  )

  const onRemoveOption = React.useCallback(
    (newlyRemovedValue: string) => {
      let newValue = {
        selected: [...value.selected],
        isAll: false,
      }
      if (newValue.selected.includes(newlyRemovedValue)) {
        newValue.selected.splice(newValue.selected.indexOf(newlyRemovedValue), 1)
      }
      onChange(newValue)
    },
    [onChange, value.selected],
  )

  const { selectableOptions, selectedOptions } = React.useMemo(() => {
    return {
      selectableOptions: options.filter((elt) => !value.selected.includes(elt.value)),
      selectedOptions: options.filter((elt) => value.selected.includes(elt.value)),
    }
  }, [options, value.selected])

  return (
    <ECFormGroup id={`${name}-attribution`} legend={legend} help={help} margin={margin}>
      {isAllAvailable && (
        <>
          <ECRadioInput
            id={`scope-${name}-all`}
            value="true"
            label={labelSelectAll}
            isChecked={value.isAll}
            onSelect={onSelectAll(true)}
            isDisabled={isDisabled}
          />
          <ECRadioInput
            id={`scope-${name}-selected`}
            value="false"
            label={labelSelectNotAll}
            isChecked={!value.isAll}
            onSelect={onSelectAll(false)}
            isDisabled={isDisabled}
          />
        </>
      )}
      {(!value.isAll || !isAllAvailable) && (
        <ECRow>
          <ECColumn col={{ all: 12 }}>
            <ECSelect
              id={`scope-${name}-select`}
              name={`scope-${name}-select`}
              placeholder={placeholder}
              options={selectableOptions}
              forcePlaceholder
              onSelect={onAddOption}
              width="l"
              isDisabled={isDisabled}
            />
          </ECColumn>
          <ECColumn col={{ all: 12 }}>
            <ECRow margin={{ left: 's' }}>
              <ECTagList id={name || ''} listSelection={selectedOptions} onRemoveBadge={onRemoveOption} />
            </ECRow>
          </ECColumn>
        </ECRow>
      )}
    </ECFormGroup>
  )
}
