import React from 'react'

import classnames from 'classnames'
import moment from 'moment'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHeader,
  ECTableHeaderCell,
  ECTableRow,
} from '../../../../components/EC/ECTable'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { ECColumn } from '../../../../components/EC/utilities/ECGrid'
import { useMarginClass } from '../../../../components/EC/utilities/ECSpacable'
import { ECStack } from '../../../../components/EC/utilities/ECStack'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { useBackOfficeTranslate } from '../../../../i18n'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'

const HEADERS = ['users.list.identification', 'users.list.access', 'users.specs.parentUser']

export const UserListPage: React.FC = () => {
  const t = useBackOfficeTranslate()
  const me = useBackOfficeUserSelector()

  const [rows, setRows] = React.useState<BackOfficeUser[]>([])

  React.useEffect(() => {
    backOfficeApi
      .getUsers()
      .then((newUsers) => {
        setRows(newUsers.filter((elt) => elt.id !== me?.id))
      })
      .catch((err) => {})
  }, [me])

  const tableClass = classnames(useMarginClass({ top: 'l' }))

  return (
    <ECColumn col={{ all: 12 }}>
      <ECStack horizontalAlignment="end">
        <ECButton type="primary" text={t('users.create')} href="/backOfficeUsers/new"></ECButton>
      </ECStack>
      <ECTable className={tableClass}>
        <ECTableHeader>
          <ECTableRow>
            {HEADERS.map((value: string) => {
              return <ECTableHeaderCell key={value}>{t(value)}</ECTableHeaderCell>
            })}
          </ECTableRow>
        </ECTableHeader>
        <ECTableBody>
          {rows.map((item) => {
            return (
              <ECTableRow key={item.id}>
                <ECTableCell>
                  <ECLink path={`/backOfficeUsers/${item.id}`}>{item.id}</ECLink>
                  <ECText>
                    <b>{`${item.firstName} ${item.lastName}`}</b>
                    <br />
                    {item.institution}
                  </ECText>
                </ECTableCell>
                <ECTableCell>
                  {item.isActive
                    ? item.isActiveUntil
                      ? t('users.specs.activeStatus.activeUntil', { endDate: moment(item.isActiveUntil).format('lll') })
                      : t('users.specs.activeStatus.active')
                    : t('users.specs.activeStatus.inactive')}
                </ECTableCell>
                <ECTableCell>
                  <ECText>
                    {item.creator ? (
                      <ECLink path={`/backOfficeUsers/${item.creator.id}`}>
                        {item.creator.firstName} {item.creator.lastName}
                      </ECLink>
                    ) : (
                      ''
                    )}
                  </ECText>
                </ECTableCell>
              </ECTableRow>
            )
          })}
        </ECTableBody>
      </ECTable>
    </ECColumn>
  )
}
