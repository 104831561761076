import React from 'react'

import { Redirect, useParams } from 'react-router-dom'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECColumn } from '../../../../components/EC/utilities/ECGrid'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'
import { UserProfile } from '../../components/UserProfile'

export const UserDetailsPage: React.FC = () => {
  const currentUser = useBackOfficeUserSelector()
  const [user, setUser] = React.useState<BackOfficeUser>()

  const params = useParams<{ id: string }>()

  React.useEffect(() => {
    const { id } = params
    if (id) {
      backOfficeApi.getUser(id).then((newUser) => {
        setUser(newUser)
      })
    }
  }, [params])

  if (!user) {
    return <Spinner />
  }
  if (user.id === currentUser?.id) {
    return <Redirect to="/backOfficeUsers/me" />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      <ECTitle level={1} isPageTitle>
        {`${user.firstName} ${user.lastName}`}
      </ECTitle>
      <UserProfile user={user} />
    </ECColumn>
  )
}
