import React from 'react'

import { useTranslation } from 'react-i18next'

import { anglersApi } from '../../../../api/anglersApi'
import { ECContainer } from '../../../../components/EC/ECContainer'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useCommonTranslate } from '../../../../i18n'
import { trackOutlinkClick } from '../../../../services/analytics-service'

export const AppsPage: React.FC = () => {
  const tc = useCommonTranslate()
  const { t, i18n } = useTranslation('static-apps')

  const [apps, setApps] = React.useState<CertifiedApp[]>([])

  React.useEffect(() => {
    anglersApi
      .listCertifiedApps()
      .then((newApps) => {
        setApps(newApps)
      })
      .catch((err) => {})
  }, [])

  return (
    <ECContainer>
      <ECTitle isPageTitle>{tc('footer.more.certified-apps')}</ECTitle>
      <ECText>{t('introduction')}</ECText>
      <ECText>{t('account')}</ECText>
      <ECContainer>
        <ul className="ecl-row" style={{ padding: 0, listStyle: 'none' }}>
          {apps.map((elt) => (
            <li key={elt.name} className="ecl-col-md-4" style={{ marginTop: '1rem' }}>
              <article className="ecl-card">
                <header className="ecl-card__header">
                  <a href={elt.clientUrl} target="_blank" rel="noopener noreferrer">
                    <div
                      className="ecl-card__image"
                      aria-label="card image"
                      role="img"
                      style={{
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url(${elt.logoUrl})`,
                      }}></div>
                  </a>
                  <div className="ecl-card__meta">{t('certifiedApp')}</div>
                  <h3 className="ecl-card__title">
                    <a
                      href={elt.clientUrl}
                      onClick={() => {
                        trackOutlinkClick(elt.clientUrl)
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ecl-link ecl-link--standalone"
                      aria-label={elt.name}>
                      {elt.name}
                    </a>
                  </h3>
                </header>
                <div className="ecl-card__body">
                  <div className="ecl-card__description">
                    {elt.descriptions[i18n.language.split('-')[0]] ?? elt.descriptions['en']}
                  </div>
                </div>
                <footer className="ecl-card__footer">
                  <ul className="ecl-card__link-container">
                    <li className="ecl-card__link-item">
                      <a
                        href={elt.clientUrl}
                        onClick={() => {
                          trackOutlinkClick(elt.clientUrl)
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ecl-card__link ecl-link ecl-link--standalone"
                        aria-label="">
                        {t('seeWebsite', { appName: elt.name })}
                      </a>
                    </li>
                  </ul>
                </footer>
              </article>
            </li>
          ))}
        </ul>
      </ECContainer>
    </ECContainer>
  )
}
