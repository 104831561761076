import React from 'react'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { anglersApi } from '../../../api/anglersApi'
import { backOfficeApi } from '../../../api/backOfficeApi'
import { ECButton } from '../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../components/EC/forms/ECForm'
import { ECTextInput } from '../../../components/EC/forms/ECTextInput'
import { ECDimensionProps } from '../../../components/EC/utilities/ECDimensionable'
import { ECColumn, ECGrid, ECRow } from '../../../components/EC/utilities/ECGrid'
import { ECText } from '../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../components/Spinner/Spinner'
import { useAnglersTranslate, useBackOfficeTranslate } from '../../../i18n'

export const ResetPasswordRequestForm: React.FC = () => {
  const ta = useAnglersTranslate()
  const tb = useBackOfficeTranslate()

  const [loading, setLoading] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoginError, setShowLoginError] = React.useState(false)

  const validationSchema = yup.object({
    username: yup.string().email().required(),
  })

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      username: '',
    },
    validationSchema,
  })

  const isAdmin = window._env_.REACT_APP_RCSB_TYPE === 'admin'

  const onSubmit = handleSubmit(async (values) => {
    if (loading) {
      return
    }
    try {
      setLoading(true)
      setShowSuccess(false)
      setShowLoginError(false)
      if (isAdmin) {
        await backOfficeApi.requestResetPassword(values.username)
      } else {
        await anglersApi.requestResetPassword(values.username)
      }
      setShowSuccess(true)
    } catch (err) {
      setShowLoginError(true)
    }
    setLoading(false)
  })

  const textInputDimension: ECDimensionProps = { width: 100 }

  return (
    <ECForm onSubmit={onSubmit}>
      <ECGrid>
        <ECColumn col={{ lg: 6 }} offset={{ lg: 3 }}>
          <ECTitle level={1} margin={{ bottom: 'l', horizontal: 'auto' }}>
            {isAdmin ? tb('resetPassword.title') : ta('resetPassword.title')}
          </ECTitle>
          <ECText>{isAdmin ? tb('resetPassword.baseline') : ta('resetPassword.baseline')}</ECText>
          <ECTextInput
            id="username"
            name="username"
            inputType="text"
            label={ta('resetPassword.username.label')}
            isRequired
            ref={register}
            error={errors.username}
            width={textInputDimension}
            margin={{ vertical: 's', horizontal: 'auto' }}
            inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
            isDisabled={loading}
          />
          {showLoginError ? (
            <div className="ecl-u-mh-auto">
              <ECFormErrorMessage id="login-error" error={{ message: ta('resetPassword.requestError') }} />
            </div>
          ) : undefined}
          <ECRow>
            <div className="ecl-u-mh-auto">
              {loading ? (
                <Spinner />
              ) : showSuccess ? (
                <ECRow>
                  <ECText color="blue">{isAdmin ? tb('resetPassword.success') : ta('resetPassword.success')}</ECText>
                </ECRow>
              ) : (
                <ECButton
                  type="primary"
                  isSubmit
                  text={ta('resetPassword.send')}
                  margin={{ top: 's', bottom: 's', horizontal: 'auto' }}
                  padding={{ horizontal: '4xl' }}
                />
              )}
            </div>
          </ECRow>
        </ECColumn>
      </ECGrid>
    </ECForm>
  )
}
