import React from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { ECDimensionProps } from '../../../../components/EC/utilities/ECDimensionable'
import { ECColumn, ECGrid, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useBackOfficeTranslate } from '../../../../i18n'
import { refreshBackOfficeUser } from '../../../../services/back-office/back-office-login-service'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'
import { Logger } from '../../../../utils/Logger'
import './Login.scss'

export const Login = () => {
  const t = useBackOfficeTranslate()

  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useBackOfficeUserSelector()

  const [hasLoginError, setHasLoginError] = React.useState(false)

  const validationSchema = yup.object({
    username: yup.string().email(t('login.username.error')).required(),
    password: yup.string().min(6).required(),
  })

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    validationSchema,
  })

  const onSubmit = handleSubmit(async (values: Record<string, string>) => {
    setHasLoginError(false)
    try {
      await backOfficeApi.postLogin({
        username: values.username.trim(),
        password: values.password.trim(),
      })
      await refreshBackOfficeUser(dispatch)
      history.push('/data/indicators')
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Logger.debug('wrong login info !')
        setHasLoginError(true)
        return
      }
    }
  })

  const textInputDimension: ECDimensionProps = { width: 100 }

  if (currentUser) {
    return <Redirect to="/backOfficeUsers/me" />
  }

  return (
    <ECForm onSubmit={onSubmit}>
      <ECGrid>
        <ECColumn col={{ lg: 6 }} offset={{ lg: 3 }}>
          <ECTitle level={1} margin={{ bottom: 'l', horizontal: 'auto' }}>
            {t('login.title')}
          </ECTitle>
          <ECTextInput
            id="username"
            name="username"
            label={t('login.username.label')}
            placeholder={t('login.username.placeholder')}
            ref={register}
            width={textInputDimension}
            error={errors.username}
            margin={{ vertical: 's', horizontal: 'auto' }}
          />
          <ECTextInput
            inputType="password"
            id="password"
            name="password"
            label={t('login.password.label')}
            placeholder="***"
            width={textInputDimension}
            ref={register}
            error={errors.password}
            margin={{ vertical: 's', horizontal: 'auto' }}
          />
          {hasLoginError ? (
            <div className="ecl-u-mh-auto">
              <ECFormErrorMessage id="login-form-error" error={{ message: t('login.wrongIdOrPassword') }} />
            </div>
          ) : undefined}
          <ECRow>
            <ECButton
              type="primary"
              isSubmit
              text={t('login.submitButton')}
              margin={{ top: 's', bottom: 's', horizontal: 'auto' }}
              padding={{ horizontal: '4xl' }}
            />
          </ECRow>
          <ECRow>
            <ECLink path="/forgotPassword" margin={{ horizontal: 'auto' }} className="forgotLink">
              {t('login.forgotLink')}
            </ECLink>
          </ECRow>
        </ECColumn>
      </ECGrid>
    </ECForm>
  )
}
