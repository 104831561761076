import React from 'react'

import { useForm } from 'react-hook-form'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECColumn, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../../components/EC/utilities/ECSpacable'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../../i18n'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'
import { useScopesData } from '../../../../utils/hooks/useScopesData'
import { Logger } from '../../../../utils/Logger'
import { ScopeGroup } from '../../common/ScopeGroup'
import { RoleEditForm } from '../../components/RoleEditForm'
import { UserProfile } from '../../components/UserProfile'
import { SelectUserActiveStatus, UserActiveStatusValue } from '../../management/SelectUserActiveStatus'

export const UserEditPage = () => {
  const t = useBackOfficeTranslate()
  const history = useHistory()

  const [loading, setLoading] = React.useState(false)
  const [errorUpdatingUser, setErrorUpdatingUser] = React.useState(false)

  const currentUser = useBackOfficeUserSelector()
  const [user, setUser] = React.useState<BackOfficeUser>()

  const params = useParams<{ id: string }>()
  const { id } = params

  const { scopes, fishSpecies, geographicalAreas, anglersGroups } = useScopesData()

  const validationSchema = yup.object({
    activationStatus: yup.string().oneOf(['active', 'activeUntil', 'inactive']).required(),
    isActiveUntil: yup.date(),
    role: yup.object({
      role: yup
        .string()
        .required()
        .oneOf(currentUser?.usableRole?.canCreateUsersWithRoles ?? []),
    }),
  })
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm<CreateBackOfficeUserRequest>({
    defaultValues: {
      activationStatus: 'inactive',
      isActiveUntil: undefined,
      fishSpeciesScope: { isAll: false, selected: [] },
      geographicalAreasScope: { isAll: false, selected: [] },
      anglersGroupsScope: { isAll: false, selected: [] },
      role: {},
    },
    validationSchema,
    mode: 'onBlur',
  })

  React.useEffect(() => {
    register({ name: 'fishSpeciesScope' })
    register({ name: 'geographicalAreasScope' })
    register({ name: 'anglersGroupsScope' })
    register({ name: 'activationStatus' })
    register({ name: 'isActiveUntil' })
    register({ name: 'role' })
  }, [register])

  React.useEffect(() => {
    if (id) {
      backOfficeApi.getUser(id).then((newUser) => {
        setUser(newUser)
        reset({
          fishSpeciesScope: {
            isAll: newUser.scopes?.fishSpecies.isAll ?? false,
            selected: newUser.scopes?.fishSpecies.selected ?? [],
          },
          geographicalAreasScope: {
            isAll: newUser.scopes?.geographicalAreas.isAll ?? false,
            selected: newUser.scopes?.geographicalAreas.selected ?? [],
          },
          anglersGroupsScope: {
            isAll: newUser.scopes?.anglersGroups.isAll ?? false,
            selected: newUser.scopes?.anglersGroups.selected ?? [],
          },
          activationStatus: newUser.isActive ? (!!newUser.isActiveUntil ? 'activeUntil' : 'active') : 'inactive',
          isActiveUntil: newUser.isActive && newUser.isActiveUntil ? new Date(newUser.isActiveUntil) : undefined,
          role: {
            role: newUser.role?.type,
            canManageUsers: (newUser.usableRole?.canCreateUsersWithRoles ?? []).length > 0,
            aggregPeriod: newUser.usableRole?.aggregationPeriod,
            aggregGeography: newUser.usableRole?.aggregationGeography,
          },
        })
      })
    }
  }, [id, params, reset])

  const roleValues = watch('role')
  const onChangeRoleValues = React.useCallback(
    (newValues: EditRoleValues) => {
      setValue('role', newValues)
    },
    [setValue],
  )

  const fishSpeciesScope = watch('fishSpeciesScope')
  const geographicalAreasScope = watch('geographicalAreasScope')
  const anglersGroupsScope = watch('anglersGroupsScope')

  const onChangeSpeciesScope = React.useCallback(
    (newScope: Scope) => {
      setValue('fishSpeciesScope', newScope)
    },
    [setValue],
  )

  const onChangeGeographicalAreasScope = React.useCallback(
    (newScope: Scope) => {
      setValue('geographicalAreasScope', newScope)
    },
    [setValue],
  )

  const onChangeAnglersGroupsScope = React.useCallback(
    (newScope: Scope) => {
      setValue('anglersGroupsScope', newScope)
    },
    [setValue],
  )

  const activationStatus = watch('activationStatus')
  const isActiveUntil = watch('isActiveUntil')

  const onChangeActivationStatus = React.useCallback(
    (newValue: UserActiveStatusValue) => {
      setValue('activationStatus', newValue.activationStatus)
      setValue('isActiveUntil', newValue.isActiveUntil)
    },
    [setValue],
  )

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true)
    setErrorUpdatingUser(false)
    try {
      Logger.debug('updating values', values)
      if (user?.id) {
        const newUser = (await backOfficeApi.updateUser(user.id, values)).data
        history.push(`/backOfficeUsers/${newUser.id}`)
      }
    } catch (err) {
      setErrorUpdatingUser(true)
      setLoading(false)
    }
  })

  const sectionsMargin: ECSpacable = { top: 'l' }

  if (loading) {
    return <Spinner />
  }

  if (!user || !scopes) {
    return <Spinner />
  }
  if (user.id === currentUser?.id) {
    return <Redirect to="/backOfficeUsers/me/edit" />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      <ECTitle level={1} isPageTitle>
        {t('users.edit')}
      </ECTitle>
      <UserProfile user={user} noShow={['role', 'scopes', 'activationStatus', 'editButton']} />
      <ECForm onSubmit={onSubmit}>
        <RoleEditForm values={roleValues} onChange={onChangeRoleValues} hasError={!!errors.role} />
        <ScopeGroup
          name="fishSpecies"
          margin={sectionsMargin}
          value={fishSpeciesScope}
          options={fishSpecies}
          onChange={onChangeSpeciesScope}
          isAllAvailable={scopes.fishSpecies.isAll}
          legend={t('users.createForm.scopes.fishSpecies.title')}
          help={t('users.createForm.scopes.editSubtitle')}
          labelSelectAll={t('users.createForm.scopes.fishSpecies.all.true')}
          labelSelectNotAll={t('users.createForm.scopes.fishSpecies.all.false')}
          placeholder={t('users.createForm.scopes.fishSpecies.select')}
        />
        <ScopeGroup
          name="geographicalAreas"
          margin={sectionsMargin}
          value={geographicalAreasScope}
          options={geographicalAreas}
          onChange={onChangeGeographicalAreasScope}
          isAllAvailable={scopes.geographicalAreas.isAll}
          legend={t('users.createForm.scopes.geographicalAreas.title')}
          help={t('users.createForm.scopes.editSubtitle')}
          labelSelectAll={t('users.createForm.scopes.geographicalAreas.all.true')}
          labelSelectNotAll={t('users.createForm.scopes.geographicalAreas.all.false')}
          placeholder={t('users.createForm.scopes.geographicalAreas.select')}
        />
        <ScopeGroup
          name="anglersGroups"
          margin={sectionsMargin}
          value={anglersGroupsScope}
          options={anglersGroups}
          onChange={onChangeAnglersGroupsScope}
          isAllAvailable={scopes.anglersGroups.isAll}
          legend={t('users.createForm.scopes.anglersGroups.title')}
          help={t('users.createForm.scopes.editSubtitle')}
          labelSelectAll={t('users.createForm.scopes.anglersGroups.all.true')}
          labelSelectNotAll={t('users.createForm.scopes.anglersGroups.all.false')}
          placeholder={t('users.createForm.scopes.anglersGroups.select')}
        />
        <SelectUserActiveStatus
          sectionsMargin={sectionsMargin}
          value={{
            activationStatus,
            isActiveUntil,
          }}
          isEdition
          onChange={onChangeActivationStatus}
        />
        <ECRow margin={sectionsMargin} className="align_center">
          {/*<WarnMessage state={userSelectedOptions} />*/}
        </ECRow>
        {errorUpdatingUser ? (
          <div className="ecl-u-mh-auto">
            <ECFormErrorMessage id="login-error" error={{ message: t('users.editForm.error') }} />
          </div>
        ) : undefined}
        <ECRow margin={sectionsMargin} className="align_center">
          <ECColumn col={{ all: 12, lg: 4 }}>
            <ECButton isSubmit type="primary" text={t('users.editForm.submitButton')} dimensions={{ width: 100 }} />
          </ECColumn>
        </ECRow>
      </ECForm>
    </ECColumn>
  )
}
