/**
 * See https://ec.europa.eu/component-library/ec/components/forms/select/code/
 */

import React, { useCallback } from 'react'

import { ReactComponent as CornerArrow } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/corner-arrow.svg'
import classnames from 'classnames'

import { ECContentBaseProps } from '../core/ECContentBase'
import { ECSpacableProps } from '../utilities/ECSpacable'
import { ECStack } from '../utilities/ECStack'
import { ECFormIdPrefixes } from './ECForm'
import { ECFormGroup, ECFormGroupProps } from './ECFormGroup'

export interface ECSelectOption {
  value: string
  title: string
}

export interface ECSelectProps extends ECFormGroupProps {
  name: string
  /** default = 'm' */
  width?: 's' | 'm' | 'l'
  label?: string
  help?: string
  placeholder?: string
  forcePlaceholder?: boolean
  options?: Array<ECSelectOption>
  selectedValue?: string
  onSelect?: (value: string) => void
}

export const ECSelect = React.forwardRef<HTMLSelectElement, ECSelectProps & ECContentBaseProps & ECSpacableProps>(
  (
    {
      id,
      name,
      width = 'm',
      label,
      help,
      placeholder,
      forcePlaceholder = false,
      isDisabled: disable,
      optionalText,
      isRequired: required,
      options,
      selectedValue,
      error,
      onSelect,
      margin,
      padding,
      className,
    },
    ref,
  ) => {
    const onChange = useCallback((event) => onSelect?.(event.target.value), [onSelect])

    // ECStack necessary otherwise select is on same line as title
    return (
      <ECStack direction="column" horizontalAlignment="center" margin={margin} padding={padding} className={className}>
        <ECFormGroup
          id={id}
          legend={label}
          help={help}
          error={error}
          optionalText={optionalText}
          isRequired={required}
          padding={{ bottom: 'xs' }}
        />
        <div className={`ecl-select__container ecl-select__container--${width}`}>
          <select
            ref={ref}
            id={id}
            name={name}
            className={classnames('ecl-select', {
              'ecl-select--disabled': disable,
              'ecl-select--invalid': error !== undefined,
            })}
            aria-label={label}
            onChange={onChange}
            value={forcePlaceholder ? 'placeholder' : selectedValue !== '' ? selectedValue : 'placeholder'}
            disabled={disable}>
            {placeholder && (
              <option value="placeholder" disabled>
                {placeholder}
              </option>
            )}
            {options?.map(({ value, title }) => {
              return <option key={`${id}-${value}`} value={value} dangerouslySetInnerHTML={{ __html: title }}></option>
            })}
          </select>
          <div className="ecl-select__icon">
            <CornerArrow
              focusable="false"
              aria-hidden="true"
              className="ecl-select__icon-shape ecl-icon ecl-icon--s ecl-icon--rotate-180"
            />
          </div>
        </div>
      </ECStack>
    )
  },
)
