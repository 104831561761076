/**
 * See https://ec.europa.eu/component-library/ec/utilities/grid/
 */

import React from 'react'

import classnames from 'classnames'

import { ECContentBaseProps } from '../core/ECContentBase'
import { ECBoderableProps, useBorderClass } from './ECBorder'
import { ECSpacableProps, useMarginClass, usePaddingClass } from './ECSpacable'
import { ECTextProps, useTextClasses } from './ECText'

export interface ECColumnViewportProps {
  all?: number
  xl?: number
  lg?: number
  md?: number
  sm?: number
}

export interface ECColumnProps {
  col?: ECColumnViewportProps
  offset?: ECColumnViewportProps
}

export const ECColumn: React.FC<
  ECColumnProps & ECBoderableProps & ECTextProps & ECSpacableProps & ECContentBaseProps
> = ({ col, offset, color, styles, border, margin, padding, className, children, style }) => {
  const colClass = classnames(
    {
      [`ecl-col-${col?.all}`]: (col?.all ?? 0) > 0,
      [`ecl-col-sm-${col?.sm}`]: (col?.sm ?? 0) > 0,
      [`ecl-col-md-${col?.md}`]: (col?.md ?? 0) > 0,
      [`ecl-col-lg-${col?.lg}`]: (col?.lg ?? 0) > 0,
      [`ecl-col-xl-${col?.xl}`]: (col?.xl ?? 0) > 0,
      [`ecl-offset-${offset?.all}`]: (offset?.all ?? 0) > 0,
      [`ecl-offset-sm-${offset?.sm}`]: (offset?.sm ?? 0) > 0,
      [`ecl-offset-md-${offset?.md}`]: (offset?.md ?? 0) > 0,
      [`ecl-offset-lg-${offset?.lg}`]: (offset?.lg ?? 0) > 0,
      [`ecl-offset-xl-${offset?.xl}`]: (offset?.xl ?? 0) > 0,
    },
    useBorderClass(border),
    useTextClasses(color, styles),
    useMarginClass(margin),
    usePaddingClass(padding),
    className,
  )
  return (
    <div className={colClass} style={style}>
      {children}
    </div>
  )
}

export const ECRow: React.FC<ECBoderableProps & ECTextProps & ECSpacableProps & ECContentBaseProps> = ({
  border,
  color,
  styles,
  margin,
  padding,
  className,
  children,
}) => {
  const rowClass = classnames(
    'ecl-row',
    useBorderClass(border),
    useTextClasses(color, styles),
    useMarginClass(margin),
    usePaddingClass(padding),
    className,
  )
  return <div className={rowClass}>{children}</div>
}

export const ECGrid: React.FC<ECBoderableProps & ECTextProps & ECSpacableProps & ECContentBaseProps> = ({
  border,
  color,
  styles,
  margin,
  padding,
  className,
  children,
  style,
}) => {
  const gridClass = classnames(
    'ecl-container',
    useBorderClass(border),
    useTextClasses(color, styles),
    useMarginClass(margin),
    usePaddingClass(padding),
    className,
  )
  return (
    <div className={gridClass} style={style}>
      {children}
    </div>
  )
}
