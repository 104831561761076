import React, { useMemo, useState } from 'react'

import moment from 'moment'
import { useDispatch } from 'react-redux'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECPagination } from '../../../../components/EC/navigation/ECPagination'
import { ECColumn } from '../../../../components/EC/utilities/ECGrid'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../../i18n'
import { SetFiltersActionCreator, useLatestSavedFilters } from '../../../../store/reducers/filtersReducer'
import { Filters, FilterSection } from '../../indicators/FilterSection'
import { IndicatorsTable } from '../../indicators/IndicatorsTable'

const PAGE_SIZE = 10

export const GlobalIndicators = () => {
  const t = useBackOfficeTranslate()
  const dispatch = useDispatch()

  const latestUsedFilters = useLatestSavedFilters()
  const [filters, setFilters] = React.useState<Filters>(latestUsedFilters || {})
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [data, setData] = React.useState<Exports.AggregatedDataResponse>()

  const maxPage = useMemo(() => {
    return Math.floor((data?.totalCount ?? 0) / PAGE_SIZE)
  }, [data])

  const onPageSelected = React.useCallback((value: number) => {
    setCurrentPage(value)
  }, [])

  const refreshData = React.useCallback(async () => {
    const offset = currentPage * PAGE_SIZE
    const results = await backOfficeApi.getAggregatedData({
      limit: PAGE_SIZE,
      offset,
      startDate: new Date(filters.startPeriod ?? moment().startOf('month').valueOf()),
      endDate: new Date(filters.endPeriod ?? moment().endOf('month').valueOf()),
      speciesIds: filters.fishSpeciesScope?.isAll ? [] : filters.fishSpeciesScope?.selected ?? [],
      geographicalAreasIds: filters.geographicalAreasScope?.isAll ? [] : filters.geographicalAreasScope?.selected ?? [],
    })
    setData(results)
  }, [currentPage, filters.endPeriod, filters.fishSpeciesScope, filters.geographicalAreasScope, filters.startPeriod])

  React.useEffect(() => {
    refreshData()
  }, [refreshData])

  const onChangeFilters = React.useCallback(
    (newFilters: Filters) => {
      dispatch(SetFiltersActionCreator(newFilters))
      setFilters(newFilters)
    },
    [dispatch],
  )

  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([])

  const onHideColumn = React.useCallback(
    (columnKey) => {
      if (!hiddenColumns.includes(columnKey)) {
        setHiddenColumns(hiddenColumns.concat([columnKey]))
      }
    },
    [hiddenColumns],
  )

  const onShowColumn = React.useCallback(
    (columnKey) => {
      if (hiddenColumns.includes(columnKey)) {
        setHiddenColumns(hiddenColumns.filter((elt) => elt !== columnKey))
      }
    },
    [hiddenColumns],
  )

  const headerKeys = React.useMemo(() => {
    return [
      'period',
      'zone',
      'speciesFaoCode',
      'numberOfCatchesKept',
      'numberOfCatchesReleased',
      'averageSizeKept',
      'averageWeightKept',
      'numberOfAnglers',
    ].filter((elt) => !hiddenColumns.includes(elt))
  }, [hiddenColumns])

  return (
    <ECColumn col={{ all: 12 }}>
      <FilterSection value={filters} onChange={onChangeFilters} />
      {data ? (
        <>
          {hiddenColumns.length > 0 ? (
            <div>
              <br />
              <br />
              <ECText type="lead">{t('aggregatedData.header.hidddenColumns')}</ECText>
              <ul>
                {hiddenColumns.map((elt) => (
                  <li>
                    {t(`aggregatedData.header.${elt}`)}{' '}
                    <ECButton
                      type="text"
                      text={t('aggregatedData.header.showColumn')}
                      onClick={() => onShowColumn(elt)}></ECButton>
                  </li>
                ))}
              </ul>
            </div>
          ) : undefined}
          <IndicatorsTable data={data} headerKeys={headerKeys} mode="aggregatedData" onHideColumn={onHideColumn} />
          {maxPage > 0 && (
            <ECPagination
              dimensions={{ width: 100 }}
              margin={{ top: 'l' }}
              currentPage={currentPage}
              maxPage={maxPage}
              onClick={onPageSelected}
            />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </ECColumn>
  )
}
