import React from 'react'

import { RouteProps, Route, Redirect } from 'react-router-dom'

import { useAnglerSelector } from '../../../store/reducers/user/reducer'

export const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const currentUser = useAnglerSelector()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          if (component) {
            return React.createElement(component, props)
          }
        }
        return <Redirect to="/login" />
      }}
    />
  )
}
