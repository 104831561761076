import '@ecl/ec-preset-website/dist/styles/ecl-ec-preset-website.css'
import React, { Suspense } from 'react'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import './i18n'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import configureStore from './store/store'

import 'moment/locale/fr'

const Loader = () => <div></div>

const { store, persistor } = configureStore()

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <App />
            </Suspense>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorker.unregister()
