import { useSelector } from 'react-redux'
import { Action } from 'redux'

import { AppReduxState } from './reducers'

export interface OAuthReduxState {
  clientId?: string
  redirectUri?: string
}

export const SetOauthActionType = '@@actionTypes/SetOauthActionType'
export interface SetOauthAction extends Action<typeof SetOauthActionType> {
  payload: { oauth?: OAuthReduxState }
}
export const SetOauthActionCreator = (oauth?: OAuthReduxState): SetOauthAction => ({
  type: SetOauthActionType,
  payload: { oauth },
})

export const oAuthInitialState: OAuthReduxState = {}

// REDUCERS
export const OAuthReducer = (state = oAuthInitialState, action: Action) => {
  if (action.type === SetOauthActionType) {
    return (action as SetOauthAction).payload.oauth ?? {}
  }
  return state
}

export const useOAuthSelector = (): Required<OAuthReduxState> | undefined =>
  useSelector((appState: AppReduxState) => {
    const { clientId, redirectUri } = appState.oAuth
    if (clientId && redirectUri) {
      return { clientId, redirectUri }
    }
    return undefined
  })

export const useOAuthAuthorizeRedirectUrl = () =>
  useSelector((appState: AppReduxState) => {
    const { clientId, redirectUri } = appState.oAuth
    if (clientId && redirectUri) {
      return `${window._env_.REACT_APP_API_HOST}/oauth/authorize?client_id=${encodeURIComponent(
        clientId,
      )}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code`
    }
  })
