import React from 'react'

import { useDispatch } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'

import { ContentWithNav } from '../../../components/ContentWithNav'
import { ECNavItemProps } from '../../../components/EC/navigation/ECInpageNav'
import { useAnglersTranslate } from '../../../i18n'
import { AnglersLoginService } from '../../../services/anglers/anglers-login-service'
import { useAnglerSelector } from '../../../store/reducers/user/reducer'
import { AnglerHistory } from './history/AnglerHistory'
import { AnglerProfilePage } from './my-profile/AnglerProfilePage'
import { ChangePasswordPage } from './my-profile/ChangePasswordPage'
import { EditProfilePage } from './my-profile/EditProfilePage'
import { PrivateRoute } from './PrivateRoute'

export const RouterLoggedIn: React.FC = () => {
  const t = useAnglersTranslate()
  const currentAngler = useAnglerSelector()

  const currentPath = useLocation().pathname
  const dispatch = useDispatch()

  const navItems: ECNavItemProps[] = [
    { title: t('nav.profile'), path: '/profile', isActive: currentPath.startsWith('/profile') },
    { title: t('nav.history'), path: '/history', isActive: currentPath === '/history' },
  ]

  const onLogOut = () => {
    AnglersLoginService.logOut(dispatch)
  }

  React.useEffect(() => {
    if (currentAngler?.id) {
      AnglersLoginService.refreshAngler(dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAngler?.id])

  if (!currentAngler) {
    return <Redirect to="/login" />
  }

  return (
    <ContentWithNav
      navTitle={[currentAngler.emailPartial, currentAngler.id].join(' ')}
      navItems={navItems}
      onLogOut={onLogOut}>
      <Switch>
        <PrivateRoute exact path="/profile/edit" component={EditProfilePage} />
        <PrivateRoute exact path="/profile/changePassword" component={ChangePasswordPage} />
        <PrivateRoute path="/profile" component={AnglerProfilePage} />
        <PrivateRoute path="/history" component={AnglerHistory} />
      </Switch>
    </ContentWithNav>
  )
}
