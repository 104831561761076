import React from 'react'

import { countries, Country } from 'countries-list'
import RandExp from 'randexp'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { anglersApi } from '../../../../api/anglersApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECSelect, ECSelectOption } from '../../../../components/EC/forms/ECSelect'
import { ECSelectMulti } from '../../../../components/EC/forms/ECSelectMulti'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECDimensionProps } from '../../../../components/EC/utilities/ECDimensionable'
import { ECColumn, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../../components/EC/utilities/ECSpacable'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useAnglersTranslate } from '../../../../i18n'
import { AnglersLoginService } from '../../../../services/anglers/anglers-login-service'
import { useAnglerSelector } from '../../../../store/reducers/user/reducer'

const zipCodesByCountries = require('../../../../utils/data/zipCodesByCountries.json')

const countriesOptions = Object.entries(countries).map(([key, elt]) => ({
  title: `${elt.native} (${elt.name})`,
  key: key,
  value: key,
}))

const regexpExamplesCache: { [regexp: string]: string } = {}
const getRegexpExample = (regexp: string) => {
  if (regexpExamplesCache[regexp]) {
    return regexpExamplesCache[regexp]
  }
  regexpExamplesCache[regexp] = [
    `"${new RandExp(regexp).gen()}"`,
    `"${new RandExp(regexp).gen()}"`,
    `"${new RandExp(regexp).gen()}"`,
  ].join(', ')
  return regexpExamplesCache[regexp]
}

export const EditProfilePage = () => {
  const t = useAnglersTranslate()

  const dispatch = useDispatch()
  const angler = useAnglerSelector()

  const [loading, setLoading] = React.useState(false)
  const [errorCreatingUser, setErrorCreatingUser] = React.useState<string>()
  const history = useHistory()

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      countryOfResidence: yup.string(),
      zipCode: yup.string().when('countryOfResidence', (country: string | undefined) => {
        const zipCodeRegexp: string = country && country !== 'placeholder' ? zipCodesByCountries[country] ?? '.*' : '.*'
        let countryName = ''
        if (country) {
          countryName = (countries as { [code: string]: Country })[country].name
        }
        return yup.string().matches(new RegExp(`(^${zipCodeRegexp}$)`), {
          message: t('register.zipCode.error', {
            countryName,
            example: getRegexpExample(`^${zipCodeRegexp}$`),
          }),
        })
      }),
      ownsBoat: yup.string().oneOf(['true', 'false'], t('register.ownsBoat.mandatory')).required(),
      boatCountry: yup.string().when('ownsBoat', {
        is: 'true',
        then: yup.string().notOneOf(['placeholder'], t('register.boatCountry.mandatory')).required(),
      }),
    })
  }, [t])

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: 'onBlur',
    validationSchema,
    defaultValues: {
      zipCode: angler?.zipCode ?? '',
      countryOfResidence: angler?.countryOfResidence ?? 'placeholder',
      gender: angler?.gender ?? 'placeholder',
      ownsBoat: (angler?.ownsBoat === true ? 'true' : 'false') as 'true' | 'false' | 'placeholder',
      boatCountry: angler?.boatCountry ?? 'placeholder',
      anglersGroups: angler?.anglersGroups?.map((elt) => elt.id) ?? ([] as string[]),
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true)
    setErrorCreatingUser(undefined)
    try {
      await anglersApi.putAngler({
        zipCode: values.zipCode.trim(),
        countryOfResidence: values.countryOfResidence !== 'placeholder' ? values.countryOfResidence : undefined,
        ownsBoat: values.ownsBoat === 'true',
        boatCountry:
          values.ownsBoat === 'true'
            ? values.boatCountry !== 'placeholder'
              ? values.boatCountry
              : undefined
            : undefined,
        anglersGroups: values.anglersGroups,
      })
      await AnglersLoginService.refreshAngler(dispatch)
      setErrorCreatingUser(undefined)
      history.push('/profile')
    } catch (err) {
      if (err?.response?.status === 400) {
        setErrorCreatingUser(t('profile.editForm.error.badRequest'))
      } else {
        setErrorCreatingUser(t('profile.editForm.error.unknown'))
      }
    }
    setLoading(false)
  })

  React.useEffect(() => {
    register('anglersGroups')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ownsBoat = watch('ownsBoat')

  const ownsBoatOptions = React.useMemo(() => {
    return [
      {
        key: 'true',
        value: 'true',
        title: t('register.ownsBoat.options.true'),
      },
      {
        key: 'false',
        value: 'false',
        title: t('register.ownsBoat.options.false'),
      },
    ]
  }, [t])

  const [allAnglersGroups, setAllAnglersGroups] = React.useState<AnglersGroup[]>([])
  React.useEffect(() => {
    anglersApi
      .listAnglersGroups()
      .then((groups) => {
        setAllAnglersGroups(groups)
      })
      .catch((err) => {})
  }, [])
  const anglersGroupsOptions: ECSelectOption[] = React.useMemo(() => {
    return allAnglersGroups.map((elt) => ({
      value: elt.id,
      title: elt.name,
    }))
  }, [allAnglersGroups])

  const formElementMargin: ECSpacable = { vertical: 's' }
  const textInputDimension: ECDimensionProps = { width: 100 }

  return (
    <ECForm onSubmit={onSubmit} className="ecl-u-mh-auto">
      <ECColumn col={{ lg: 6 }} offset={{ lg: 3 }}>
        <ECSelect
          id="countryOfResidence"
          name="countryOfResidence"
          ref={register}
          label={t('register.countryOfResidence.label')}
          placeholder={t('register.countryOfResidence.placeholder')}
          isRequired
          options={countriesOptions}
          margin={formElementMargin}
          isDisabled={loading}
        />
        <ECTextInput
          id="zipCode"
          name="zipCode"
          label={t('register.zipCode.label')}
          placeholder={t('register.zipCode.label')}
          ref={register}
          isRequired
          error={errors.zipCode}
          margin={formElementMargin}
          width={textInputDimension}
          isDisabled={loading}
          inputProps={{ autoCapitalize: 'off', autoCorrect: 'off' }}
        />
        <ECSelectMulti
          id="anglersGroups"
          name="anglersGroups"
          label={t('register.anglersGroups.label')}
          placeholder={t('register.anglersGroups.placeholder')}
          options={anglersGroupsOptions}
          forcePlaceholder
          initialValues={angler?.anglersGroups?.map((elt) => elt.id)}
          onChange={(values) => setValue('anglersGroups', values)}
          width="l"
        />
        <ECSelect
          id="ownsBoat"
          error={errors.ownsBoat}
          name="ownsBoat"
          ref={register}
          label={t('register.ownsBoat.label')}
          placeholder={t('register.ownsBoat.placeholder')}
          isRequired
          options={ownsBoatOptions}
          margin={formElementMargin}
          isDisabled={loading}
        />
        {ownsBoat === 'true' ? (
          <ECSelect
            id="boatCountry"
            name="boatCountry"
            error={errors.boatCountry}
            ref={register}
            label={t('register.boatCountry.label')}
            placeholder={t('register.boatCountry.placeholder')}
            isRequired={ownsBoat === 'true'}
            options={countriesOptions}
            margin={formElementMargin}
            isDisabled={loading}
          />
        ) : undefined}
        {errorCreatingUser && (
          <ECRow margin={{ top: '2xl' }}>
            <ECFormErrorMessage id="error-creating-user" error={{ message: errorCreatingUser }} />
          </ECRow>
        )}
        <ECRow margin={{ top: '2xl' }}>
          <div className="ecl-u-mh-auto">
            {loading ? (
              <Spinner />
            ) : (
              <ECButton isSubmit type="primary" text={t('profile.editForm.submit')} dimensions={textInputDimension} />
            )}
          </div>
        </ECRow>
      </ECColumn>
    </ECForm>
  )
}
