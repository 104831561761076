import React from 'react'

import { useDispatch } from 'react-redux'

import {
  useDashboardApiAggregatedData,
  useDashboardApiDistributionData,
  useDashboardApiMapData,
  useDashboardApiTimeSeries,
} from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECColumn, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECStack } from '../../../../components/EC/utilities/ECStack'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useBackOfficeTranslate, useFishCatchesTranslate } from '../../../../i18n'
import { useLatestSavedDashboardConfiguration } from '../../../../store/reducers/dashboardConfigurationReducer'
import { SetFiltersActionCreator, useLatestSavedFilters } from '../../../../store/reducers/filtersReducer'
import { ECWebtoolElement } from '../../components/ECWebtoolElement'
import { Filters } from '../../indicators/FilterSection'
import { FilterSectionV2 } from '../../indicators/FilterSectionV2'
import {
  getTimeSeriesLineChartData,
  getPieChartData,
  getMapData,
  getCandlestickChartData,
  getSizeWeightChartData,
  getSizeDistributionChartData,
} from './dashboard-chart-utils'
import { DashboardConfigurationOverlay } from './DashboardConfigurationOverlay'

export const Dashboard = () => {
  const t = useBackOfficeTranslate()
  const ta = useFishCatchesTranslate()
  const dispatch = useDispatch()

  const latestUsedFilters = useLatestSavedFilters()
  const [filters, setFilters] = React.useState<Filters>(latestUsedFilters || {})

  const dashboardConfiguration = useLatestSavedDashboardConfiguration()
  const [overlayShow, setOverlayShow] = React.useState(false)
  const onCloseClick = () => setOverlayShow(false)
  const dashboardConfigurationIsEmpty = React.useMemo(() => {
    return Object.values(dashboardConfiguration ?? {}).filter((elt) => !!elt).length === 0
  }, [dashboardConfiguration])

  const aggregatedData = useDashboardApiAggregatedData({ filters })
  const timeSeries = useDashboardApiTimeSeries({ filters })
  const mapData = useDashboardApiMapData({ filters })
  const distributionData = useDashboardApiDistributionData({ filters })

  const [catchesTimeSeriesSelectedTabId, setCatchesTimeSeriesSelectedTabId] = React.useState('catches')
  const [selectedMapTabId, setSelectedMapTabId] = React.useState('catches')
  const [selectedDistributionTabId, setSelectedDistributionTabId] = React.useState<string>()

  const onChangeFilters = React.useCallback(
    (newFilters: Filters) => {
      dispatch(SetFiltersActionCreator(newFilters))
      setFilters(newFilters)
    },
    [dispatch],
  )

  const timeSeriesCatches = React.useMemo(() => {
    if (!timeSeries.data?.catches) {
      return undefined
    }
    return getTimeSeriesLineChartData({
      title: t('dashboard.timeSeries.catches.title'),
      categories: timeSeries.data.catches.months,
      series: timeSeries.data.catches.series.map((elt) => ({
        name: `${elt.speciesScientificName} (${elt.speciesFAOCode})`,
        data: elt.data.map((elt) => elt.count),
      })),
    })
  }, [timeSeries.data?.catches, t])

  const timeSeriesCatchesKept = React.useMemo(() => {
    if (!timeSeries.data?.catchesKept) {
      return undefined
    }
    return getTimeSeriesLineChartData({
      title: t('dashboard.timeSeries.keptCatches.title'),
      categories: timeSeries.data.catchesKept.months,
      series: timeSeries.data.catchesKept.series.map((elt) => ({
        name: `${elt.speciesScientificName} (${elt.speciesFAOCode})`,
        data: elt.data.map((elt) => elt.count),
      })),
    })
  }, [timeSeries.data?.catchesKept, t])

  const timeSeriesAnglers = React.useMemo(() => {
    if (!timeSeries.data?.anglers) {
      return undefined
    }
    return getTimeSeriesLineChartData({
      title: t('dashboard.timeSeries.anglers.title'),
      categories: timeSeries.data.anglers.months,
      series: timeSeries.data.anglers.series.map((elt) => ({
        name: `${elt.speciesScientificName} (${elt.speciesFAOCode})`,
        data: elt.data.map((elt) => elt.count),
      })),
    })
  }, [timeSeries.data?.anglers, t])

  const byFishingMethodWebtoolData = React.useMemo(() => {
    if (!aggregatedData.data?.activityPercentage.byFishingMethods) {
      return undefined
    }
    return getPieChartData({
      title: t('dashboard.pies.byFishingMode.title'),
      seriesName: t('dashboard.pies.catches'),
      data: aggregatedData.data.activityPercentage.byFishingMethods.map((item) => ({
        y: item.count,
        name: item.fishingMethodName,
      })),
    })
  }, [aggregatedData.data?.activityPercentage.byFishingMethods, t])

  const byFishingTypeWebtoolData = React.useMemo(() => {
    if (!aggregatedData.data?.activityPercentage.byFishingTypes) {
      return undefined
    }
    return getPieChartData({
      title: t('dashboard.pies.byFishingType.title'),
      seriesName: t('dashboard.pies.catches'),
      data: aggregatedData.data.activityPercentage.byFishingTypes.map((item) => ({
        y: item.count,
        name: item.fishingType?.length ? ta(`specs.fishingMode.${item.fishingType}`) : ta('specs.fishingMode.unknown'),
      })),
    })
  }, [aggregatedData.data?.activityPercentage.byFishingTypes, t, ta])

  const byClientTypeWebtoolData = React.useMemo(() => {
    if (!aggregatedData.data?.activityPercentage.byClient) {
      return undefined
    }
    return getPieChartData({
      title: t('dashboard.pies.byClient.title'),
      seriesName: t('dashboard.pies.catches'),
      data: aggregatedData.data.activityPercentage.byClient.map((item) => ({
        y: item.count,
        name: item.clientName,
      })),
    })
  }, [aggregatedData.data?.activityPercentage.byClient, t])

  const catchesMapData = React.useMemo(() => {
    if (!mapData.data?.catches) {
      return undefined
    }
    return getMapData({
      mapData: mapData.data.catches,
      tooltipText: t('mapData.catches'),
    })
  }, [mapData.data?.catches, t])

  const anglersMapData = React.useMemo(() => {
    if (!mapData.data?.anglers) {
      return undefined
    }
    return getMapData({
      mapData: mapData.data.anglers,
      tooltipText: t('mapData.anglers'),
    })
  }, [mapData.data?.anglers, t])

  const pieStyle = React.useMemo(() => {
    const pieCount = dashboardConfigurationIsEmpty
      ? 3
      : [
          dashboardConfiguration?.percentCatchesByClient,
          dashboardConfiguration?.percentCatchesByFishingMethod,
          dashboardConfiguration?.percentCatchesByFishingTypes,
        ].filter(Boolean).length
    return { width: `${Math.floor(100 / pieCount)}%`, margin: '0 auto' }
  }, [dashboardConfigurationIsEmpty, dashboardConfiguration])

  const timeSeriesStyle = React.useMemo(() => {
    return { width: '100%' }
  }, [])

  const mapStyle = React.useMemo(() => {
    return { width: '100%', height: 600 }
  }, [])

  const distributionStyle = React.useMemo(() => {
    return { width: '100%' }
  }, [])

  const distributionDataTabs = React.useMemo(() => {
    return (
      distributionData.data?.candlesData?.series.map((elt) => ({
        id: elt.speciesId,
        title: `<i>${elt.speciesScientificName}</i> (${elt.speciesFAOCode})`,
      })) ?? []
    )
  }, [distributionData.data?.candlesData])

  const candleStickDistributionDataBySpeciesId = React.useMemo(() => {
    if (!distributionData.data?.candlesData) {
      return undefined
    }
    return distributionData.data.candlesData.series.reduce((acc: any, elt) => {
      acc[elt.speciesId] = getCandlestickChartData({
        candleData: distributionData.data.candlesData,
        speciesId: elt.speciesId,
        title: t('dashboard.distribution.candlestick.title'),
        yAxisTitle: t('dashboard.distribution.candlestick.yAxisTitle'),
      })
      return acc
    }, {})
  }, [distributionData.data?.candlesData, t])

  const sizeVsWeightDistributionDataBySpeciesId = React.useMemo(() => {
    if (!distributionData.data?.sizeVsWeight) {
      return undefined
    }
    return distributionData.data.sizeVsWeight.reduce((acc: any, elt) => {
      acc[elt.speciesId] = getSizeWeightChartData({
        sizeVsWeight: distributionData.data.sizeVsWeight,
        speciesId: elt.speciesId,
        title: t('dashboard.distribution.sizeWeight.title'),
        yAxisTitle: t('dashboard.distribution.sizeWeight.yAxisTitle'),
        xAxisTitle: t('dashboard.distribution.sizeWeight.xAxisTitle'),
      })
      return acc
    }, {})
  }, [distributionData.data?.sizeVsWeight, t])

  const sizeDistributionDataBySpeciesId = React.useMemo(() => {
    if (!distributionData.data?.sizeDistributionKept || !distributionData.data?.sizeDistributionReleased) {
      return undefined
    }
    return distributionData.data.sizeDistributionKept.reduce((acc: any, elt) => {
      acc[elt.speciesId] = getSizeDistributionChartData({
        sizeDistributionKept: distributionData.data.sizeDistributionKept,
        sizeDistributionReleased: distributionData.data.sizeDistributionReleased,
        speciesId: elt.speciesId,
        title: t('dashboard.distribution.size.title'),
        yAxisTitle: t('dashboard.distribution.size.yAxisTitle'),
        xAxisTitle: t('dashboard.distribution.size.xAxisTitle'),
        releasedTitle: t('dashboard.distribution.size.releasedTitle'),
        keptTitle: t('dashboard.distribution.size.keptTitle'),
      })
      return acc
    }, {})
  }, [distributionData.data?.sizeDistributionKept, distributionData.data?.sizeDistributionReleased, t])

  const onClickConfigure = React.useCallback(() => {
    setOverlayShow(true)
  }, [])

  return (
    <>
      <ECColumn col={{ all: 12 }}>
        <ECStack horizontalAlignment="end" margin={{ bottom: 'm' }} style={{ marginTop: -60 }}>
          <ECButton type="primary" text={t('dashboard.configure.button')} onClick={onClickConfigure}></ECButton>
        </ECStack>
        <ECRow>
          <FilterSectionV2 value={filters} onChange={onChangeFilters} />
        </ECRow>
        <ECRow margin={{ top: 'l' }}>
          <ECTitle level={2}>{t('dashboard.activity.title')}</ECTitle>
        </ECRow>
        {dashboardConfigurationIsEmpty || dashboardConfiguration?.keyFigures ? (
          <>
            <div className="ecl-fact-figures ecl-fact-figures--col-12">
              <div className="ecl-fact-figures__items" style={{ justifyContent: 'space-evenly' }}>
                <ECStatItem
                  title={t('dashboard.activity.anglersCount')}
                  value={aggregatedData?.data?.aggregData?.anglersCount}
                />
                <ECStatItem
                  title={t('dashboard.activity.catchesCount')}
                  value={aggregatedData?.data?.aggregData?.catchesCount}
                />
                <ECStatItem
                  title={t('dashboard.activity.keptCatchesCount')}
                  value={aggregatedData?.data?.aggregData?.catchesKeptCount}
                />
                <ECStatItem
                  title={t('dashboard.activity.releasedCatchesCount')}
                  value={aggregatedData?.data?.aggregData?.catchesReleasedCount}
                />
              </div>
            </div>
          </>
        ) : undefined}
        {dashboardConfigurationIsEmpty || dashboardConfiguration?.trends ? (
          <>
            <ECRow margin={{ top: 'l' }}>
              <TabbedContent
                activeId={catchesTimeSeriesSelectedTabId ?? 'catches'}
                tabs={[
                  { id: 'anglers', title: t('dashboard.timeSeries.tabs.anglers') },
                  { id: 'catches', title: t('dashboard.timeSeries.tabs.catches') },
                  { id: 'keptCatches', title: t('dashboard.timeSeries.tabs.keptCatches') },
                ]}
                onSelectTab={(id) => setCatchesTimeSeriesSelectedTabId(id)}
                renderContent={(id) => (
                  <div style={timeSeriesStyle}>
                    {timeSeriesCatches && (
                      <div style={{ ...timeSeriesStyle, display: id === 'catches' ? 'block' : 'none' }}>
                        <ECWebtoolElement style={timeSeriesStyle} loadData={async () => timeSeriesCatches} />
                      </div>
                    )}
                    {timeSeriesCatchesKept && (
                      <div style={{ ...timeSeriesStyle, display: id === 'keptCatches' ? 'block' : 'none' }}>
                        <ECWebtoolElement style={timeSeriesStyle} loadData={async () => timeSeriesCatchesKept} />
                      </div>
                    )}
                    {timeSeriesAnglers && (
                      <div style={{ ...timeSeriesStyle, display: id === 'anglers' ? 'block' : 'none' }}>
                        <ECWebtoolElement style={timeSeriesStyle} loadData={async () => timeSeriesAnglers} />
                      </div>
                    )}
                  </div>
                )}
              />
            </ECRow>
          </>
        ) : undefined}
        <ECRow margin={{ top: 'xl' }}>
          {(dashboardConfigurationIsEmpty || dashboardConfiguration?.percentCatchesByFishingMethod) &&
            byFishingMethodWebtoolData && (
              <ECWebtoolElement style={pieStyle} loadData={async () => byFishingMethodWebtoolData} />
            )}
          {(dashboardConfigurationIsEmpty || dashboardConfiguration?.percentCatchesByFishingTypes) &&
            byFishingTypeWebtoolData && (
              <ECWebtoolElement style={pieStyle} loadData={async () => byFishingTypeWebtoolData} />
            )}
          {(dashboardConfigurationIsEmpty || dashboardConfiguration?.percentCatchesByClient) &&
            byClientTypeWebtoolData && (
              <ECWebtoolElement style={pieStyle} loadData={async () => byClientTypeWebtoolData} />
            )}
        </ECRow>
        {dashboardConfigurationIsEmpty || dashboardConfiguration?.activityMapForSelectedSpecies ? (
          <>
            <ECRow margin={{ top: 'l' }}>
              <ECTitle level={2}>{t('dashboard.map.title')}</ECTitle>
            </ECRow>
            <ECRow>
              <TabbedContent
                activeId={selectedMapTabId ?? 'catches'}
                tabs={[
                  { id: 'catches', title: t('mapData.catches') },
                  { id: 'anglers', title: t('mapData.anglers') },
                ]}
                onSelectTab={(id) => setSelectedMapTabId(id)}
                renderContent={(id) => (
                  <div>
                    {catchesMapData && (
                      <div style={{ ...mapStyle, display: id === 'catches' ? 'block' : 'none' }}>
                        <ECWebtoolElement style={mapStyle} loadData={async () => catchesMapData} />
                      </div>
                    )}
                    {anglersMapData && (
                      <div style={{ ...mapStyle, display: id === 'anglers' ? 'block' : 'none' }}>
                        <ECWebtoolElement style={mapStyle} loadData={async () => anglersMapData} />
                      </div>
                    )}
                  </div>
                )}
              />
            </ECRow>
          </>
        ) : undefined}
        {dashboardConfigurationIsEmpty ||
        dashboardConfiguration?.trendsInSizeAndWeight ||
        dashboardConfiguration?.sizeAndWeightDistribution ? (
          <>
            <ECRow margin={{ top: 'l' }}>
              <ECTitle level={2}>{t('dashboard.distribution.title')}</ECTitle>
            </ECRow>
            <ECRow>
              <TabbedContent
                activeId={selectedDistributionTabId ?? distributionDataTabs[0]?.id}
                tabs={distributionDataTabs}
                onSelectTab={(id) => setSelectedDistributionTabId(id)}
                renderContent={(id) => (
                  <ECColumn col={{ all: 12 }}>
                    {distributionData &&
                      distributionDataTabs.map((tab) => (
                        <React.Fragment key={`tab-content-${tab.id}`}>
                          <div style={{ display: tab.id === id ? 'block' : 'none' }}>
                            {dashboardConfigurationIsEmpty || dashboardConfiguration?.trendsInSizeAndWeight ? (
                              <>
                                {candleStickDistributionDataBySpeciesId[tab.id] && (
                                  <>
                                    <ECRow>
                                      <div style={{ ...distributionStyle, display: id === tab.id ? 'block' : 'none' }}>
                                        <ECWebtoolElement
                                          style={distributionStyle}
                                          loadData={async () => candleStickDistributionDataBySpeciesId[tab.id]}
                                        />
                                      </div>
                                    </ECRow>
                                    <div style={{ height: 24 }} />
                                  </>
                                )}
                              </>
                            ) : undefined}
                          </div>
                          {dashboardConfigurationIsEmpty || dashboardConfiguration?.sizeAndWeightDistribution ? (
                            <div style={{ display: tab.id === id ? 'block' : 'none' }}>
                              <ECRow>
                                {sizeVsWeightDistributionDataBySpeciesId[tab.id] && (
                                  <ECColumn col={{ md: 12, lg: 8 }} className="ecl-u-pr-lg-xl">
                                    <ECRow>
                                      <div style={{ ...distributionStyle, display: id === tab.id ? 'block' : 'none' }}>
                                        <ECWebtoolElement
                                          style={distributionStyle}
                                          loadData={async () => sizeVsWeightDistributionDataBySpeciesId[tab.id]}
                                        />
                                      </div>
                                    </ECRow>
                                    <div style={{ height: 24 }} />
                                  </ECColumn>
                                )}
                                {sizeDistributionDataBySpeciesId[tab.id] && (
                                  <ECColumn col={{ md: 12, lg: 4 }}>
                                    <ECRow>
                                      <div style={{ ...distributionStyle, display: id === tab.id ? 'block' : 'none' }}>
                                        <ECWebtoolElement
                                          style={distributionStyle}
                                          loadData={async () => sizeDistributionDataBySpeciesId[tab.id]}
                                        />
                                      </div>
                                    </ECRow>
                                  </ECColumn>
                                )}
                              </ECRow>
                            </div>
                          ) : undefined}
                        </React.Fragment>
                      ))}
                  </ECColumn>
                )}
              />
            </ECRow>
          </>
        ) : undefined}
      </ECColumn>
      <DashboardConfigurationOverlay isShown={overlayShow} onClose={onCloseClick} />
    </>
  )
}

const ECStatItem = ({ title, value }: { title: string; value?: string | number }) => {
  const valueDisplay = React.useMemo(() => {
    if (typeof value === 'number') {
      if (value >= 1000) {
        return `${(value / 1000).toFixed(1)}k`
      }
      if (value >= 1000000) {
        return `${(value / 1000000).toFixed(1)}M`
      }
    }
    return value
  }, [value])
  return (
    <div className="ecl-fact-figures__item">
      <div className="ecl-fact-figures__value">{valueDisplay ?? '--'}</div>
      <div className="ecl-fact-figures__title">{title}</div>
    </div>
  )
}

const TabbedContent = ({
  activeId,
  tabs,
  onSelectTab,
  renderContent,
}: {
  activeId: string
  tabs: { id: string; title: string }[]
  onSelectTab: (activeTabId: string) => void
  renderContent: (activeTabId: string) => React.ReactNode
}) => {
  return (
    <div style={{ width: '100%' }}>
      <ul
        style={{
          display: 'flex',
          listStyle: 'none',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          paddingInlineStart: 20,
          overflowX: 'auto',
          position: 'relative',
        }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: '1px solid lightgray',
          }}
        />
        {tabs.map((tab) => (
          <li key={tab.id} style={{ zIndex: 9 }}>
            <a
              style={{
                color: tab.id === activeId ? '#004494' : 'gray',
                fontWeight: 'bold',
                textDecoration: 'none',
                padding: '8px 16px',
                borderBottom: '1px solid',
                backgroundColor: tab.id === activeId ? 'white' : 'transparent',
                borderBottomColor: tab.id !== activeId ? 'transparent' : 'white',
                borderLeft: '1px solid',
                borderLeftColor: tab.id === activeId ? 'lightgray' : 'white',
                borderRight: '1px solid',
                borderRightColor: tab.id === activeId ? 'lightgray' : 'white',
                borderTop: '3px solid',
                borderTopColor: tab.id === activeId ? '#004494' : 'white',
                whiteSpace: 'nowrap',
                display: 'block',
              }}
              href={`#${tab.id}`}
              onClick={(e) => {
                e.preventDefault()
                onSelectTab(tab.id)
              }}
              dangerouslySetInnerHTML={{ __html: tab.title }}></a>
          </li>
        ))}
      </ul>
      <div className="tab_content">{renderContent(activeId)}</div>
    </div>
  )
}
