import React from 'react'

import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECCheckBox } from '../../../../components/EC/forms/ECCheckBox'
import { ECForm, ECFormErrorMessage } from '../../../../components/EC/forms/ECForm'
import { ECFormGroup } from '../../../../components/EC/forms/ECFormGroup'
import { ECTextInput } from '../../../../components/EC/forms/ECTextInput'
import { ECColumn, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable } from '../../../../components/EC/utilities/ECSpacable'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../../i18n'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'
import { useScopesData } from '../../../../utils/hooks/useScopesData'
import { ScopeGroup } from '../../common/ScopeGroup'
import { RoleEditForm } from '../../components/RoleEditForm'
import { SelectUserActiveStatus, UserActiveStatusValue } from '../../management/SelectUserActiveStatus'

export const UserCreatePage = () => {
  const t = useBackOfficeTranslate()
  const history = useHistory()

  const currentUser = useBackOfficeUserSelector()
  const [loading, setLoading] = React.useState(false)
  const [errorCreatingUser, setErrorCreatingUser] = React.useState(false)

  const { scopes, fishSpecies, geographicalAreas, anglersGroups } = useScopesData()

  const validationSchema = React.useMemo(() => {
    return yup.object({
      firstName: yup.string().min(1).required(),
      lastName: yup.string().min(1).required(),
      institution: yup.string().min(1).required(),
      email: yup.string().email(t('users.createForm.email.error')).required(),
      activationStatus: yup.string().oneOf(['active', 'activeUntil', 'inactive']).required(),
      isActiveUntil: yup.date(),
      acceptTOUs: yup.bool().required().oneOf([true]),
      role: yup.object({
        role: yup
          .string()
          .required()
          .oneOf(currentUser?.usableRole?.canCreateUsersWithRoles ?? []),
      }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, errors, watch, setValue } = useForm<
    CreateBackOfficeUserRequest & { acceptTOUs: boolean }
  >({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      institution: '',
      activationStatus: 'inactive',
      isActiveUntil: undefined,
      fishSpeciesScope: { isAll: false, selected: [] },
      geographicalAreasScope: { isAll: false, selected: [] },
      anglersGroupsScope: { isAll: false, selected: [] },
      role: {},
      acceptTOUs: false,
    },
    validationSchema,
    mode: 'onBlur',
  })

  React.useEffect(() => {
    register({ name: 'fishSpeciesScope' })
    register({ name: 'geographicalAreasScope' })
    register({ name: 'anglersGroupsScope' })
    register({ name: 'activationStatus' })
    register({ name: 'isActiveUntil' })
    register({ name: 'role' })
  }, [register])

  const acceptTOUs = watch('acceptTOUs')

  const roleValues = watch('role')
  const onChangeRoleValues = React.useCallback(
    (newValues: EditRoleValues) => {
      setValue('role', newValues)
    },
    [setValue],
  )

  const activationStatus = watch('activationStatus')
  const isActiveUntil = watch('isActiveUntil')
  const fishSpeciesScope = watch('fishSpeciesScope')
  const geographicalAreasScope = watch('geographicalAreasScope')
  const anglersGroupsScope = watch('anglersGroupsScope')

  const onChangeSpeciesScope = React.useCallback(
    (newScope: Scope) => {
      setValue('fishSpeciesScope', newScope)
    },
    [setValue],
  )

  const onChangeGeographicalAreasScope = React.useCallback(
    (newScope: Scope) => {
      setValue('geographicalAreasScope', newScope)
    },
    [setValue],
  )

  const onChangeAnglersGroupsScope = React.useCallback(
    (newScope: Scope) => {
      setValue('anglersGroupsScope', newScope)
    },
    [setValue],
  )

  const onChangeActivationStatus = React.useCallback(
    (newValue: UserActiveStatusValue) => {
      setValue('activationStatus', newValue.activationStatus)
      setValue('isActiveUntil', newValue.isActiveUntil)
    },
    [setValue],
  )

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true)
    setErrorCreatingUser(false)
    try {
      const newUser = (await backOfficeApi.createUser({ ...values })).data
      history.push(`/backOfficeUsers/${newUser.id}`)
    } catch (err) {
      setErrorCreatingUser(true)
      setLoading(false)
    }
  })

  const sectionsMargin: ECSpacable = { top: 'l' }
  const interlineMargin: ECSpacable = { top: 'm' }

  if (!scopes) {
    return <Spinner />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      <ECTitle level={1} isPageTitle>
        {t('users.create')}
      </ECTitle>
      <ECForm onSubmit={onSubmit}>
        <ECFormGroup id="general" legend={t('users.createForm.identificationSection')} margin={sectionsMargin}>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="firstName"
                name="firstName"
                label={t('users.specs.firstName')}
                ref={register}
                error={errors.firstName}
                inputWidth="l"
                isRequired
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
          <ECRow>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="lastName"
                name="lastName"
                label={t('users.specs.lastName')}
                ref={register}
                error={errors.lastName}
                inputWidth="l"
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
          <ECRow margin={interlineMargin}>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="institution"
                name="institution"
                label={t('users.specs.institution')}
                ref={register}
                error={errors.institution}
                inputWidth="l"
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
          <ECRow margin={interlineMargin}>
            <ECColumn col={{ all: 12 }}>
              <ECTextInput
                id="email"
                name="email"
                label={t('users.specs.email')}
                ref={register}
                error={errors.email}
                inputWidth="l"
                isDisabled={loading}
              />
            </ECColumn>
          </ECRow>
        </ECFormGroup>
        <RoleEditForm values={roleValues} onChange={onChangeRoleValues} hasError={!!errors.role} />
        <ScopeGroup
          name="fishSpecies"
          margin={sectionsMargin}
          value={fishSpeciesScope}
          options={fishSpecies}
          onChange={onChangeSpeciesScope}
          isAllAvailable={scopes.fishSpecies.isAll}
          legend={t('users.createForm.scopes.fishSpecies.title')}
          labelSelectAll={t('users.createForm.scopes.fishSpecies.all.true')}
          labelSelectNotAll={t('users.createForm.scopes.fishSpecies.all.false')}
          placeholder={t('users.createForm.scopes.fishSpecies.select')}
          isDisabled={loading}
        />
        <ScopeGroup
          name="geographicalAreas"
          margin={sectionsMargin}
          value={geographicalAreasScope}
          options={geographicalAreas}
          onChange={onChangeGeographicalAreasScope}
          isAllAvailable={scopes.geographicalAreas.isAll}
          legend={t('users.createForm.scopes.geographicalAreas.title')}
          labelSelectAll={t('users.createForm.scopes.geographicalAreas.all.true')}
          labelSelectNotAll={t('users.createForm.scopes.geographicalAreas.all.false')}
          placeholder={t('users.createForm.scopes.geographicalAreas.select')}
          isDisabled={loading}
        />
        <ScopeGroup
          name="anglersGroups"
          margin={sectionsMargin}
          value={anglersGroupsScope}
          options={anglersGroups}
          onChange={onChangeAnglersGroupsScope}
          isAllAvailable={scopes.anglersGroups.isAll}
          legend={t('users.createForm.scopes.anglersGroups.title')}
          labelSelectAll={t('users.createForm.scopes.anglersGroups.all.true')}
          labelSelectNotAll={t('users.createForm.scopes.anglersGroups.all.false')}
          placeholder={t('users.createForm.scopes.anglersGroups.select')}
          isDisabled={loading}
        />
        <SelectUserActiveStatus
          sectionsMargin={sectionsMargin}
          value={{
            activationStatus,
            isActiveUntil,
          }}
          onChange={onChangeActivationStatus}
          isDisabled={loading}
        />
        <ECRow margin={sectionsMargin} className="align_center">
          <ECCheckBox
            id="acceptTOUs-checkbox"
            name="acceptTOUs"
            className="information-checkbox"
            isInvalid={!!errors.acceptTOUs}
            isChecked={acceptTOUs}
            ref={register}
            onClick={() => setValue('acceptTOUs', !acceptTOUs)}>
            <ECText>{t('users.createForm.confirm')}</ECText>
          </ECCheckBox>
        </ECRow>
        {errorCreatingUser ? (
          <div className="ecl-u-mh-auto">
            <ECFormErrorMessage id="login-error" error={{ message: t('users.createForm.error') }} />
          </div>
        ) : undefined}
        <ECRow margin={sectionsMargin} className="align_center">
          <ECColumn col={{ all: 12, lg: 4 }}>
            {loading ? (
              <Spinner />
            ) : (
              <ECButton isSubmit type="primary" text={t('users.createForm.submitButton')} dimensions={{ width: 100 }} />
            )}
          </ECColumn>
        </ECRow>
      </ECForm>
    </ECColumn>
  )
}
