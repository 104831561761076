import React from 'react'

import { useCommonTranslate } from '../i18n'
import { ECFooter } from './EC/core/ECFooter'
import { ECLinkProps } from './EC/navigation/ECLink'

export interface FooterProps {
  additionalMoreLinks?: Array<ECLinkProps>
  additionalServiceLinks?: Array<ECLinkProps>
  additionalLegalLinks?: Array<ECLinkProps>
}

export const Footer: React.FC<FooterProps> = ({
  additionalMoreLinks,
  additionalServiceLinks,
  additionalLegalLinks,
}) => {
  const t = useCommonTranslate()

  const moreLinks =
    window._env_.REACT_APP_RCSB_TYPE === 'admin'
      ? [
          {
            title: t('footer.more.fishersPortal'),
            href: window.location.href.includes('staging')
              ? 'https://recreational-fishing.test.ec.europa.eu/'
              : 'https://recreational-fishing.ec.europa.eu/',
          },
        ]
      : [
          { title: t('footer.more.detail-program'), path: '/how' },
          { title: t('footer.more.certified-apps'), path: '/apps' },
          { title: t('footer.more.recreational-fishing-legislation'), path: '/legislation' },
          ...(additionalMoreLinks ?? []),
        ]
  const serviceLinks =
    window._env_.REACT_APP_RCSB_TYPE === 'admin'
      ? []
      : [
          { title: t('footer.more.detail-data'), path: '/specific-privacy-statement' },
          ...(additionalServiceLinks ?? []),
        ]
  const legalLinks = additionalLegalLinks ?? []

  return <ECFooter margin={{ top: '4xl' }} moreLinks={moreLinks} serviceLinks={serviceLinks} legalLinks={legalLinks} />
}
