import React from 'react'

import { trackDownloadClick } from '../services/analytics-service'

export interface DownloadLinkProps {
  filePath: string
}

export const DownloadLink: React.FC<DownloadLinkProps> = ({ filePath, children }) => {
  return (
    <a
      href={filePath}
      onClick={() => {
        trackDownloadClick(filePath)
      }}
      target="_blank"
      rel="noopener noreferrer"
      download>
      {children}
    </a>
  )
}
