import React from 'react'

import { useQueryParams } from '../../../../utils/hooks/useQueryParams'
import { ResetPasswordRequestForm } from '../../components/ResetPasswordRequestForm'
import { ResetPasswordFill } from '../../components/RestPasswordFill'

export const ForgotPasswordPage: React.FC = () => {
  const queryParams = useQueryParams()

  const tok = queryParams.get('tok')
  if (tok) {
    return <ResetPasswordFill token={tok} />
  }
  return <ResetPasswordRequestForm />
}
