import React from 'react'

import { useTranslation } from 'react-i18next'

import { anglersApi } from '../../../../api/anglersApi'
import { ECContainer } from '../../../../components/EC/ECContainer'
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHeader,
  ECTableHeaderCell,
  ECTableRow,
} from '../../../../components/EC/ECTable'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useCommonTranslate } from '../../../../i18n'

const HEADERS = ['fao', 'scientificName', 'name']

export const HowPage: React.FC = () => {
  const tc = useCommonTranslate()
  const { t } = useTranslation('static-how')

  const [fishSpecies, setFishSpecies] = React.useState<FishSpecies[]>([])

  React.useEffect(() => {
    anglersApi.listFishSpecies().then((res) => {
      setFishSpecies(res.sort((a, b) => a.scientificName.localeCompare(b.scientificName)))
    })
  }, [])

  return (
    <ECContainer>
      <ECTitle isPageTitle>{tc('footer.more.detail-program')}</ECTitle>
      <ECText>{t('welcome')}</ECText>
      <ECText>{t('introduction')}</ECText>
      <ECText>{t('anglerId')}</ECText>
      <ECText>{t('history')}</ECText>
      <ECText>{t('data')}</ECText>
      <ECText>{t('participation')}</ECText>
      <hr
        style={{
          marginTop: 40,
          marginBottom: 20,
          borderTop: '1px solid #eee',
        }}
      />
      <ECTitle>{t('concernedSpecies.title')}</ECTitle>
      <ECText>{t('concernedSpecies.introduction')}</ECText>
      <ECTable>
        <ECTableHeader>
          <ECTableRow>
            {HEADERS.map((value: string) => {
              return <ECTableHeaderCell key={value}>{t(`concernedSpecies.headers.${value}`)}</ECTableHeaderCell>
            })}
          </ECTableRow>
        </ECTableHeader>
        <ECTableBody>
          {fishSpecies.map((item) => {
            return (
              <ECTableRow key={item.id}>
                <ECTableCell headerTitle={t(`concernedSpecies.headers.${HEADERS[0]}`)}>{item.faoCode}</ECTableCell>
                <ECTableCell headerTitle={t(`concernedSpecies.headers.${HEADERS[1]}`)}>
                  {item.scientificName}
                </ECTableCell>
                <ECTableCell headerTitle={t(`concernedSpecies.headers.${HEADERS[2]}`)}>
                  {item.name_locale ?? item.name}
                </ECTableCell>
              </ECTableRow>
            )
          })}
        </ECTableBody>
      </ECTable>
    </ECContainer>
  )
}
