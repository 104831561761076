import React from 'react'

export interface ScrollSpyedComponentProps {
  id: string
  onViewportVisibilityChange: (opts: { id: string; isInViewport: boolean }) => void
}

function useIsInViewport(ref: React.RefObject<Element>) {
  const [isIntersecting, setIsIntersecting] = React.useState(false)

  const observer = React.useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    [],
  )

  React.useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [ref, observer])

  return isIntersecting
}

export const ScrollSpyedComponent: React.FC<ScrollSpyedComponentProps> = ({
  id,
  children,
  onViewportVisibilityChange,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const isInViewport = useIsInViewport(ref)

  React.useEffect(() => {
    onViewportVisibilityChange({ id, isInViewport })
  }, [id, isInViewport, onViewportVisibilityChange])

  return <div ref={ref}>{children}</div>
}
