import React, { useMemo, useState } from 'react'

import classnames from 'classnames'
import moment from 'moment'

import { anglersApi } from '../../../../api/anglersApi'
import { ECPageBanner } from '../../../../components/EC/core/ECPageBanner'
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHeader,
  ECTableHeaderCell,
  ECTableRow,
} from '../../../../components/EC/ECTable'
import { ECPagination } from '../../../../components/EC/navigation/ECPagination'
import { ECColumn } from '../../../../components/EC/utilities/ECGrid'
import { useMarginClass } from '../../../../components/EC/utilities/ECSpacable'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useCommonTranslate, useFishCatchesTranslate } from '../../../../i18n'

const HEADERS = [
  'specs.date',
  'specs.species',
  'specs.size',
  'specs.weight',
  'specs.zone',
  'specs.releasedStatus.title',
  'specs.declarationTool',
  'specs.receiptId',
]

const PAGE_SIZE = 10
export const AnglerHistory: React.FC = () => {
  const tc = useCommonTranslate()
  const t = useFishCatchesTranslate()

  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState<FishCatchHistoryItem[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalCount, setTotalCount] = React.useState(0)

  const maxPage = useMemo(() => {
    return Math.floor(totalCount / PAGE_SIZE)
  }, [totalCount])

  const onPageSelected = React.useCallback((value: number) => {
    setCurrentPage(value)
  }, [])

  React.useEffect(() => {
    setLoading(true)
    const offset = currentPage * PAGE_SIZE
    anglersApi.getAnglersFishCatchesHistory({ offset }).then((results) => {
      setTotalCount(results.count)
      setRows(results.rows)
      setLoading(false)
    })
  }, [currentPage])

  const tableClass = classnames(useMarginClass({ top: 'l' }))

  if (loading) {
    return <Spinner />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      {rows.length === 0 ? (
        <div>
          <ECPageBanner
            background="blue"
            title={t('history.empty.title')}
            baseline={t('history.empty.baseline')}
            buttons={[{ title: tc('footer.more.certified-apps'), path: '/apps' }]}
          />
        </div>
      ) : (
        <div style={{ overflowX: 'scroll' }}>
          <ECTable className={tableClass}>
            <ECTableHeader>
              <ECTableRow>
                {HEADERS.map((value: string) => {
                  return <ECTableHeaderCell key={value}>{t(value)}</ECTableHeaderCell>
                })}
              </ECTableRow>
            </ECTableHeader>
            <ECTableBody>
              {rows.map((item) => {
                return (
                  <ECTableRow key={item.id}>
                    <ECTableCell headerTitle={t(HEADERS[0])}>
                      <span
                        dangerouslySetInnerHTML={{ __html: moment(item.date).format('ll').replace(/ /g, '&nbsp;') }}
                      />{' '}
                      <span
                        dangerouslySetInnerHTML={{ __html: moment(item.date).format('LT').replace(/ /g, '&nbsp;') }}
                      />
                    </ECTableCell>
                    <ECTableCell headerTitle={t(HEADERS[1])}>
                      {item.fishSpecies.name_lang ?? item.fishSpecies.name} ({item.fishSpecies.faoCode})
                    </ECTableCell>
                    <ECTableCell headerTitle={t(HEADERS[2])}>
                      {item.size ? `${Math.round(item.size * 100) / 100}cm` : ''}
                    </ECTableCell>
                    <ECTableCell headerTitle={t(HEADERS[3])}>
                      {item.weight ? `${Math.round(item.weight * 100) / 100}kg` : ''}
                    </ECTableCell>
                    <ECTableCell headerTitle={t(HEADERS[4])}>
                      {item.fishingZone ? `${item.fishingZone.type} ${item.fishingZone.code}` : ''}
                    </ECTableCell>
                    <ECTableCell headerTitle={t(HEADERS[5])}>
                      {t(`specs.releasedStatus.${item.released ? 'true' : 'false'}`)}
                    </ECTableCell>
                    <ECTableCell headerTitle={t(HEADERS[6])}>{item.client.name}</ECTableCell>
                    <ECTableCell headerTitle={t(HEADERS[7])}>{item.declarationNumber}</ECTableCell>
                  </ECTableRow>
                )
              })}
            </ECTableBody>
          </ECTable>
        </div>
      )}
      {maxPage > 0 && (
        <ECPagination
          dimensions={{ width: 100 }}
          margin={{ top: 'l' }}
          currentPage={currentPage}
          maxPage={maxPage}
          onClick={onPageSelected}
        />
      )}
    </ECColumn>
  )
}
