import React from 'react'

import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { ECPageBanner } from '../../../../components/EC/core/ECPageBanner'
import { ECContainer } from '../../../../components/EC/ECContainer'
import { ECText } from '../../../../components/EC/utilities/ECText'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { useAnglersTranslate, useCommonTranslate } from '../../../../i18n'
import { useAnglerSelector } from '../../../../store/reducers/user/reducer'

export const LandingPage = () => {
  const t = useAnglersTranslate()
  const angler = useAnglerSelector()
  const tc = useCommonTranslate()
  const { t: tsh } = useTranslation('static-how')

  if (angler) {
    return <Redirect to="/profile" />
  }

  return (
    <ECContainer>
      <ECPageBanner
        title="Recreational Fisheries (RecFishing)"
        baseline={t('landing.baseline')}
        buttons={[
          {
            title: t('landing.login'),
            path: '/login',
          },
          {
            title: t('landing.register'),
            path: '/register',
          },
        ]}
        background="blue"
      />
      <div style={{ height: 60 }} />
      <ECTitle isPageTitle>{tc('footer.more.detail-program')}</ECTitle>
      <ECText>{tsh('welcome')}</ECText>
      <ECText>{tsh('introduction')}</ECText>
      <ECText>{tsh('anglerId')}</ECText>
      <ECText>{tsh('history')}</ECText>
      <ECText>{tsh('data')}</ECText>
      <ECText>{tsh('participation')}</ECText>
    </ECContainer>
  )
}
