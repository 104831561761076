import React from 'react'

import { useDispatch } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'

import { ContentWithNav } from '../../../components/ContentWithNav'
import { ECNavItemProps } from '../../../components/EC/navigation/ECInpageNav'
import { useBackOfficeTranslate } from '../../../i18n'
import { logOutFromBackOffice } from '../../../services/back-office/back-office-login-service'
import { useBackOfficeUserSelector, useBackOfficeUserIsActiveSelector } from '../../../store/reducers/user/reducer'
import { ChangePasswordPage } from '../../anglers/pages/my-profile/ChangePasswordPage'
import { Dashboard } from './dashboard/Dashboard'
import { DataExports } from './data-export/DataExports'
import { GlobalIndicators } from './global-indicators/GlobalIndicators'
import { OAuthClientCreatePage } from './oauth-clients/OAuthClientCreatePage'
import { OAuthClientDetailsPage } from './oauth-clients/OAuthClientDetailsPage'
import { OAuthClientEditPage } from './oauth-clients/OAuthClientEditPage'
import { OAuthClientListPage } from './oauth-clients/OAuthClientListPage'
import { PrivateRoute } from './PrivateRoute'
import { UserCreatePage } from './users/UserCreatePage'
import { UserDetailsPage } from './users/UserDetailsPage'
import { UserEditPage } from './users/UserEditPage'
import { UserListPage } from './users/UserListPage'
import { UserMePage } from './users/UserMePage'

const showDashboard = window._env_.REACT_APP_SHOW_DASHBOARD === 'true'

export const RouterLoggedIn: React.FC = () => {
  const currentUser = useBackOfficeUserSelector()
  const currentUserIsActive = useBackOfficeUserIsActiveSelector()

  const dispatch = useDispatch()
  const t = useBackOfficeTranslate()

  const currentPath = useLocation().pathname

  const onLogOut = () => {
    logOutFromBackOffice(dispatch)
  }

  const navItems: ECNavItemProps[] = React.useMemo(() => {
    let all: ECNavItemProps[] = [
      {
        title: t('nav.userProfile'),
        path: '/backOfficeUsers/me',
        isActive: ['/backOfficeUsers/me'].includes(currentPath),
      },
    ]
    if (currentUserIsActive) {
      all.push({
        title: t('nav.globalIndicators'),
        path: '/data/indicators',
        isActive: currentPath === '/data/indicators',
      })
      if (showDashboard) {
        all.push({
          title: t('nav.dashboard'),
          path: '/data/dashboard',
          hideNavWhenActive: true,
          isActive: currentPath === '/data/dashboard',
        })
      }
      all.push({
        title: t('nav.dataExports'),
        path: '/data/exports',
        isActive: currentPath === '/data/exports',
      })
      if ((currentUser?.usableRole?.canCreateUsersWithRoles ?? []).length > 0) {
        all.push({
          title: t('nav.userManagement'),
          path: '/backOfficeUsers',
          isActive: !currentPath.startsWith('/backOfficeUsers/me') && currentPath.startsWith('/backOfficeUsers'),
        })
      }
      if (currentUser?.role?.type === 'ADMIN') {
        all.push({
          title: t('nav.oAuthClients'),
          path: '/oAuthClients',
          isActive: currentPath.startsWith('/oAuthClients'),
        })
      }
    }
    return all
  }, [currentPath, currentUser, currentUserIsActive, t])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <ContentWithNav
      navTitle={[currentUser.firstName, currentUser.lastName].join(' ')}
      navItems={navItems}
      navSubtitle={currentUser.id}
      onLogOut={onLogOut}>
      <Switch>
        <PrivateRoute path="/backOfficeUsers/new" component={UserCreatePage} />
        <PrivateRoute path="/backOfficeUsers/me/changePassword" component={ChangePasswordPage} />
        <PrivateRoute path="/backOfficeUsers/me" component={UserMePage} />
        <PrivateRoute path="/backOfficeUsers/:id/edit" component={UserEditPage} />
        <PrivateRoute path="/backOfficeUsers/:id" component={UserDetailsPage} />
        <PrivateRoute path="/backOfficeUsers" component={UserListPage} />
        <PrivateRoute path="/oAuthClients/new" component={OAuthClientCreatePage} />
        <PrivateRoute path="/oAuthClients/:id/edit" component={OAuthClientEditPage} />
        <PrivateRoute path="/oAuthClients/:id" component={OAuthClientDetailsPage} />
        <PrivateRoute path="/oAuthClients" component={OAuthClientListPage} />
        <PrivateRoute path="/data/exports" component={DataExports} />
        <PrivateRoute path="/data/indicators" component={GlobalIndicators} />)
        <PrivateRoute path="/data/dashboard" component={Dashboard} />)
      </Switch>
    </ContentWithNav>
  )
}
