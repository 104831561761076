import React from 'react'

import classnames from 'classnames'

import { ECHarmonizedPageBanner } from './EC/core/ECHarmonizedPageBanner'
import { ECInpageNav, ECNavItemProps } from './EC/navigation/ECInpageNav'
import { ECColumn, ECGrid, ECRow } from './EC/utilities/ECGrid'
import { ECSpacableProps, useMarginClass, usePaddingClass } from './EC/utilities/ECSpacable'

export interface ContentWithNavProps {
  navItems: Array<ECNavItemProps>
  navTitle?: string
  navSubtitle?: string
  onLogOut?: () => void
}

export const ContentWithNav: React.FC<ContentWithNavProps & ECSpacableProps> = ({
  navItems,
  navTitle,
  navSubtitle,
  margin,
  padding,
  children,
  onLogOut,
}) => {
  const noNav = React.useMemo(() => {
    return navItems.find((item) => item.isActive)?.hideNavWhenActive === true
  }, [navItems])

  return (
    <>
      <ECHarmonizedPageBanner navItems={navItems} />
      <ECGrid className={classnames(useMarginClass(margin), usePaddingClass(padding))}>
        <ECRow>
          {noNav ? null : (
            <ECColumn col={{ sm: 12, md: 12, lg: 3 }} margin={{ top: 'xs', bottom: 'xl' }}>
              <ECInpageNav title={navTitle} subtitle={navSubtitle} items={navItems} onLogOut={onLogOut} />
            </ECColumn>
          )}
          <ECColumn col={{ sm: 12, md: 12, lg: noNav ? 12 : 9 }} margin={{ top: 'xs', bottom: 'xl' }}>
            {children}
          </ECColumn>
        </ECRow>
      </ECGrid>
    </>
  )
}
