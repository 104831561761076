/**
 * See https://ec.europa.eu/component-library/ec/components/navigation/inpage-navigation/usage/
 */

import React from 'react'

import classnames from 'classnames'

import { useCommonTranslate } from '../../../i18n'
import { ECContainer } from '../ECContainer'
import { ECSpacable, useMarginClass } from '../utilities/ECSpacable'
import { ECStack } from '../utilities/ECStack'
import { ECText } from '../utilities/ECText'
import { ECLink, ECLinkProps } from './ECLink'

export interface ECNavItemProps extends ECLinkProps {
  title?: string
  onClick?: () => void
  margin?: ECSpacable
  isActive?: boolean
  hideNavWhenActive?: boolean
}

const ECNavItem: React.FC<ECNavItemProps> = ({ margin, path, title, isActive, onClick }) => {
  const marginClass = useMarginClass(margin)

  return (
    <li
      className={classnames(
        'ecl-inpage-navigation__item',
        {
          'ecl-inpage-navigation__item--active': isActive,
        },
        marginClass,
      )}>
      <ECLink
        path={path}
        className="ecl-inpage-navigation__link ecl-link ecl-link--standalone"
        title={title}
        onClick={onClick}
      />
    </li>
  )
}

export interface ECInpageNavProps {
  title?: string
  subtitle?: string
  items: Array<ECNavItemProps>
  onLogOut?: () => void
}

export const ECInpageNav: React.FC<ECInpageNavProps> = ({ title, subtitle, items, onLogOut }) => {
  const tc = useCommonTranslate()

  return (
    <nav className="ecl-inpage-navigation">
      {title && (
        <div style={{ display: 'block', textTransform: 'initial' }} className="ecl-inpage-navigation__title">
          {title}
        </div>
      )}
      {subtitle && (
        <ECText
          margin={{ allAround: 'none' }}
          padding={{ horizontal: 'm', top: 'none', bottom: 'm' }}
          color="grey"
          type="s">
          {subtitle}
        </ECText>
      )}
      <div className="ecl-inpage-navigation__body">
        <ul className="ecl-inpage-navigation__list" id="ecl-inpage-navigation-list">
          {items.map((props, index) => {
            return <ECNavItem key={`ecl-inpage-navigation__list-${props.title ?? index}`} {...props} />
          })}
        </ul>
        {onLogOut && (
          <ECStack horizontalAlignment="center">
            <ECContainer>
              <ECLink
                onClick={onLogOut}
                className={classnames(
                  'ecl-page-banner__link-cta ecl-link ecl-link--cta ecl-link--icon ecl-link--icon-after',
                )}>
                <span className="ecl-link__label">{tc('logOut')}</span>&nbsp;
              </ECLink>
            </ECContainer>
          </ECStack>
        )}
      </div>
    </nav>
  )
}
