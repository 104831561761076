/**
 * See https://ec.europa.eu/component-library/ec/utilities/border/usage/
 */

import classnames from 'classnames'

import { ECColor } from './ECColor'

export interface ECBorderProps {
  /** defaults to all */
  direction?: 'all' | 'top' | 'right' | 'bottom' | 'left'
  width?: 0 | 1 | 2 | 4 | 8
  color?: ECColor
  style?: 'solid' | 'dashed' | 'dotted'
}

export interface ECBoderableProps {
  border?: ECBorderProps
}

export const useBorderClass = (border?: ECBorderProps) => {
  if (border) {
    return classnames({
      [`ecl-u-border-all`]: border.direction === undefined,
      [`ecl-u-border-${border.direction}`]: border.direction,
      [`ecl-u-border-width-${border.width}`]: border.width,
      [`ecl-u-border-color-${border.color}`]: border.color,
      [`ecl-u-border-style-${border.style}`]: border.style,
    })
  }
}
