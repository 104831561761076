import i18n from '../../../../i18n'

const colors = ['#466eb4', '#d7642d', '#e6a532', '#829bcd', '#e6aa82', '#fae6c3', '#aab9e1']

const chartTitleStyle = {
  fontSize: 17,
}
const chartLabelStyle = {
  fontSize: 15,
}

export const getTimeSeriesLineChartData = ({
  title,
  categories,
  series,
}: {
  title: string
  categories: string[]
  series: { name: string; data: number[] }[]
}) => {
  let latestYear: number | undefined
  let categoriesUpdated = categories
  try {
    categoriesUpdated = categories.map((elt) => {
      const date = new Date(elt)
      if (!latestYear || latestYear !== date.getFullYear()) {
        latestYear = date.getFullYear()
        //return date.toLocaleDateString(i18n.language, { year: 'numeric', month: 'short' })
      }
      return date.toLocaleDateString(i18n.language, { year: 'numeric', month: 'short' })
    })
  } catch (e) {
    // do nothing
  }

  return {
    service: 'chart',
    version: '2.0',
    data: {
      colors,
      chart: { height: 400 },
      title: { text: title },
      xAxis: { categories: categoriesUpdated },
      yAxis: { title: { text: title, style: chartTitleStyle }, labels: { style: chartLabelStyle } },
      series: series.map((elt) => ({
        type: 'line',
        name: `<i>${elt.name.split('(')[0]}</i> (${elt.name.split('(')[1]}`,
        data: elt.data,
      })),
    },
  }
}

export const getPieChartData = ({
  data,
  title,
  seriesName,
}: {
  data: { y: number; name: string }[]
  title: string
  seriesName: string
}) => {
  return {
    id: 'byFishingMethod',
    service: 'chart',
    version: '2.0',
    provider: 'highcharts',
    data: {
      chart: { type: 'pie', height: 300 },
      colors,
      xAxis: [{ type: 'category', title: {} }],
      yAxis: [{ title: {}, labels: {} }],
      title: { text: title },
      tooltip: { pointFormat: '<b>{point.percentage:.1f}%</b>' },
      legend: { enabled: true },
      plotOptions: {
        pie: { dataLabels: { enabled: false }, showInLegend: true },
      },
      series: [{ type: 'pie', animation: false, name: seriesName, data }],
    },
  }
}

export const getMapData = ({ mapData, tooltipText }: { mapData: Dashboard.MapData[]; tooltipText: string }) => {
  let { min, max } = mapData.reduce(
    (acc, elt) => {
      return {
        min: Math.min(acc.min, elt.count),
        max: Math.max(acc.max + 1, elt.count),
      }
    },
    { min: 0, max: 0 },
  )
  while (max - min < 5) {
    max += 1
  }
  const ranges = Array.from({ length: 5 }, (_, i) => ({
    from: i === 0 ? 0 : min + (max - min) * (i / 5),
    to: min + (max - min) * ((i + 1) / 5) + 1,
    legend: `${Math.round(min + (max - min) * (i / 5))} - ${Math.round(min + (max - min) * ((i + 1) / 5))}`,
    style: {
      style: `style_${i}`,
      fillColor: `rgba(0, 0, 255, ${0.1 + 0.2 * i})`,
      fillOpacity: 1,
      lineColor: `rgba(0, 0, 255, 1)`,
      lineOpacity: 1,
      lineWidth: 1,
      weight: 1,
    },
  }))
  const hasGeometry = mapData.some((elt) => elt.zoneGeometry)
  return {
    service: 'map',
    version: '3.0',
    map: { center: [47.1414082, 2.9280804], zoom: 4, background: ['osmec'] },
    layers: {
      chorojson: [
        {
          name: 'map_catches_data',
          data: {
            source: mapData.reduce((acc: any, item) => {
              acc[item.zoneCode] = { value: item.count, text: tooltipText }
              return acc
            }, {}),
          },
          options: {
            legend: { header: `<b style="display: block; padding-bottom: 5px;">${tooltipText}</b>`, min: 0, max },
            ranges,
            events: { hover: { highlight: true } },
          },
        },
      ],
      geojson: hasGeometry
        ? [
            {
              data: {
                type: 'FeatureCollection',
                features: mapData.map((item) => ({
                  type: 'Feature',
                  properties: { zoneName: item.zoneName, count: item.count },
                  geometry: item.zoneGeometry,
                })),
              },

              options: {
                style: (feature: any) => {
                  const count = feature.properties.count
                  const range = ranges.find((elt) => elt.from <= count && elt.to > count)
                  return range ? range.style : {}
                },
                events: { tooltip: { type: 'popup', content: `{zoneName}<br/>${tooltipText}<br/><b>{count}</b>` } },
              },
            },
          ]
        : [],
    },
  }
}

export const getCandlestickChartData = ({
  candleData,
  speciesId,
  title,
  yAxisTitle,
}: {
  candleData: Dashboard.CandlesData
  speciesId: string
  title: string
  yAxisTitle: string
}) => {
  const data = candleData.series.find((elt) => elt.speciesId === speciesId)
  if (!data) {
    return undefined
  }
  let latestYear: number | undefined
  let categories = candleData.months
  try {
    categories = candleData.months.map((elt) => {
      const date = new Date(elt)
      if (!latestYear || latestYear !== date.getFullYear()) {
        latestYear = date.getFullYear()
        //return date.toLocaleDateString(i18n.language, { year: 'numeric', month: 'short' })
      }
      return date.toLocaleDateString(i18n.language, { year: 'numeric', month: 'short' })
    })
  } catch (e) {
    // do nothing
  }
  return {
    service: 'chart',
    version: '2.0',
    data: {
      chart: { type: 'boxplot', height: 400 },
      title: { text: title },
      legend: { enabled: true },
      xAxis: { categories },
      yAxis: { title: { text: yAxisTitle, style: chartTitleStyle }, labels: { style: chartLabelStyle } },
      series: [
        {
          name: `<i>${data.speciesScientificName}</i> (${data.speciesFAOCode})`,
          data: data.data.map((elt) => [
            elt.percentile_5,
            elt.percentile_25,
            elt.percentile_50,
            elt.percentile_75,
            elt.percentile_95,
          ]),
        },
      ],
    },
  }
}

export const getSizeWeightChartData = ({
  title,
  xAxisTitle,
  yAxisTitle,
  sizeVsWeight,
  speciesId,
}: {
  title: string
  xAxisTitle: string
  yAxisTitle: string
  sizeVsWeight: Dashboard.SizeVsWeight[]
  speciesId: string
}) => {
  const data = sizeVsWeight.find((elt) => elt.speciesId === speciesId)
  if (!data) {
    return undefined
  }
  return {
    service: 'chart',
    version: '2.0',
    data: {
      chart: { type: 'scatter' },
      title: { text: title },
      legend: { enabled: true },
      xAxis: { title: { text: xAxisTitle } },
      yAxis: { title: { text: yAxisTitle, style: chartTitleStyle }, labels: { style: chartLabelStyle } },
      plotOptions: {
        scatter: {
          tooltip: {
            crosshairs: true,
            headerFormat: `<b><i>${data.speciesScientificName}</i> (${data.speciesFAOCode})</b>`,
            pointFormat: `<br/><b>${xAxisTitle}</b> {point.x}<br /><b>${yAxisTitle}</b> {point.y}`,
          },
        },
      },
      series: [
        {
          name: `<i>${data.speciesScientificName}</i> (${data.speciesFAOCode})`,
          data: data.data.map((elt) => ({ x: elt.size, y: elt.weight })),
        },
      ],
    },
  }
}

export const getSizeDistributionChartData = ({
  title,
  xAxisTitle,
  yAxisTitle,
  sizeDistributionReleased,
  sizeDistributionKept,
  speciesId,
  releasedTitle,
  keptTitle,
}: {
  title: string
  xAxisTitle: string
  yAxisTitle: string
  sizeDistributionReleased: Dashboard.SizeDistribution[]
  sizeDistributionKept: Dashboard.SizeDistribution[]
  speciesId: string
  releasedTitle: string
  keptTitle: string
}) => {
  const dataReleased = sizeDistributionReleased.find((elt) => elt.speciesId === speciesId)
  const dataKept = sizeDistributionKept.find((elt) => elt.speciesId === speciesId)
  if (!dataReleased || !dataKept) {
    return undefined
  }
  const allSizes = [...dataReleased.data.map((elt) => elt.size), ...dataKept.data.map((elt) => elt.size)]
    .reduce((acc: number[], elt) => {
      if (!acc.includes(elt)) {
        acc.push(elt)
      }
      return acc
    }, [])
    .sort((a, b) => a - b)
  return {
    service: 'chart',
    version: '2.0',
    data: {
      chart: { type: 'column', inverted: true },
      colors,
      title: { text: title },
      legend: { enabled: true },
      xAxis: {
        title: { text: xAxisTitle, style: chartTitleStyle },
        labels: { style: chartLabelStyle },
        categories: allSizes.map((elt) => `${elt}`),
      },
      yAxis: { title: { text: yAxisTitle } },
      plotOptions: {
        column: {
          tooltip: {
            headerFormat: `<b><i>${dataReleased.speciesScientificName}</i> (${dataReleased.speciesFAOCode})</b>`,
            pointFormat: `<br/><b>${xAxisTitle}</b> {point.size}cm<br /><b>${releasedTitle}</b> {point.releasedCount}<br/><b>${keptTitle}</b> {point.keptCount}`,
          },
        },
      },
      series: [
        {
          name: releasedTitle,
          data: allSizes
            .map((elt) => dataReleased.data.find((elt2) => elt2.size === elt) ?? { count: 0, size: elt })
            .map((elt) => ({
              y: elt.count,
              size: elt.size,
              releasedCount: elt.count,
              keptCount: dataKept.data.find((elt2) => elt2.size === elt.size)?.count ?? 0,
            })),
        },
        {
          name: keptTitle,
          data: allSizes
            .map((elt) => dataKept.data.find((elt2) => elt2.size === elt) ?? { count: 0, size: elt })
            .map((elt) => ({
              y: elt.count,
              size: elt.size,
              releasedCount: dataReleased.data.find((elt2) => elt2.size === elt.size)?.count ?? 0,
              keptCount: elt.count,
            })),
        },
      ],
    },
  }
}
