import React from 'react'

import { ECColumn } from '../../../../components/EC/utilities/ECGrid'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'
import { UserProfile } from '../../components/UserProfile'

import './UserMePage.scss'

export const UserMePage: React.FC = () => {
  const user = useBackOfficeUserSelector()

  if (!user) {
    return <div />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      <UserProfile user={user} />
    </ECColumn>
  )
}
