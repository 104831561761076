import React from 'react'

import { createBrowserHistory } from 'history'
import { useDispatch } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'

import { Footer } from '../../../components/Footer'
import { RouterPageChangeTracker } from '../../../components/RouterPageChangeTracker'
import ScrollToTop from '../../../components/ScrollToTop'
import { AnglersLoginService } from '../../../services/anglers/anglers-login-service'
import { SetOauthActionCreator } from '../../../store/reducers/oauthReducer'
import { useQueryParams } from '../../../utils/hooks/useQueryParams'
import { PubSub } from '../../../utils/PubSub'
import { Header } from '../components/Header'
import { LandingPage } from './landing/LandingPage'
import { LoginPage } from './login/LoginPage'
import { ForgotPasswordPage } from './my-profile/ForgotPasswordPage'
import { PrivateRoute } from './PrivateRoute'
import { RegisterPage } from './register/RegisterPage'
import { RouterLoggedIn } from './RouterLoggedIn'
import { AppsPage } from './static/AppsPage'
import { HowPage } from './static/HowPage'
import { LegislationPage } from './static/LegislationPage'
import { PrivacyStatementPage } from './static/PrivacyStatementPage'

const history = createBrowserHistory()

export const AppRouter: React.FC = () => {
  const dispatch = useDispatch()
  const queryParams = useQueryParams()

  React.useEffect(() => {
    if (queryParams.get('oauth')) {
      const clientId = queryParams.get('client_id')
      const redirectUri = queryParams.get('redirect_uri')
      if (clientId && redirectUri) {
        dispatch(
          SetOauthActionCreator({
            clientId,
            redirectUri,
          }),
        )
      }
    }
  }, [dispatch, queryParams])

  React.useEffect(() => {
    const listenerId = PubSub.subscribe('public-logged-out', () => {
      AnglersLoginService.logOut(dispatch)
    })
    return () => PubSub.unsubscribe('public-logged-out', listenerId)
  }, [dispatch])

  return (
    <Router history={history}>
      <ScrollToTop />
      <Header />
      <RouterPageChangeTracker />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/apps" component={AppsPage} />
        <Route exact path="/how" component={HowPage} />
        <Route exact path="/legislation" component={LegislationPage} />
        <Route exact path="/data" component={PrivacyStatementPage} />
        <Route exact path="/specific-privacy-statement" component={PrivacyStatementPage} />
        <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/register" component={RegisterPage} />
        <PrivateRoute component={RouterLoggedIn} />
      </Switch>
      <Footer />
    </Router>
  )
}
