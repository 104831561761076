import { Dispatch } from 'redux'

import { backOfficeApi } from '../../api/backOfficeApi'
import { SetBackOfficeUserActionCreator } from '../../store/reducers/user/actions'

export const refreshBackOfficeUser = async (dispatch: Dispatch) => {
  try {
    const me = await backOfficeApi.getUserMe()
    dispatch(SetBackOfficeUserActionCreator(me))
  } catch (err) {
    dispatch(SetBackOfficeUserActionCreator())
  }
}

export const logOutFromBackOffice = async (dispatch: Dispatch) => {
  try {
    await backOfficeApi.logOut()
    dispatch(SetBackOfficeUserActionCreator())
  } catch (_err) {}
}
