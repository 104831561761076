import React from 'react'

import { useParams } from 'react-router-dom'

import { backOfficeApi } from '../../../../api/backOfficeApi'
import { ECButton } from '../../../../components/EC/ECButton'
import { ECColorPrimary } from '../../../../components/EC/utilities/ECColor'
import { ECColumn, ECColumnViewportProps, ECRow } from '../../../../components/EC/utilities/ECGrid'
import { ECSpacable, ECSpacableProps } from '../../../../components/EC/utilities/ECSpacable'
import { ECStack } from '../../../../components/EC/utilities/ECStack'
import { ECTitle } from '../../../../components/EC/utilities/ECTitle'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { useBackOfficeTranslate } from '../../../../i18n'

const rowMargins: ECSpacable = { vertical: 's' }

const leftColWidth: ECColumnViewportProps = { all: 4 }
const rightColWidth: ECColumnViewportProps = { all: 8 }

const labelColor = 'grey'

interface GenericRowProps {
  labelKey?: string
  value?: React.ReactNode
  isLeftBold?: boolean
  widthLeft?: ECColumnViewportProps
  widthRight?: ECColumnViewportProps
  color?: ECColorPrimary
}

const GenericRow: React.FC<GenericRowProps & ECSpacableProps> = ({
  labelKey,
  value,
  isLeftBold = false,
  widthLeft = leftColWidth,
  widthRight = rightColWidth,
  color = labelColor,
  margin = rowMargins,
}) => {
  const t = useBackOfficeTranslate()
  return (
    <ECRow margin={margin}>
      <ECColumn col={widthLeft} color={color} className="align_right">
        {labelKey ? isLeftBold ? <b>{t(`oAuthClients.${labelKey}`)}</b> : t(`oAuthClients.${labelKey}`) : undefined}
      </ECColumn>
      <ECColumn col={widthRight}>{value}</ECColumn>
    </ECRow>
  )
}

export const OAuthClientDetailsPage: React.FC = () => {
  const t = useBackOfficeTranslate()
  const [oAuthClient, setOAuthClient] = React.useState<OAuthClients.OAuthClient>()

  const params = useParams<{ id: string }>()

  React.useEffect(() => {
    const { id } = params
    if (id) {
      backOfficeApi
        .getOAuthClient(id)
        .then(setOAuthClient)
        .catch(() => { })
    }
  }, [params])

  if (!oAuthClient) {
    return <Spinner />
  }

  return (
    <ECColumn col={{ all: 12 }}>
      <ECTitle level={1} isPageTitle>
        {`${oAuthClient.name}`}
      </ECTitle>
      <GenericRow labelKey="name" value={oAuthClient.name} />
      <GenericRow
        labelKey="logo"
        value={<img src={oAuthClient.logoUrl} width={50} alt={t('oAuthClients.form.logoUrl')} />}
      />
      <GenericRow labelKey="clientUrl" value={oAuthClient.clientUrl} />
      <GenericRow
        labelKey="publicVisibility"
        value={
          oAuthClient.displayInAppsPage
            ? t('oAuthClients.isDisplayedInAppsPage.true')
            : t('oAuthClients.isDisplayedInAppsPage.false')
        }
      />
      <br />
      <br />
      <GenericRow isLeftBold labelKey="form.technical" value="" />
      <GenericRow labelKey="clientId" value={oAuthClient.id} />
      <GenericRow labelKey="clientSecret" value={oAuthClient.clientSecret} />
      <GenericRow
        labelKey="redirectUris"
        value={
          <span>
            {oAuthClient.redirectUris.map((elt) => (
              <span key={elt}>
                {elt}
                <br />
              </span>
            ))}
          </span>
        }
      />
      <br />
      <br />
      <ECRow margin={rowMargins}>
        <ECColumn col={leftColWidth} color={labelColor} className="align_right"></ECColumn>
        <ECColumn col={rightColWidth}>
          <ECStack horizontalAlignment="start">
            <ECButton
              type="primary"
              text={t('oAuthClients.edit')}
              href={`/oAuthClients/${oAuthClient.id}/edit`}></ECButton>
          </ECStack>
        </ECColumn>
      </ECRow>
    </ECColumn>
  )
}
