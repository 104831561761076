import React from 'react'

import { ReactComponent as CheckIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/check.svg'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import { CloseButton } from '../../../../components/EC/core/ECHeader'
import { ECLink } from '../../../../components/EC/navigation/ECLink'
import { useBackOfficeTranslate } from '../../../../i18n'
import {
  SetDashboardConfigurationActionCreator,
  useLatestSavedDashboardConfiguration,
} from '../../../../store/reducers/dashboardConfigurationReducer'

export interface DashboardConfiguration {
  keyFigures?: boolean
  trends?: boolean
  percentCatchesByFishingTypes?: boolean
  percentCatchesByFishingMethod?: boolean
  percentCatchesByClient?: boolean
  trendsInSizeAndWeight?: boolean
  sizeAndWeightDistribution?: boolean
  activityMapForSelectedSpecies?: boolean
}

export interface ECLanguageListOverlayProps {
  isShown: boolean
  onClose: () => void
}

export const DashboardConfigurationOverlay: React.FC<ECLanguageListOverlayProps> = ({ isShown, onClose }) => {
  const t = useBackOfficeTranslate()
  const dispatch = useDispatch()

  const lastestConfiguration = useLatestSavedDashboardConfiguration()
  const [dashboardConfiguration, setDashboardConfiguration] = React.useState(lastestConfiguration || {})

  const options = [
    { key: 'keyFigures' },
    { key: 'trends' },
    { key: 'percentCatchesByFishingTypes' },
    { key: 'percentCatchesByFishingMethod', fullKey: 'dashboard.pies.byFishingType.title' },
    { key: 'percentCatchesByClient' },
    { key: 'activityMapForSelectedSpecies' },
    { key: 'trendsInSizeAndWeight' },
    { key: 'sizeAndWeightDistribution' },
  ]

  const splitIndex = Math.ceil(options.length / 2)
  const leftColumn = options.slice(0, splitIndex)
  const rightColumn = options.slice(splitIndex)

  const onPressKey = React.useCallback(
    (key: string) => {
      dispatch(
        SetDashboardConfigurationActionCreator({
          ...dashboardConfiguration,
          [key]: !(dashboardConfiguration as any)[key],
        }),
      )
      setDashboardConfiguration((prev: any) => ({ ...prev, [key]: !prev[key] }))
    },
    [dashboardConfiguration, dispatch],
  )

  return (
    <div
      id="language-list-overlay"
      hidden={!isShown}
      className="ecl-language-list ecl-language-list--overlay"
      aria-labelledby="ecl-language-list__title"
      role="dialog"
      data-ecl-language-list-overlay="true">
      <div className="ecl-language-list__container ecl-container">
        <div className="ecl-row">
          <div className="ecl-language-list__close ecl-col-12 ecl-col-lg-8 ecl-offset-lg-2">
            <CloseButton onClick={onClose} />
          </div>
          <div
            className="ecl-language-list__title ecl-col-12 ecl-col-lg-8 ecl-offset-lg-2"
            id="ecl-language-list__title">
            <div>
              <h2 style={{ display: 'block' }}>
                {t('dashboard.configure.title')}
                <br />
                <br />
                <small style={{ fontWeight: 'normal' }}>{t('dashboard.configure.subtitle')}</small>
              </h2>
            </div>
          </div>
        </div>
        <div className="ecl-row">
          <div className="ecl-language-list__column ecl-col-12 ecl-col-lg-4 ecl-offset-lg-2">
            <ul className="ecl-language-list__list">
              {leftColumn?.map(({ key, fullKey }) => (
                <OptionListItem
                  key={key}
                  id={key}
                  title={t(fullKey ?? `dashboard.configure.options.${key}`)}
                  isActive={(dashboardConfiguration as any)[key]}
                  onClick={onPressKey}
                />
              ))}
            </ul>
          </div>
          <div className="ecl-language-list__column ecl-col-12 ecl-col-lg-4">
            <ul className="ecl-language-list__list">
              {rightColumn?.map(({ key }) => (
                <OptionListItem
                  key={key}
                  id={key}
                  title={t(`dashboard.configure.options.${key}`)}
                  isActive={(dashboardConfiguration as any)[key]}
                  onClick={onPressKey}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const OptionListItem = ({
  id,
  title,
  isActive,
  onClick,
}: {
  id: string
  title: string
  isActive?: boolean
  onClick: (key: string) => void
}) => {
  const _onClick = React.useCallback(() => {
    onClick(id)
  }, [onClick, id])

  return (
    <li className={classnames('ecl-language-list__item', { 'ecl-language-list__item--is-active': isActive })}>
      <ECLink
        rel="alternate"
        className={classnames('ecl-language-list__link ecl-link ecl-link--standalone', {
          'ecl-link--icon ecl-link--icon-after': isActive,
        })}
        onClick={_onClick}>
        {title}
        {isActive && <CheckIcon focusable="false" className="ecl-link__icon ecl-icon ecl-icon--xs" />}
      </ECLink>
    </li>
  )
}
