/**
 * See https://ec.europa.eu/component-library/ec/components/forms/text-field/code/
 */

import React from 'react'

import classnames from 'classnames'

import { ECDimensionProps, useDimensionClass } from '../utilities/ECDimensionable'
import { ECSpacableProps, useMarginClass, usePaddingClass } from '../utilities/ECSpacable'
import { ECFormErrorMessage, ECFormHelpMessage, ECFormIdPrefixes, ECFormProps } from './ECForm'
import { ECFormGroupTitle } from './ECFormGroup'

export interface ECTextFieldProps extends ECFormProps {
  name: string
  label: string
  help?: string
  placeholder?: string
  /** disable text field if true, default = false */
  isDisabled?: boolean
  inputType?: 'email' | 'text' | 'number' | 'password'
  inputWidth?: 's' | 'm' | 'l'
  width?: ECDimensionProps
  inputProps?: React.HTMLProps<HTMLInputElement>
}

export const ECTextInput = React.forwardRef<HTMLInputElement, ECTextFieldProps & ECSpacableProps>(
  (
    {
      id,
      name,
      label,
      help,
      placeholder,
      error,
      isDisabled: disable = false,
      isRequired: required = false,
      optionalText,
      inputType = 'text',
      inputWidth,
      margin,
      padding,
      width,
      inputProps,
    },
    ref,
  ) => {
    const groupClass = classnames('ecl-form-group', useMarginClass(margin), usePaddingClass(padding))
    const inputClass = classnames(
      'ecl-text-input',
      {
        [`ecl-text-input--${inputWidth}`]: inputWidth,
      },
      useDimensionClass(width),
    )

    return (
      <div className={groupClass}>
        {label && (
          <ECFormGroupTitle
            id={id}
            legend={label}
            optionalText={optionalText}
            isRequired={required}
            htmlFor={`${ECFormIdPrefixes.textInput}${id}`}
            isLabelTag
          />
        )}
        {help && (
          <ECFormHelpMessage id={id} isDisabled={disable}>
            {help}
          </ECFormHelpMessage>
        )}
        <ECFormErrorMessage id={id} error={error} />
        <input
          type={inputType}
          id={`${ECFormIdPrefixes.textInput}${id}`}
          name={name}
          className={inputClass}
          disabled={disable}
          placeholder={placeholder}
          ref={ref}
          aria-label={label}
          {...inputProps}
        />
      </div>
    )
  },
)
