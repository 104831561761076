import React from 'react'

import classnames from 'classnames'

import { ECContentBaseProps } from '../core/ECContentBase'
import { ECSpacableProps, useMarginClass, usePaddingClass } from '../utilities/ECSpacable'
import { ECFormErrorMessage, ECFormHelpMessage, ECFormIdPrefixes, ECFormProps } from './ECForm'

export interface ECFormGroupTitleProps extends ECFormProps {
  legend?: string
  isLabelTag?: boolean
  htmlFor?: string
}

export const ECFormGroupTitle: React.FC<ECFormGroupTitleProps> = ({
  isLabelTag = false,
  legend,
  optionalText,
  isRequired: required,
  isDisabled: disable,
  error,
  htmlFor,
}) => {
  const RootTag = (isLabelTag ? 'label' : 'legend') as keyof JSX.IntrinsicElements

  return (
    <RootTag
      className={classnames('ecl-form-label', {
        'ecl-form-label--disabled': disable,
        'ecl-form-label--invalid': error !== undefined,
      })}
      htmlFor={htmlFor}>
      {legend}
      {optionalText && <span className="ecl-form-label__optional"> {optionalText}</span>}
    </RootTag>
  )
}

export interface ECFormGroupProps extends ECFormProps {
  legend?: string
  help?: string
  isDisabled?: boolean
  isLabelTag?: boolean
}

export const ECFormGroup: React.FC<ECFormGroupProps & ECSpacableProps & ECContentBaseProps> = ({
  id,
  legend,
  help,
  isDisabled,
  isRequired,
  optionalText,
  isLabelTag = false,
  error,
  margin,
  padding,
  children,
  className,
}) => {
  const groupClass = classnames('ecl-form-group', useMarginClass(margin), usePaddingClass(padding), className)

  return (
    <div className={groupClass}>
      {legend && (
        <ECFormGroupTitle
          id={id}
          legend={legend}
          isRequired={isRequired}
          optionalText={optionalText}
          isLabelTag={isLabelTag}
          htmlFor={id}
        />
      )}
      {help && (
        <ECFormHelpMessage id={id} isDisabled={isDisabled}>
          {help}
        </ECFormHelpMessage>
      )}
      <ECFormErrorMessage id={id} error={error} />
      {children}
    </div>
  )
}
