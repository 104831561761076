/**
 * See https://ec.europa.eu/component-library/ec/components/tag/code/
 */
import React, { useCallback } from 'react'

import { ReactComponent as CloseIcon } from '@ecl/ec-preset-website/dist/images/icons/svg/ui/close.svg'
import classnames from 'classnames'

import { ECContentBaseProps } from './core/ECContentBase'
import './ECTag.scss'
import { ECFormProps } from './forms/ECForm'
import { ECSelectOption } from './forms/ECSelect'
import { ECLink } from './navigation/ECLink'
import { ECBackgroundProps, useBackgroundClass } from './utilities/ECColor'
import { ECSpacableProps, useMarginClass } from './utilities/ECSpacable'
import { ECTextProps, useTextClasses } from './utilities/ECText'

export interface ECTagProps {
  hasCloseIcon?: boolean
  value?: string
  /** if set, makes a link tag */
  to?: string
  /** if set, makes a removable tag, supercedes to */
  onClose?: (value: string) => void
}

export const ECTag: React.FC<ECTagProps & ECBackgroundProps & ECTextProps & ECSpacableProps & ECContentBaseProps> = ({
  to,
  value,
  hasCloseIcon,
  onClose,
  background = 'blue-75',
  color = 'white',
  styles,
  children,
  margin,
  className,
}) => {
  const onClick = useCallback(() => {
    if (onClose !== undefined && value) {
      onClose(value)
    }
  }, [onClose, value])
  const backgroundClass = useBackgroundClass(background)
  const textClass = useTextClasses(color, styles)
  const marginClass = useMarginClass(margin)

  if (hasCloseIcon && onClose) {
    return (
      <span
        className={classnames(
          'ecl-tag ecl-tag--removable ec-removable-tag',
          backgroundClass,
          textClass,
          marginClass,
          className,
        )}>
        {children}
        <button type="button" aria-label="Dismiss" className="ecl-tag__icon">
          <CloseIcon
            focusable="false"
            aria-hidden="true"
            className="ecl-tag__icon-close ecl-icon ecl-icon--xs"
            onClick={onClick}
            color={color}
          />
        </button>
      </span>
    )
  } else if (to) {
    return (
      <ECLink path={to} className={classnames('ecl-tag', backgroundClass, textClass, marginClass, className)}>
        {children}
      </ECLink>
    )
  } else {
    return (
      <span className={classnames('ecl-tag ec-simple-tag', backgroundClass, textClass, marginClass, className)}>
        {children}
      </span>
    )
  }
}

export interface ECTagListProps extends ECFormProps {
  /** A removable ECTag with onRemoveSelected is added for each element */
  listSelection: Array<ECSelectOption>
  onRemoveBadge: (value: string) => void
}

export const ECTagList: React.FC<ECTagListProps> = ({ id, listSelection, onRemoveBadge }) => {
  return (
    <div id={`badge-list-${id}`}>
      {listSelection?.map(({ value, title }, index) => (
        <ECTag
          key={`badgel-list-${value}`}
          value={value}
          hasCloseIcon
          onClose={onRemoveBadge}
          margin={{ top: 'xs', right: 'xs' }}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </ECTag>
      ))}
    </div>
  )
}
