import produce from 'immer'
import { useSelector } from 'react-redux'
import { Action } from 'redux'

import { DashboardConfiguration } from '../../containers/back-office/pages/dashboard/DashboardConfigurationOverlay'
import { AppReduxState } from './reducers'

export interface DashboardConfigurationReduxState {
  dasboardConfiguration?: DashboardConfiguration
}

export const SetDashboardConfigurationActionType = '@@actionTypes/SetDashboardConfiguration'
export interface SetDashboardConfigurationAction extends Action<typeof SetDashboardConfigurationActionType> {
  payload: {
    dashboardConfiguration?: DashboardConfiguration
  }
}
export const SetDashboardConfigurationActionCreator = (
  dashboardConfiguration?: DashboardConfiguration,
): SetDashboardConfigurationAction => ({
  type: SetDashboardConfigurationActionType,
  payload: { dashboardConfiguration },
})

export const filtersReducerInitialState: DashboardConfigurationReduxState = {}

// REDUCERS
export const DashboardConfigurationReducer = (state = filtersReducerInitialState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SetDashboardConfigurationActionType:
        draft.dasboardConfiguration = (action as SetDashboardConfigurationAction).payload.dashboardConfiguration
        break
    }
  })

export const useLatestSavedDashboardConfiguration = () =>
  useSelector((appState: AppReduxState) => {
    return appState.dashboardConfiguration.dasboardConfiguration
  })
