import React from 'react'

import { Redirect } from 'react-router-dom'

import { ECPageBanner } from '../../../../components/EC/core/ECPageBanner'
import { useBackOfficeTranslate } from '../../../../i18n'
import { useBackOfficeUserSelector } from '../../../../store/reducers/user/reducer'

export const Landing = () => {
  const t = useBackOfficeTranslate()

  const user = useBackOfficeUserSelector()

  if (user) {
    return <Redirect to="/backOfficeUsers/me" />
  }

  return (
    <ECPageBanner
      title={t('landing.title')}
      baseline={t('landing.baseline')}
      buttons={[{ title: t('landing.loginButton'), path: '/login' }]}
      background="blue"
    />
  )
}
