import * as Redux from 'redux'

import { DashboardConfigurationReducer, DashboardConfigurationReduxState } from './dashboardConfigurationReducer'
import { FiltersReduxState, FiltersReducer } from './filtersReducer'
import { OAuthReduxState, OAuthReducer } from './oauthReducer'
import { UserReduxState, UserReducer } from './user/reducer'

export interface AppReduxState {
  user: UserReduxState
  filters: FiltersReduxState
  dashboardConfiguration: DashboardConfigurationReduxState
  oAuth: OAuthReduxState
}

const reducers = Redux.combineReducers<AppReduxState>({
  user: UserReducer,
  filters: FiltersReducer,
  dashboardConfiguration: DashboardConfigurationReducer,
  oAuth: OAuthReducer,
})

export default reducers
