/**
 * See https://ec.europa.eu/component-library/ec/utilities/typography/code/
 */

import React from 'react'

import classnames from 'classnames'

import { ECContentBaseProps } from '../core/ECContentBase'
import { ECColorPrimary } from './ECColor'
import { ECDimensionableProps, useDimensionClass } from './ECDimensionable'
import { ECSpacableProps, useMarginClass, usePaddingClass } from './ECSpacable'

export type ECTextStyle = 'none' | 'lowercase' | 'uppercase' | 'capitalize' | 'overline' | 'underline'
export type ECTextType = 'lead' | 'medium' | 's' | 'xs'

export interface ECTextProps {
  type?: ECTextType
  color?: ECColorPrimary
  styles?: Array<ECTextStyle>
}

export const useTextClasses = (color?: ECColorPrimary, styles?: Array<ECTextStyle>) =>
  React.useMemo(() => {
    let classes = {} as { [className: string]: boolean }

    if (color) classes[`ecl-u-type-color-${color}`] = true
    if (styles) {
      classes = {
        ...classes,
        ...styles.reduce((aggreg, elt) => {
          aggreg[`ecl-u-type-${elt}`] = true
          return aggreg
        }, classes),
      }
    }

    return classnames(classes)
  }, [color, styles])

export const ECText: React.FC<ECTextProps & ECDimensionableProps & ECSpacableProps & ECContentBaseProps> = ({
  type = 'medium',
  color = 'black',
  styles,
  dimensions,
  margin,
  padding,
  className,
  children,
}) => {
  const paragraphClass = classnames(
    'eecl-u-type-m',
    {
      'ecl-u-type-paragraph': type === 'medium',
      [`ecl-u-type-paragraph-${type}`]: type !== 'medium',
    },
    useTextClasses(color, styles),
    useMarginClass(margin),
    usePaddingClass(padding),
    useDimensionClass(dimensions),
    className,
  )

  return <p className={paragraphClass}>{children}</p>
}
